import React, { Component } from "react";
const ListStoreGroup = props => {
  const { itemss, selectedStore, onStoreSelect } = props;
  return (
    <ul className="list-group">
      {itemss.map(items => (
        <li
          onClick={() => onStoreSelect(items)}
          key={items.storeId}
          className={
            items === selectedStore
              ? "list-group-item active"
              : "list-group-item"
          }
        >
          {items.storeTitle}
        </li>
      ))}
    </ul>
  );
};

export default ListStoreGroup;
