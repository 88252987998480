import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import CounterBadge from "./counterBadge";

const Navbar = ({ user, counter, totalCounters }) => {
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        {user && user.Role !== "Buyer" && (
          <React.Fragment>
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#">
                <i className="fas fa-bars"></i>
              </a>
            </li>
          </React.Fragment>
        )}
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/">
            Home
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/StoreUser">
            Manage Store
          </NavLink>
        </li>
        {/* <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/addproduct">
            Manage Products
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/listUser">
            User
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/users">
            Users
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/products">
            Products
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/prdts">
            Prdts
          </NavLink>
        </li> */}
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink
            tag={Link}
            className="nav-link"
            to={`/${user && user.LinkStr}`}
          >
            Shop
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/checkout">
            Check Out
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/additem">
            Item
          </NavLink>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <NavLink tag={Link} className="nav-link" to="/receipt">
            Receipt
          </NavLink>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        {user && (
          <React.Fragment>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-shopping-cart"></i>
                <CounterBadge />
                <span className="badge badge-pill badge-secondary">
                  {" "}
                  {totalCounters}
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <form>
                  {counter}
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="../../dist/img/user3-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 img-circle mr-3"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Nora Silvester
                          <span className="float-right text-sm text-warning">
                            <i className="fas fa-star"></i>
                          </span>
                        </h3>
                        <p className="text-sm">The subject goes here</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1"></i> 4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="#" className="dropdown-item dropdown-footer">
                    See All Messages
                  </a>
                </form>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell"></i>
                <span className="badge badge-warning navbar-badge">15</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">
                  15 Notifications
                </span>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  <i className="fas fa-envelope mr-2"></i> 4 new messagesasdf
                  asdf asdf asdf asdf asdfasdfasdfas
                  <span className="float-right text-muted text-sm">3 mins</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  <i className="fas fa-users mr-2"></i> 8 friend requests
                  <span className="float-right text-muted text-sm">
                    12 hours
                  </span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  <i className="fas fa-file mr-2"></i> 3 new reports
                  <span className="float-right text-muted text-sm">2 days</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item dropdown-footer">
                  See All Notifications
                </a>
              </div>
            </li>
          </React.Fragment>
        )}
        {!user && (
          <React.Fragment>
            <li className="nav-item">
              <NavLink tag={Link} className="nav-link" to="/signup">
                Create account
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink tag={Link} className="nav-link" to="/form">
                Register
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink tag={Link} className="nav-link" to="/login">
                Login
              </NavLink>
            </li>
          </React.Fragment>
        )}

        {user && (
          <React.Fragment>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-user"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">
                  Welcome {user.Name} {user.LinkStr} {user.ViewStr}
                </span>
                <div className="dropdown-divider"></div>
                <NavLink tag={Link} className="dropdown-item" to="/account">
                  <i className="fas fa-user mr-2"></i>My Account
                  <span className="float-right text-muted text-sm">3 mins</span>
                </NavLink>
                <div className="dropdown-divider"></div>
                <NavLink tag={Link} className="dropdown-item" to="/order">
                  <i className="fas fa-shopping-cart mr-2"></i>My Orders
                  <span className="float-right text-muted text-sm">3 mins</span>
                </NavLink>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                  <i className="fas fa-file mr-2"></i> 3 new reports
                  <span className="float-right text-muted text-sm">2 days</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="/logout" className="dropdown-item dropdown-footer">
                  Logout
                </a>
              </div>
            </li>
            {/* <li className="nav-item">
              <NavLink tag={Link} className="nav-link" to="/logout">
                Logout
              </NavLink>
            </li> */}
          </React.Fragment>
        )}
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            href="#"
          >
            <i className="fas fa-shopping-cart"></i>
            <span className="badge badge-pill badge-secondary">
              {totalCounters}
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default Navbar;
