import axios from "axios";
import { toast } from "react-toastify";
//import { login } from "./authService";

function userUrl(id) {
  return `https://api.hazaramart.com/api/storeusergroup/${id}`;
}
export function getMovie(movieId) {
  return axios.post(userUrl(movieId));
}
export function register(user) {
  return axios.post("https://api.hazaramart.com/api/users", {
    userEmail: user.username,
    userPass: user.password,
    userName: user.name,
    userStatus: true,
    roleId: 5,
  }).then((response) => {
    window.location = "/login";
    toast.success(`Registered Successfully.`);
    /* try {
      const { data: jwtt } = login(user.username, user.password);
      localStorage.setItem("key", jwtt);
      console.log(jwtt);
      
      
      //window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    } */
  });
}
