import React, { Component } from "react";
import axios from "axios";

class TestImgUpload extends Component {
  state = {
    selectedfile: null,
  };
  fileSelectedHandler = (event) => {
    //console.log(event.target.files[0]);
    this.setState({ selectedfile: event.target.files[0] });
  };
  fileUploadHandler = () => {
    const fd = new FormData();
    fd.append("image", this.state.selectedfile, this.state.selectedfile.name);
    axios.post("../upload", fd).then((res) => {
      console.log(res);
    });
  };
  render() {
    return (
      <div>
        <input type="file" onChange={this.fileSelectedHandler} />
        <button onClick={this.fileUploadHandler}>Upload</button>
      </div>
    );
  }
}

export default TestImgUpload;
