const users = [
  {
    userId: 1,
    firstName: "Inventory",
    lastName: { _id: "5b21ca3eeb7f6fbccd471818", name: "Admin" },
    userName: "InventoryAdmin",
    email: "InventoryAdmin@abc.com",
    password: "$admin@2017",
    createdDate: "2020-02-16T11:16:58.737"
  },
  {
    userId: 2,
    firstName: "Papu",
    lastName: { _id: "5b21ca3eeb7f6fbccd471814", name: "Worker" },
    userName: "Papu007",
    email: "papu@abc.com",
    password: "123456789",
    createdDate: "2020-02-16T11:16:58.737"
  },
  {
    userId: 3,
    firstName: "Business",
    lastName: { _id: "5b21ca3eeb7f6fbccd471820", name: "Mudassar" },
    userName: "Iqbal",
    email: "leo.mudassar@gmail.com",
    password: "hello",
    createdDate: "2020-02-29T12:17:58.99"
  },
  {
    userId: 4,
    firstName: null,
    lastName: { _id: "kjhkh", name: "ApiUrl" },
    userName: null,
    email: "ApiUrl@gmail.com",
    password: "apiurl",
    createdDate: "2020-02-29T13:20:24.557"
  },
  {
    userId: 5,
    firstName: null,
    lastName: { _id: "5b21ca3eeb7f6fbccd471814", name: "Worker" },
    userName: null,
    email: "gmail@gmail.com",
    password: "hello",
    createdDate: "2020-02-29T14:25:50.957"
  },
  {
    userId: 6,
    firstName: "Papu",
    lastName: { _id: "5b21ca3eeb7f6fbccd471814", name: "Worker" },
    userName: "Papu007",
    email: "papu@abc.com",
    password: "123456789",
    createdDate: "2020-02-16T11:16:58.737"
  }
];

export function getUsers() {
  return users;
}

export function getUser(id) {
  return users.find(u => u.userId === id);
}
