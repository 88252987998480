import React, { Component } from "react";
import CounterKart from "./counterKart";

class CountersKart extends Component {
  render() {
    return (
      <tbody>
        {this.props.counters.map((counter) => (
          <CounterKart
            key={counter.id}
            counter={counter}
          />
        ))}
      </tbody>
    );
  }
}

export default CountersKart;
