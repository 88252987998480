import React, { Component } from "react";
const ListStoreCompany = (props) => {
  const { companyitem, selectedCompany, onCompanySelect } = props;
  return (
    <ul className="list-group">
      {companyitem.map((companyitems) => (
        <li
          onClick={() => onCompanySelect(companyitems)}
          key={companyitems.companyId}
          className={
            companyitems === selectedCompany
              ? "list-group-item active"
              : "list-group-item"
          }
        >
          {companyitems.companyName}
        </li>
      ))}
    </ul>
  );
};

export default ListStoreCompany;
