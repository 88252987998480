import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { getCompany } from "./services/serviceProduct";
import ListStoreCompany from "./common/listStoreCompany";
import SearchBox from "./searchBox";
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productLocal: [],
      productLocalGroup: [],
      productTile: [],
      storeCompanies: [],
      mapStore: [],
      searchQuery: "",
      searchTile: "",
      selectedCompany: null,
    };
  }
  async componentDidMount() {
    const { data } = await getCompany();
    const storeCompanies = [
      { companyId: "", companyName: "All Companies" },
      ...data,
    ];
    const { data: productLocal } = await axios.get(
      `https://api.hazaramart.com/api/StoreStockLocal/${this.props.user.IdStr}`
    );
    const { data: productLocalGroup } = await axios.get(
      `https://api.hazaramart.com/api/productlocalgroup/${this.props.user.IdMaster}`
    );
    const { data: productTile } = await axios.get(
      `https://api.hazaramart.com/api/StoreStockTiles/${this.props.user.IdMaster}`
    );
    const { data: mapStore } = await axios.get(
      `https://api.hazaramart.com/api/StoreMapStockGoup/${this.props.user.IdStr}`
    );
    this.setState({
      productLocal,
      productLocalGroup,
      productTile,
      storeCompanies,
      mapStore,
    });
  }
  handleCompanySelect = (storeCompanies) => {
    this.setState({ selectedCompany: storeCompanies, searchQuery: "" });
    console.log(storeCompanies);
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedCompany: null });
  };
  handleSearchTile = (query) => {
    this.setState({ searchTile: query });
  };
  handleStore = (idd) => {
    axios
      .get(`https://api.hazaramart.com/api/StoreStockLocal/${idd}`)
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({
            productLocal: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  };
  render() {
    const { user } = this.props;
    const {
      productLocal: AllLocal,
      selectedCompany,
      mapStore,
      productLocalGroup: AllStock,
      productTile: AllTile,
      searchQuery,
      searchTile,
    } = this.state;
    let filtered = AllStock;
    let filterLocal = AllLocal;
    let filterTile = AllTile;
    if (searchQuery)
      filterLocal = AllLocal.filter(
        (u) =>
          u.productLocal.productType
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          u.productLocal.productCode
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          u.productLocal.productDescription
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase())
      );
    else if (selectedCompany && selectedCompany.companyId)
      filterLocal = AllLocal.filter(
        (u) => u.productLocal.company.companyId === selectedCompany.companyId
      );

    if (searchQuery)
      filtered = AllStock.filter(
        (u) =>
          u.productType.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          u.productCode.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          u.productDescription
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase())
      );
    else if (selectedCompany && selectedCompany.companyId)
      filtered = AllStock.filter(
        (u) => u.companyId === selectedCompany.companyId
      );
    if (searchTile)
      filterTile = AllTile.filter(
        (t) =>
          t.code.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.size.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.meter.toString().startsWith(searchTile.toString())
      );
    return (
      <React.Fragment>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Store Products Panel</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink tag={Link} to="/StoreUser">
                      List
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    <NavLink tag={Link} to="/adduser">
                      Add
                    </NavLink>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                {user && (
                  <div className="card card-primary card-outline">
                    <ListStoreCompany
                      companyitem={this.state.storeCompanies}
                      selectedCompany={this.state.selectedCompany}
                      onCompanySelect={this.handleCompanySelect}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-9">
                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-three-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-three-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-three-home"
                          role="tab"
                          aria-controls="custom-tabs-three-home"
                          aria-selected="true"
                        >
                          In Store Qty
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-three-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-three-profile"
                          role="tab"
                          aria-controls="custom-tabs-three-profile"
                          aria-selected="false"
                        >
                          All Stock
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-three-messages-tab"
                          data-toggle="pill"
                          href="#custom-tabs-three-messages"
                          role="tab"
                          aria-controls="custom-tabs-three-messages"
                          aria-selected="false"
                        >
                          In Store Tile
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-three-settings-tab"
                          data-toggle="pill"
                          href="#custom-tabs-three-settings"
                          role="tab"
                          aria-controls="custom-tabs-three-settings"
                          aria-selected="false"
                        >
                          All Tiles
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-three-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="custom-tabs-three-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-home-tab"
                      >
                        <div className="container">
                          <nav className="navbar navbar-expand-md navbar-light navbar-white">
                            <button
                              className="navbar-toggler order-1"
                              type="button"
                              data-toggle="collapse"
                              data-target="#navbarCollapse"
                              aria-controls="navbarCollapse"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                              className="collapse navbar-collapse order-3 navbar-light"
                              id="navbarCollapse"
                            >
                              <ul className="navbar-nav">
                                {mapStore.map((mapstr) => (
                                  <li
                                    key={mapstr.storeIdAllowNavigation.storeId}
                                    className="nav-item"
                                  >
                                    <a
                                      onClick={() =>
                                        this.handleStore(
                                          mapstr.storeIdAllowNavigation.storeId
                                        )
                                      }
                                      className="nav-link"
                                    >
                                      {mapstr.storeIdAllowNavigation.storeTitle}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </nav>
                        </div>
                        <div className="card">
                          <div className="navbar navbar-white navbar-light">
                            Showing {filterLocal.length} product(s).
                            <SearchBox
                              value={searchQuery}
                              onChange={this.handleSearch}
                            />
                          </div>
                          <div className="card-body">
                            <table className="table table-bordered table-hover">
                              <thead className="btn-primary">
                                <tr>
                                  <th>Company</th>
                                  <th>Name</th>
                                  <th>Code</th>
                                  <th>Description</th>
                                  <th>Size/Color</th>
                                  <th>Price</th>
                                  <th>Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterLocal.map((storeStock) => (
                                  <tr key={storeStock.productLocalId}>
                                    <td>
                                      {
                                        storeStock.productLocal.company
                                          .companyName
                                      }
                                    </td>
                                    <td>
                                      {storeStock.productLocal.productType}
                                    </td>
                                    <td>
                                      {storeStock.productLocal.productCode}
                                    </td>
                                    <td>
                                      {
                                        storeStock.productLocal
                                          .productDescription
                                      }
                                    </td>
                                    <td>
                                      {storeStock.productLocal.productSizeColor}
                                    </td>
                                    <td>
                                      {storeStock.productLocal.priceLocal}
                                    </td>
                                    <td>{storeStock.qtyLocal}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-three-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-profile-tab"
                      >
                        <div className="card">
                          <div className="navbar navbar-white navbar-light">
                            Showing {filtered.length} users in the database.
                            <SearchBox
                              value={searchQuery}
                              onChange={this.handleSearch}
                            />
                          </div>
                          <div className="card-body">
                            <table className="table table-bordered table-hover">
                              <thead className="btn-primary">
                                <tr>
                                  <th>Company</th>
                                  <th>Name</th>
                                  <th>Code</th>
                                  <th>Description</th>
                                  <th>Size/Color</th>
                                  <th>Price</th>
                                  <th>Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filtered.map((storeproduct) => (
                                  <tr key={storeproduct.productLocal1}>
                                    <td>
                                      <Link
                                        to={`/adduser/${storeproduct.productLocal1}`}
                                      >
                                        {storeproduct.company.companyName}
                                      </Link>
                                    </td>
                                    <td>{storeproduct.productType}</td>
                                    <td>{storeproduct.productCode}</td>
                                    <td>{storeproduct.productDescription}</td>
                                    <td>{storeproduct.productSizeColor}</td>
                                    <td>{storeproduct.priceLocal}</td>
                                    <td>
                                      <Link
                                        className="btn btn-info btn-sm m-2"
                                        to={`/adduser/${storeproduct.productLocal1}`}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Link>
                                      <button
                                        onClick={() =>
                                          this.deleteUser(
                                            storeproduct.productLocal1
                                          )
                                        }
                                        className="btn btn-danger btn-sm"
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-three-messages"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-messages-tab"
                      >
                        <div className="card">
                          <div className="navbar navbar-white navbar-light">
                            Showing {filterTile.length} users in the database.
                            <SearchBox
                              value={searchTile}
                              onChange={this.handleSearchTile}
                            />
                          </div>
                          <div className="card-body">
                            <table className="table table-bordered table-hover">
                              <thead className="btn-primary">
                                <tr>
                                  <th>Company</th>
                                  <th>Code</th>
                                  <th>Packing</th>
                                  <th>Size</th>
                                  <th>Meter</th>
                                  <th>Price</th>
                                  <th>Box</th>
                                  <th>Pcs</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterTile.map((productTile) => (
                                  <tr key={productTile.productTileId}>
                                    <td>
                                      <Link
                                        to={`/adduser/${productTile.productTileId}`}
                                      >
                                        {productTile.company}
                                      </Link>
                                    </td>
                                    <td>{productTile.code}</td>
                                    <td>{productTile.packing}</td>
                                    <td>{productTile.size}</td>
                                    <td>{productTile.meter}</td>
                                    <td>{productTile.price}</td>
                                    <td>{productTile.qtyBox}</td>
                                    <td>{productTile.qtyPcs}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-three-settings"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-three-settings-tab"
                      >
                        Pellentesque vestibulum commodo nibh nec blandit.
                        Maecenas neque magna, iaculis tempus turpis ac, ornare
                        sodales tellus. Mauris eget blandit dolor. Quisque
                        tincidunt venenatis vulputate. Morbi euismod molestie
                        tristique. Vestibulum consectetur dolor a vestibulum
                        pharetra. Donec interdum placerat urna nec pharetra.
                        Etiam eget dapibus orci, eget aliquet urna. Nunc at
                        consequat diam. Nunc et felis ut nisl commodo dignissim.
                        In hac habitasse platea dictumst. Praesent imperdiet
                        accumsan ex sit amet facilisis.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Products;
