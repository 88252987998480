import React, { Component } from "react";

class Store extends Component {
  state = {};
  render() {
    return (
      <body>
        <div class="container my-4">
          <div
            id="carousel-with-lb"
            class="carousel slide carousel-multi-item"
            data-ride="carousel"
          >
            <div class="controls-top">
              <a
                class="btn-floating btn-secondary"
                href="#carousel-with-lb"
                data-slide="prev"
              >
                <i class="fas fa-chevron-left"></i>
              </a>
              <a
                class="btn-floating btn-secondary"
                href="#carousel-with-lb"
                data-slide="next"
              >
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>

            <ol class="carousel-indicators">
              <li
                data-target="#carousel-with-lb"
                data-slide-to="0"
                class="active secondary-color"
              ></li>
              <li
                data-target="#carousel-with-lb"
                data-slide-to="1"
                class="secondary-color"
              ></li>
              <li
                data-target="#carousel-with-lb"
                data-slide-to="2"
                class="secondary-color"
              ></li>
            </ol>

            <div class="carousel-inner mdb-lightbox" role="listbox">
              <div id="mdb-lightbox-ui"></div>

              <div class=" carousel-item active text-center">
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(2).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(2).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(4).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(4).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(6).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(6).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(10).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(10).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(12).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(12).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(14).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(14).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
              </div>

              <div class="carousel-item text-center">
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(22).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(22).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(25).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(25).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(29).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(29).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(31).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(31).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(32).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(32).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(33).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(33).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
              </div>

              <div class="carousel-item text-center">
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(44).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(44).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(45).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(45).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(66).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(66).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>

                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(53).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(53).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(56).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(56).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
                <figure class="col-md-4 d-md-inline-block">
                  <a
                    href="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(77).jpg"
                    data-size="1600x1067"
                  >
                    <img
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(77).jpg"
                      class="img-fluid"
                    />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="container my-4">
          <div
            id="carousel-example-multi"
            class="carousel slide carousel-multi-item v-2"
            data-ride="carousel"
          >
            <div class="controls-top">
              <a
                class="btn-floating"
                href="#carousel-example-multi"
                data-slide="prev"
              >
                <i class="fas fa-chevron-left"></i>
              </a>
              <a
                class="btn-floating"
                href="#carousel-example-multi"
                data-slide="next"
              >
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>

            <ol class="carousel-indicators">
              <li
                data-target="#carousel-example-multi"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#carousel-example-multi" data-slide-to="1"></li>
              <li data-target="#carousel-example-multi" data-slide-to="2"></li>
              <li data-target="#carousel-example-multi" data-slide-to="3"></li>
              <li data-target="#carousel-example-multi" data-slide-to="4"></li>
              <li data-target="#carousel-example-multi" data-slide-to="5"></li>
            </ol>

            <div class="carousel-inner v-2" role="listbox">
              <div class="carousel-item active">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (36).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (34).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (38).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (29).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (30).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-12 col-md-4">
                  <div class="card mb-2">
                    <img
                      class="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Others/img (27).jpg"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h4 class="card-title font-weight-bold">Card title</h4>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a class="btn btn-primary btn-md btn-rounded">Button</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container my-4">
          <div
            id="multi-item-example"
            class="carousel slide carousel-multi-item"
            data-ride="carousel"
          >
            <div class="controls-top">
              <a
                class="btn-floating"
                href="#multi-item-example"
                data-slide="prev"
              >
                <i class="fa fa-chevron-left"></i>
              </a>
              <a
                class="btn-floating"
                href="#multi-item-example"
                data-slide="next"
              >
                <i class="fa fa-chevron-right"></i>
              </a>
            </div>

            <ol class="carousel-indicators">
              <li
                data-target="#multi-item-example"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#multi-item-example" data-slide-to="1"></li>
              <li data-target="#multi-item-example" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(18).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(35).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(60).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(47).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(48).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(53).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(45).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 clearfix d-none d-md-block">
                    <div class="card mb-2">
                      <img
                        class="card-img-top"
                        src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(51).jpg"
                        alt="Card image cap"
                      />
                      <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                        <a class="btn btn-primary">Button</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <br />
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li
                data-target="#myCarousel"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
              <li data-target="#myCarousel" data-slide-to="3"></li>
            </ol>

            <div class="carousel-inner" role="listbox">
              <div class="item active">
                <div class="span4" style={{ paddingleft: 18 }}>
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                </div>
              </div>
              <div class="item">
                <div class="span4" style={{ paddingleft: 18 }}>
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                  <img
                    src="http://placehold.it/290x180"
                    class="img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <a
              class="right carousel-control"
              href="#myCarousel"
              role="button"
              data-slide="next"
            >
              <span
                class="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </body>
    );
  }
}

export default Store;
