import React, { Component } from "react";
import { getUsers } from "./services/fakeUserService";
import Pagination from "./common/pagination";
import ListGroup from "./common/listGroup";
import { getGenres } from "./services/fakeGenreService";
import { paginate } from "./utils/paginate";

class Users extends Component {
  state = {
    users: [],
    genres: [],
    currentPage: 1,
    pageSize: 2
  };
  componentDidMount() {
    const genres = [{ name: "All Genres" }, ...getGenres()];
    this.setState({ users: getUsers(), genres });
  }
  handleDelete = user => {
    const users = this.state.users.filter(u => u.userId !== user.userId);
    this.setState({ users });
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleGenreSelect = lastName => {
    this.setState({ selectedGenre: lastName, currentPage: 1 });
  };
  render() {
    const { length: count } = this.state.users;
    const {
      pageSize,
      currentPage,
      selectedGenre,
      users: allUsers
    } = this.state;
    if (count === 0) return <p>There is no user in the database.</p>;

    const filtered =
      selectedGenre && selectedGenre._id
        ? allUsers.filter(u => u.lastName._id === selectedGenre._id)
        : allUsers;

    const users = paginate(filtered, currentPage, pageSize);

    return (
      <div className="row">
        <div className="col-2">
          <ListGroup
            items={this.state.genres}
            selectedItem={this.state.selectedGenre}
            onItemSelect={this.handleGenreSelect}
          />
        </div>
        <div className="col">
          <p>Showing {filtered.length} users in the database.</p>
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Genre</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.userId}>
                  <td>{user.email}</td>
                  <td>{user.password}</td>
                  <td>{user.lastName.name}</td>
                  <td>
                    <button
                      onClick={() => this.handleDelete(user)}
                      className="btn btn-danger btn-sm"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            itemsCount={filtered.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default Users;
