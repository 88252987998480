import React, { Component } from "react";

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem("key");
    window.location = "/";
  }
  render() {
    return null;
  }
}

export default Logout;
