import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { getPayType } from "./services/serviceReceipt";

class Order extends Component {
    state = {
        OrderAll: [],
    };
    async componentDidMount() {
        const { data: storePayType } = await getPayType();
        const { data: OrderAll } = await axios.get(
            `https://api.hazaramart.com/api/orderall/${this.props.user.Id}`
        );
        this.setState({ OrderAll });
    }
    render() {
        const {
            OrderAll,
        } = this.state;
        return (
            <React.Fragment>
                <section className="content-header"></section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        Points 0.00
                                    </li>
                                </ul>
                                <div className="card">
                                    <ul className="list-group">
                                        <NavLink tag={Link} className="list-group-item" to="/account">
                                            <i className="fas fa-smile"></i>   My Account
                                        </NavLink>
                                        <NavLink tag={Link} className="list-group-item" to="/order">
                                            <i className="fas fa-shopping-cart"></i>   My Orders
                                        </NavLink>
                                        <NavLink tag={Link} className="list-group-item" to="/wallet">
                                            <i className="fas fa-wallet"></i>   My Wallet
                                        </NavLink>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <nav className="navbar navbar-expand-md navbar-light d-none d-sm-inline-block">
                                    <button
                                        className="navbar-toggler order-1"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarCollapse"
                                        aria-controls="navbarCollapse"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div
                                        className="collapse navbar-collapse order-3 navbar-light"
                                        id="navbarCollapse"
                                    >
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    <b>My Account</b>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    <b>My Orders</b>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">
                                                    <b>Wallet</b>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>

                                <div className="card card-primary card-outline card-outline-tabs">
                                    <div className="card card-primary card-outline">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <b>
                                                    My Orders
                                                </b>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-header p-0 border-bottom-0">
                                        <ul
                                            className="nav nav-tabs"
                                            id="custom-tabs-three-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    id="custom-tabs-three-home-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-home"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-home"
                                                    aria-selected="true"
                                                >
                                                    All
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="custom-tabs-three-profile-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-profile"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-profile"
                                                    aria-selected="false"
                                                >
                                                    Pending
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="custom-tabs-three-messages-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-messages"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-messages"
                                                    aria-selected="false"
                                                >
                                                    Completed
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="custom-tabs-three-settings-tab"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-three-settings"
                                                    role="tab"
                                                    aria-controls="custom-tabs-three-settings"
                                                    aria-selected="false"
                                                >
                                                    Cancelled
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div
                                            className="tab-content"
                                            id="custom-tabs-three-tabContent"
                                        >
                                            <div
                                                className="tab-pane fade show active"
                                                id="custom-tabs-three-home"
                                                role="tabpanel"
                                                aria-labelledby="custom-tabs-three-home-tab"
                                            >
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <div class="table-responsive">
                                                            <table class="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order ID</th>
                                                                        <th>Pay Type</th>
                                                                        <th>Status</th>
                                                                        <th>Total</th>
                                                                        <th>Created on</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {OrderAll.map((OdrAll) => (
                                                                        <tr key={OdrAll.rcptOnlineId}>
                                                                            <td><a>{OdrAll.rcptOnlineId}</a></td>
                                                                            <td>{OdrAll.paymentType}</td>
                                                                            <td><span class="badge badge-secondary">{OdrAll.statusRcptOnline}</span></td>
                                                                            <td>{OdrAll.total}</td>
                                                                            <td>{OdrAll.createdDate}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>

                                                    <div class="card-footer clearfix">
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="custom-tabs-three-profile"
                                                role="tabpanel"
                                                aria-labelledby="custom-tabs-three-profile-tab"
                                            >
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <div class="table-responsive">
                                                            <table class="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order ID</th>
                                                                        <th>Pay Type</th>
                                                                        <th>Status</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {OrderAll
                                                                        .filter((OdrAll) => OdrAll.statusRcptOnline === "Pending" || OdrAll.statusRcptOnline == "Processing")
                                                                        .map((OdrAll) => (
                                                                            <tr key={OdrAll.rcptOnlineId}>
                                                                                <td>{OdrAll.rcptOnlineId}</td>
                                                                                <td>{OdrAll.paymentType}</td>
                                                                                <td><span class="badge badge-info">{OdrAll.statusRcptOnline}</span></td>
                                                                                <td>{OdrAll.total}</td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>

                                                    <div class="card-footer clearfix">
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="custom-tabs-three-messages"
                                                role="tabpanel"
                                                aria-labelledby="custom-tabs-three-messages-tab"
                                            >
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <div class="table-responsive">
                                                            <table class="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order ID</th>
                                                                        <th>Pay Type</th>
                                                                        <th>Status</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {OrderAll
                                                                        .filter((OdrAll) => OdrAll.statusRcptOnline == "Completed")
                                                                        .map((OdrAll) => (
                                                                            <tr key={OdrAll.rcptOnlineId}>
                                                                                <td>{OdrAll.rcptOnlineId}</td>
                                                                                <td>{OdrAll.paymentType}</td>
                                                                                <td><span class="badge badge-success">{OdrAll.statusRcptOnline}</span></td>
                                                                                <td>{OdrAll.total}</td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>

                                                    <div class="card-footer clearfix">
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                                                    </div>

                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="custom-tabs-three-settings"
                                                role="tabpanel"
                                                aria-labelledby="custom-tabs-three-settings-tab"
                                            >
                                                <div class="card">
                                                    <div class="card-body p-0">
                                                        <div class="table-responsive">
                                                            <table class="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order ID</th>
                                                                        <th>Pay Type</th>
                                                                        <th>Status</th>
                                                                        <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {OrderAll
                                                                        .filter((OdrAll) => OdrAll.statusRcptOnline == "Cancelled")
                                                                        .map((OdrAll) => (
                                                                            <tr key={OdrAll.rcptOnlineId}>
                                                                                <td>{OdrAll.rcptOnlineId}</td>
                                                                                <td>{OdrAll.paymentType}</td>
                                                                                <td><span class="badge badge-danger">{OdrAll.statusRcptOnline}</span></td>
                                                                                <td>{OdrAll.total}</td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>

                                                    <div class="card-footer clearfix">
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-info float-left">Place New Order</a>
                                                        <a href="javascript:void(0)" class="btn btn-sm btn-secondary float-right">View All Orders</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Order;