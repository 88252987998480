import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import FormAddress from "./common/formAddress";
import { Link } from "react-router-dom";
import { getPayType } from "./services/serviceReceipt";
import { toast } from "react-toastify";

class CheckOut extends FormAddress {
  state = {
    statusKart: "",
    statusAdrs: "",
    storePayType: [],
    userAdrs: [],
    data: {
      userId: "",
      addTag: "",
      active: "",
      phone: "",
      houseFlat: "",
      address: "",
      city: "",
      streetBuilding: "",
    },
    deliveryAddress: "",
    errors: {},
  };
  schema = {
    userAddrId: Joi.string(),
    userId: Joi.string(),
    addTag: Joi.label("addTag"),
    houseFlat: Joi.label("houseFlat"),
    address: Joi.label("address"),
    city: Joi.label("city"),
    streetBuilding: Joi.label("streetBuilding"),
  };
  async componentDidMount() {
    const { data: storePayType } = await getPayType();
    const { data: userAdrs } = await axios.get(
      `https://api.hazaramart.com/api/useraddressid/${this.props.user.Id}`
    );
    this.setState({ storePayType, userAdrs });
  }
  async kartProcess() {
    this.setState({ statusKart: "Process" });
  }
  async kartEdit() {
    this.setState({ statusKart: "" });
  }
  async processKart() {
    const currData = this.state.userAdrs.filter((u) => u.active == true);
    const data = {
      userId: this.props.user.Id,
      paymentType: "Cash on Delivery",
      total: this.props.totalPrice,
      deliveryAddress:
        "H. " +
        currData[0].houseFlat +
        " St/Flat " +
        currData[0].streetBuilding +
        " " +
        currData[0].address +
        " " +
        currData[0].city,
    };
    await axios
      .post(`https://api.hazaramart.com/api/ProcessKart`, data)
      .then((response) => {
        toast.success(`Receipt saved. `);
        this.setState({
          statusKart: "Complete",
        });
        this.props.onReload();
      });
  }
  mapToViewModel(adrsDetail) {
    return {
      userAddrId: adrsDetail.userAddrId || null,
      userId: this.props.user.Id,
      addTag: adrsDetail.addTag || "",
      houseFlat: adrsDetail.houseFlat || "",
      address: adrsDetail.address || "",
      city: adrsDetail.city || "",
      active: adrsDetail.active || "",
      streetBuilding: adrsDetail.streetBuilding || "",
    };
  }
  async handleProductEdit(id) {
    this.setState({ statusAdrs: "Process" });
    const { data: adrsDetail } = await axios.get(
      `https://api.hazaramart.com/api/useraddresses/${id}`
    );
    this.setState({ data: this.mapToViewModel(adrsDetail) });
  }
  async handleProductAdd() {
    this.setState({ statusAdrs: "Process" });
  }
  async handleBack() {
    this.setState({ statusAdrs: "" });
  }
  async handleDAdrs(id) {
    await axios
      .post(`https://api.hazaramart.com/api/useraddressid/${id}`)
      .then((response) => {
        toast.success("Delivery address updated.");
      });
    const { data: userAdrs } = await axios.get(
      `https://api.hazaramart.com/api/useraddressid/${this.props.user.Id}`
    );
    this.setState({ userAdrs });
  }
  async editTodo(id) {
    if (id) {
      const itemToEdit = { ...this.state.data };
      await axios
        .put(`https://api.hazaramart.com/api/useraddresses/${id}`, itemToEdit)
        .then((response) => {
          toast.success("Successfully Updated.");
        });
      const { data: userAdrs } = await axios.get(
        `https://api.hazaramart.com/api/useraddressid/${this.props.user.Id}`
      );
      this.setState({ userAdrs, statusAdrs: "", adrsDetail: itemToEdit });
    } else {
      this.state.data.userId = this.props.user.Id;
      const itemToAdd = { ...this.state.data };
      await axios
        .post(`https://api.hazaramart.com/api/useraddresses`, itemToAdd)
        .then((response) => {
          toast.success("New Address Added.");
        });
      const { data: userAdrs } = await axios.get(
        `https://api.hazaramart.com/api/useraddressid/${this.props.user.Id}`
      );
      this.setState({ userAdrs, statusAdrs: "" });
    }
  }
  render() {
    const { userAdrs } = this.state;
    let pageTitle;
    let actionStatus;
    if (this.state.data.userAddrId) {
      pageTitle = "Edit Address";
      actionStatus = "Update";
    } else {
      pageTitle = "Add Address";
      actionStatus = "Save";
    }
    return (
      <React.Fragment>
        <section className="content-header" />
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                {this.state.statusKart === "" && (
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title">Item in Cart</h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Item</th>
                              <th>Price</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          {this.props.counterCopy}
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.statusKart === "Process" && (
                  <React.Fragment>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Delivery details</h3>
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary float-right"
                          data-toggle="modal"
                          data-target="#modal-secondary"
                        >
                          CHANGE
                        </button>
                      </div>
                      <div className="card-body">
                        {userAdrs
                          .filter((mapdlvry) => mapdlvry.active == true)
                          .map((mapdlvry) => (
                            <React.Fragment>
                              Address : House/Flat {mapdlvry.houseFlat},{" "}
                              {mapdlvry.streetBuilding}, {mapdlvry.address},{" "}
                              {mapdlvry.city}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Personal details</h3>
                      </div>
                      <div className="card-body">
                        Name: <b>{this.props.user.Name}</b> <br />
                        Email: {this.props.user.Email}
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Payment</h3>
                      </div>
                      <div className="card-body">
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            id="customRadio1"
                            name="customRadio"
                            checked
                          />
                          <label
                            for="customRadio1"
                            className="custom-control-label"
                          >
                            Cash on Delivery
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input custom-control-input"
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            disabled
                          />
                          <label
                            for="customRadio2"
                            className="custom-control-label"
                          >
                            Pay from Wallet
                          </label>
                        </div>
                      </div>
                      <div className="card-footer clearfix">
                        <a onClick={() => this.kartEdit()}>
                          <button
                            type="button"
                            className="btn btn-sm btn-info float-left"
                          >
                            Change/Edit Order
                          </button>
                        </a>
                        <a onClick={() => this.processKart()}>
                          <button
                            type="button"
                            className="btn btn-danger float-right"
                          >
                            Place Order
                          </button>
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.state.statusKart === "Complete" && (
                  <div className="info-box mb-3 bg-warning">
                    <span className="info-box-icon">
                      <i className="fas fa-shopping-cart"></i>
                    </span>
                    <div className="info-box-content">
                      <span className="info-box-text">
                        Order Successfully placed.
                      </span>
                      <Link to="/order" className="info-box-number">
                        <p>Click here for status</p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {this.state.statusKart === "" && (
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Order Summary</h3>
                    </div>

                    <div className="card-body p-0">
                      <ul className="products-list product-list-in-card pl-2 pr-2">
                        <li className="item">
                          <span className="product-description m-3">
                            Subtotal ({this.props.totalCounters} item)
                            <span className="float-right">
                              Rs. {this.props.totalPrice}
                            </span>
                          </span>
                          <span className="product-description m-3">
                            Shipping Fee
                            <span className="float-right">Rs. 0</span>
                          </span>
                          <span className="product-description m-3">
                            Shipping Fee Discount
                            <span className="float-right">Rs. 0</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer text-center">
                      <a
                        onClick={() => this.kartProcess()}
                        className="uppercase"
                      >
                        <button
                          type="button"
                          className="btn btn-block btn-warning"
                        >
                          PROCESS TO CHECKOUT
                        </button>
                      </a>
                    </div>
                  </div>
                )}
                {this.state.statusKart === "Process" && (
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Your order for </h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table m-0">
                          {this.props.counterCopyKart}
                        </table>
                      </div>
                      <ul className="products-list product-list-in-card pl-2 pr-2">
                        <li className="item">
                          <span className="product-description m-3">
                            Subtotal ({this.props.totalCounters} item)
                            <span className="float-right">
                              Rs. {this.props.totalPrice}
                            </span>
                          </span>
                          <span className="product-description m-3">
                            Shipping Fee
                            <span className="float-right">Rs. 0</span>
                          </span>
                          <span className="product-description m-3">
                            Shipping Fee Discount
                            <span className="float-right">Rs. 0</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal fade" id="modal-secondary">
            <div className="modal-dialog modal-xl">
              <div className="modal-content bg-secondary">
                <div className="modal-header">
                  <h4 className="modal-title">
                    Address Book |{" "}
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={() => this.handleProductAdd()}
                    >
                      Add New Address
                    </button>
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.statusAdrs === "" && (
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Tag</th>

                            <th>Address</th>

                            <th>
                              Select
                              <br />
                              Delivery
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {userAdrs.map((adrss) => (
                            <tr key={adrss.userAddrId}>
                              <td>
                                <span className="badge badge-warning">
                                  {adrss.addTag}
                                </span>
                              </td>
                              <td>
                                House/Flat {adrss.houseFlat},{" "}
                                {adrss.streetBuilding}, {adrss.address},{" "}
                                {adrss.city}
                              </td>
                              <td>
                                <button
                                  className={
                                    adrss.active == true
                                      ? "btn btn-warning btn-sm"
                                      : "btn"
                                  }
                                  data-dismiss="modal"
                                  onClick={() =>
                                    this.handleDAdrs(adrss.userAddrId)
                                  }
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              </td>
                              <td>
                                <text
                                  type="button"
                                  onClick={() =>
                                    this.handleProductEdit(adrss.userAddrId)
                                  }
                                >
                                  Edit
                                </text>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {this.state.statusAdrs === "Process" && (
                    <React.Fragment>
                      {pageTitle}
                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          {this.renderInput3("houseFlat", "House/Flat")}
                          {this.renderInput3(
                            "streetBuilding",
                            "Street/Building"
                          )}
                          {this.renderInput6("address", "Address")}
                          {this.renderInput3("city", "City")}
                          {this.renderInput3("addTag", "(Home, Office, etc)")}
                        </div>
                      </form>
                      <button
                        type="button"
                        className="btn btn-outline-light float-right"
                        onClick={() => this.handleBack()}
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-danger float-right"
                        onClick={() =>
                          this.editTodo(this.state.data.userAddrId)
                        }
                      >
                        {actionStatus}
                      </button>
                    </React.Fragment>
                  )}
                </div>
                <div className="modal-footer justify-content-between">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckOut;
