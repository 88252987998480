import React from "react";

const SelectCat = ({ name, label, options, error, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} {...rest} className="form-control">
        <option value="" />
        {options
          .sort(function (a, b) {
            return a.catName - b.catName;
          })
          .map((option) => (
            <option key={option.catId} value={option.catId}>
              {`  ${
                option.parentCategory ? option.parentCategory.catName || "" : ""
              } >> ${option.catName || ""}
                `}
            </option>
          ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default SelectCat;
