import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
//import "./index.css";
import App from "./App";
//import "../public/build/scss/pages/_login_and_register.SCSS";
import * as serviceWorker from "./serviceWorker";

/* const extractSubdomain = (host) => {
  const endSubdomain = host.indexOf(".localhost");
  if (endSubdomain !== -1) {
    return host.slice(0, endSubdomain);
  }
  return null;
};

if (extractSubdomain(window.location.hostname) === "www.agilitysol.com") {
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
} */
ReactDOM.render(<App />, document.getElementById("root")); // Learn more about service workers: https://bit.ly/CRA-PWA
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
/* ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
) */ serviceWorker.unregister();
