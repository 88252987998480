import axios from "axios";
import { apiUrl } from "../../config.json";

export function loginUser(storeUserName, storeUserPassword) {
  return axios.post("https://api.hazaramart.com/api/tokenuser", {
    storeUserName,
    storeUserPassword,
  });
}
export function login(userEmail, userPass) {
  return axios.post("https://api.hazaramart.com/api/token", {
    userEmail,
    userPass,
  });
  //const getToken = fetch(apiEndpoint, {
  //    method: "POST",
  //    body: JSON.stringify({
  //        "Email": email,
  //        "Password": password
  //    }),
  //    headers: {
  //        "Content-Type": "application/json",
  //        "Accept": "application/json"
  //    }
  //});
  ////const token = getToken.json();
  //return getToken;
}
