import React from "react";

const SearchBox = ({ value, onChange }) => {
  return (
    <div className="form-inline ml-3">
      <div className="input-group input-group-sm">
        <input
          type="text"
          name="query"
          className="form-control float-right"
          placeholder="Search..."
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      </div>
    </div>
  );
};

export default SearchBox;
