import http from "./httpService";
import { apiUrl } from "../../config.json";

export function getStoreGroup() {
  return http.get(apiUrl + "/storegroup/3");
}

export function getStoreGroupUser() {
  return http.get(apiUrl + "/storeusergroup/1");
}

export function getStoreDesignation() {
  return http.get(apiUrl + "/storedesignations");
}
