import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../config.json";
import { getRoles } from "./services/serviceRole";
import ListGroupX from "./common/listGroupX";

//const apiUrl = "https://linkwebapi.azurewebsites.net/api";

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      users: [],
      roles: [],
      selectedRole: null,
      response: {}
    };
  }
  componentDidMount() {
    axios
      .get(apiUrl + "/userroles")
      .then(response => response.data)
      .then(
        result => {
          result = [{ roleId: "", roleName: "All Roles" }, ...result];
          this.setState({
            roles: result
          });
        },
        error => {
          this.setState({ error });
        }
      );
    axios
      .get(apiUrl + "/users")
      .then(response => response.data)
      .then(
        result => {
          this.setState({
            users: result
          });
        },
        error => {
          this.setState({ error });
        }
      );
  }
  deleteUser(id) {
    const { users } = this.state;
    axios.delete(apiUrl + "/users/" + id).then(result => {
      alert(result.data);
      this.setState({
        response: result,
        users: users.filter(user => user.userId !== id)
      });
    });
  }

  handleRolesSelect = roleName => {
    this.setState({ selectedRole: roleName });
    console.log(roleName);
  };
  render() {
    const { error, users, roles } = this.state;
    const { selectedRole } = this.setState;
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink tag={Link} to="/listUser">
                      List
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    <NavLink tag={Link} to="/">
                      Add
                    </NavLink>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div className="card card-primary card-outline">
                  <ListGroupX
                    items={this.state.roles}
                    selecteditem={this.state.selectedRole}
                    onItemSelect={this.handleRolesSelect}
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          Showing {users.length} users in the database.
                        </h3>
                      </div>

                      <div className="card-body">
                        <table className="table table-bordered table-hover">
                          <thead className="btn-primary">
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Password</th>
                              <th>Mobile</th>
                              <th>ID Role</th>
                              <th></th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map(user => (
                              <tr key={user.userId}>
                                <td>{user.userName}</td>
                                <td>{user.userEmail}</td>
                                <td>{user.userPass}</td>
                                <td>{user.userMobile}</td>
                                <td>{user.roleId}</td>
                                <td></td>
                                <td>
                                  <button
                                    onClick={() =>
                                      this.props.editUser(user.userId)
                                    }
                                    className="btn btn-info btn-sm m-2"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </button>
                                  <button
                                    onClick={() => this.deleteUser(user.userId)}
                                    className="btn btn-danger btn-sm"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ListUser;
