import React, { Component } from "react";

class CounterNew extends Component {
  render() {
    return (
      <tr>
        <td>
          <div class="product-img">
            <img
              src={`../../store/${this.props.counter.strname}/${this.props.counter.productId}.jpg`}
              alt=""
              class="img-size-50"
            />
          </div>
        </td>
        <td>
          {this.props.counter.typeName}
          <br />
          {this.props.counter.description} {this.props.counter.sizeColor}{" "}
          {this.props.counter.code}{" "}
        </td>
        <td>
          Rs {this.props.counter.price}
          <br />
          <button
            type="button"
            class="btn btn-tool"
            onClick={() => this.props.onDelete(this.props.counter.receiptKartId)}
          >
            <i class="fas fa-trash"></i>
          </button>
        </td>
        <td>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-2"
            onClick={() => this.props.onDecrement(this.props.counter)}
            disabled={this.props.counter.qty < 2 ? "disabled" : ""}
          >
            -
          </button>
          {this.formatCount()}
          <button
            type="button"
            className="btn btn-secondary btn-sm m-2"
            onClick={() => this.props.onIncrement(this.props.counter)}
          >
            +
          </button>
        </td>
      </tr>
    );
  }

  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.props.counter.qty === 0 ? "warning" : "primary";
    return classes;
  }

  formatCount() {
    const { qty } = this.props.counter;
    return qty === 0 ? "Zero" : qty;
  }
}

export default CounterNew;
