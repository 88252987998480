import React, { Component } from "react";
import Counter from "./counter";

class Counters extends Component {
  render() {
    return (
      <div>
        <ul class="products-list product-list-in-card pl-2 pr-2">
          {this.props.counters.map((counter) => (
            <Counter
              key={counter.productID}
              onDelete={this.props.onDelete}
              onIncrement={this.props.onIncrement}
              onDecrement={this.props.onDecrement}
              counter={counter}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default Counters;
