import React, { Component } from "react";
const ListStoreDesignation = props => {
  const { itemsss, selectedDsgn, onDsgnSelect } = props;
  return (
    <ul className="list-group">
      {itemsss.map(items => (
        <li
          onClick={() => onDsgnSelect(items)}
          key={items.designationId}
          className={
            items === selectedDsgn
              ? "list-group-item active"
              : "list-group-item"
          }
        >
          {items.designationName}
        </li>
      ))}
    </ul>
  );
};

export default ListStoreDesignation;
