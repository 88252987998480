import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
class Footer extends Component {
  render() {
    return (
      <footer >
        <nav className="navbar navbar-expand-md">
        {/* <footer className="main-footer">
          <div className="float-right d-none d-sm-block">
          Copyright &copy; 2020{" "}
          <a href="http://agilitysol.com">AGILITYSOL.com</a><b>Version</b> 0.0.2{" "}
          All rights reserved.
        </div> */}
        <center>
          <a
            className="main-footer nav-link navbar-toggler"
            data-widget="control-sidebar"
            data-slide="true"
            href="#"
          >
            <i className="fas fa-shopping-cart"></i>
            <span className="badge badge-pill badge-secondary">
            {this.props.totalCounters}
            </span> Items | <i className="fas fa-wallet"></i> Rs. {this.props.totalPrice}
          </a>
        </center>
        </nav>
      </footer>
    );
  }
}

export default Footer;
