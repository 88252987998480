import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import LazyLoad from "react-lazyload";
import SearchBox from "./searchBox";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeByStrName: [],
      productTile: [],
      shopMenu: [],
      product: [],
      productPkg: [],
      searchStore: "",
    };
  }
  async componentDidMount() {
    const strnam = this.props.match.params.strname;
    const catnam = this.props.match.params.catname;
    const { data: shopMenu, data: shopSub } = await axios.get(
      `https://api.hazaramart.com/api/CategoryByStrName/${strnam}`
    );
    const { data: productTile } = await axios.get(
      `https://api.hazaramart.com/api/StockByStrName/${strnam}`
    );
    this.setState({
      shopMenu,
      shopSub: shopSub.filter((u) => u.parentCategory !== null),
      productTile,
    });
    await axios
      .get(`https://api.hazaramart.com/api/storebystrname/${strnam}`)
      .then((response) => {
        this.setState({ storeByStrName: response.data });
      });
    await axios
      .get(`https://api.hazaramart.com/api/StockByStrName/${strnam}`)
      .then((response) => {
        this.setState({ product: response.data });
      });
    await axios
      .get(`https://api.hazaramart.com/api/StockPkgByStrName/${strnam}`)
      .then((response) => {
        this.setState({ productPkg: response.data });
      });
    /* const { data: storeByStrName } = await axios.get(
        `https://api.hazaramart.com/api/storebystrname/${strnam}`
      ); */

    /* const { data: shopSub } = await axios.get(
        `https://linkwebapi.azurewebsites.net/api/CategoryByStrName/${strnam}`
      ); */
  }
  Loading = () => <div>...</div>;
  Spinner = () => (
    <div className="post loading">
      <h5>Loading...</h5>
    </div>
  );
  handleSearchTile = (query) => {
    this.setState({ searchTile: query });
  };
  async searchProcess() {
    this.state.searchStore == "search"
      ? this.setState({ searchStore: "" })
      : this.setState({ searchStore: "search" });
  }
  render() {
    const {
      productTile: AllTile,
      searchTile,
      shopMenu,
      shopSub,
      product,
      productPkg,
      storeByStrName,
    } = this.state;
    let filterTile = AllTile;
    if (searchTile)
      filterTile = AllTile.filter(
        (t) =>
          t.product.code.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.product.sizeColor
            .toLowerCase()
            .startsWith(searchTile.toLowerCase()) ||
          t.product.description.toLowerCase().includes(searchTile.toLowerCase())
      );
    return (
      <React.Fragment>
        <section className="content-header" />
        {/* <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>
                    {(this.props.match.params.strname || "").toUpperCase()}
                    Store
                  </h1>
                  <h6> powered by: AgilitySol</h6>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add / Edit Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </section> */}
        <div className="content">
          <div className="card">
            <nav className="navbar navbar-expand-md navbar-light navbar-white">
              <a href="#">
                <img
                  src={`../../store/${this.props.match.params.strname}/logo.png`}
                  alt="Logo"
                  className="img-size-50 mr-3 img-circle elevation-3"
                  style={{ opacity: 0.8 }}
                />
              </a>

              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Outlet Info
              </button>

              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler order-1"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse order-3"
                id="navbarCollapse"
              >
                <ul className="navbar-nav">
                  <li class="nav-item d-none d-sm-inline-block">
                    <a
                      type="button"
                      onClick={() => this.searchProcess()}
                      class="nav-link"
                    >
                      <i class="fas fa-search"></i>
                    </a>
                  </li>
                  {shopMenu
                    .filter((mapstr) => mapstr.parentCategory == null)
                    .map((mapstr) => (
                      <li
                        key={mapstr.catId}
                        className={
                          shopSub.filter(
                            (u) => u.parentCategory.catId == mapstr.catId
                          ).length > 0
                            ? "nav-item dropdown"
                            : "nav-item"
                        }
                      >
                        <a
                          id="dropdownSubMenu1"
                          href="#"
                          data-toggle={
                            shopSub.filter(
                              (u) => u.parentCategory.catId == mapstr.catId
                            ).length > 0
                              ? "dropdown"
                              : ""
                          }
                          aria-haspopup="true"
                          aria-expanded="false"
                          className={
                            shopSub.filter(
                              (u) => u.parentCategory.catId == mapstr.catId
                            ).length > 0
                              ? "nav-link dropdown-toggle"
                              : "nav-link"
                          }
                        >
                          {mapstr.catName}
                        </a>
                        <ul
                          aria-labelledby="dropdownSubMenu1"
                          className="dropdown-menu border-0 shadow"
                        >
                          <li
                            href="#"
                            className="dropdown-submenu"
                            key={mapstr.catId}
                          >
                            <a href="#">- {mapstr.catName}</a>
                          </li>
                          {shopSub
                            .filter(
                              (mapstr1) =>
                                mapstr1.parentCategory.catId == mapstr.catId
                            )
                            .map((mapstr1) => (
                              <li
                                key={mapstr1.catId}
                                className="dropdown-submenu dropdown-hover"
                              >
                                <a
                                  id="dropdownSubMenu2"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className={
                                    shopSub.filter(
                                      (u) =>
                                        u.parentCategory.catId == mapstr1.catId
                                    ).length > 0
                                      ? "dropdown-item dropdown-toggle"
                                      : "dropdown-item"
                                  }
                                >
                                  {mapstr1.catName}
                                </a>
                                {shopSub.filter(
                                  (u) => u.parentCategory.catId == mapstr1.catId
                                ).length > 0 && (
                                  <ul
                                    aria-labelledby="dropdownSubMenu2"
                                    className="dropdown-menu border-0 shadow"
                                  >
                                    {shopSub
                                      .filter(
                                        (mapstr11) =>
                                          mapstr11.parentCategory.catId ==
                                          mapstr1.catId
                                      )
                                      .map((mapstr11) => (
                                        <li key={mapstr11.catId}>
                                          <a
                                            href="#"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            className="dropdown-item"
                                          >
                                            {mapstr11.catName}
                                          </a>
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            </nav>

            {/* <div className="card card-primary card-outline">
              <div className="card-body">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    ></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100 float-right"
                        src={`../../store/${this.props.match.params.strname}/slide1.gif`}
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={`../../store/${this.props.match.params.strname}/slide2.gif`}
                        alt="Second slide"
                      />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>nayaLink</h5>
                        <p>www.nayalink.com</p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={`../../store/${this.props.match.params.strname}/slide3.gif`}
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div> */}
            {!this.props.match.params.catname && this.state.searchStore == "" && (
              <React.Fragment>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Category</h3>
                    </div>
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        {shopMenu
                          .filter((mapstr) => mapstr.parentCategory == null)
                          .map((mapstr) => (
                            <li key={mapstr.catId}>
                              <div class="product-img">
                                <img
                                  class="img-circle elevation-2"
                                  src={`dist/img/user1-128x128.jpg`}
                                  alt="User Image"
                                />
                              </div>
                              <a
                                class="users-list-name"
                                href={`/${this.props.match.params.strname}/${mapstr.catName}`}
                              >
                                {mapstr.catName}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-header">
                  <div className="container my-4">
                    <div className="row">
                      <React.Fragment>
                        {product
                          .filter(
                            (mapProduct) => mapProduct.product.catId == null
                          )
                          .slice(0, 4)
                          .map((mapProduct) => (
                            <div className="col-md-3  col-4">
                              <div className="small-box">
                                <a
                                  href={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                  data-toggle="lightbox"
                                  data-title={mapProduct.product.productId}
                                  data-gallery="gallery"
                                >
                                  <img
                                    className="card-img-top"
                                    src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                    alt="Card image cap"
                                  />
                                </a>
                                <div>
                                  <small className="text-muted m-2">
                                    {`  ${
                                      (mapProduct.product.type &&
                                        mapProduct.product.type.typeName) ||
                                      ""
                                    } ${mapProduct.product.description || ""} ${
                                      mapProduct.product.sizeColor || ""
                                    }
                                      ${
                                        (mapProduct.product.company &&
                                          mapProduct.product.company
                                            .companyName) ||
                                        ""
                                      }
                                      `}
                                  </small>
                                  {this.props.counter.filter(
                                    (u) =>
                                      u.productId ===
                                      mapProduct.product.productId
                                  ).length > 0 && (
                                    <React.Fragment>
                                      <div class="description-block">
                                        {this.props.counter
                                          .filter(
                                            (u) =>
                                              u.productId ===
                                              mapProduct.product.productId
                                          )
                                          .map((mapstr) => (
                                            <React.Fragment>
                                              <small className="text-muted m-2">
                                                {mapstr.qty} x{" "}
                                                {mapProduct.product.price}
                                                .00 Rs
                                              </small>
                                              <br />
                                              {mapstr.qty > 1 && (
                                                <button
                                                  onClick={() =>
                                                    this.props.onLess(
                                                      mapProduct.product
                                                        .productId
                                                    )
                                                  }
                                                  type="button"
                                                  className="btn btn-outline-warning btn-lg-sm btn-flat float-left"
                                                >
                                                  <i class="fa fa-minus-circle"></i>
                                                </button>
                                              )}
                                              {mapstr.qty < 2 && (
                                                <button
                                                  type="button"
                                                  class="btn btn-outline-warning btn-flat float-left"
                                                  onClick={() =>
                                                    this.props.onDel(
                                                      mapProduct.product
                                                        .productId
                                                    )
                                                  }
                                                >
                                                  <i class="fa fa-trash"></i>
                                                </button>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        <button
                                          onClick={() =>
                                            this.props.onAdd(
                                              this.props.user.Id,
                                              mapProduct.product.productId,
                                              (mapProduct.product.type &&
                                                mapProduct.product.type
                                                  .typeName) ||
                                                "",
                                              mapProduct.product.code,
                                              mapProduct.product.price,
                                              mapProduct.product.sizeColor,
                                              mapProduct.product.description,
                                              mapProduct.product.companyName,
                                              this.props.match.params.strname
                                            )
                                          }
                                          type="button"
                                          className="btn btn-outline-warning btn-flat float-right"
                                        >
                                          <i class="fa fa-plus-circle"></i>
                                        </button>
                                      </div>
                                    </React.Fragment>
                                  )}
                                  {this.props.counter.filter(
                                    (u) =>
                                      u.productId ===
                                      mapProduct.product.productId
                                  ).length < 1 && (
                                    <React.Fragment>
                                      <br />
                                      <small className="text-muted m-2">
                                        Rs{mapProduct.product.price}.00{" "}
                                      </small>
                                      <div class="description-block">
                                        <button
                                          onClick={() =>
                                            this.props.onAdd(
                                              this.props.user.Id,
                                              mapProduct.product.productId,
                                              (mapProduct.product.type &&
                                                mapProduct.product.type
                                                  .typeName) ||
                                                "",
                                              mapProduct.product.code,
                                              mapProduct.product.price,
                                              mapProduct.product.sizeColor,
                                              mapProduct.product.description,
                                              mapProduct.product.companyName,
                                              this.props.match.params.strname
                                            )
                                          }
                                          type="button"
                                          className="btn btn-block btn-outline-warning btn-flat"
                                        >
                                          <small>
                                            <i className="fas fa-cart-plus fa-lg"></i>
                                            Add to Cart
                                          </small>
                                        </button>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                {shopMenu
                  .filter((mapstr) => mapstr.parentCategory == null)
                  .map((mapstr) => (
                    <React.Fragment>
                      <div className="card-header">
                        <h2 key={mapstr.catId} className="m-0 text-primary">
                          {mapstr.catName}
                        </h2>
                        <div className="container my-4">
                          <div className="row">
                            {product
                              .filter(
                                (mapProduct) =>
                                  mapProduct.product.catId == mapstr.catId
                              )
                              .slice(0, 4)
                              .map((mapProduct) => (
                                <div className="col-md-3  col-4">
                                  <div className="small-box">
                                    <a
                                      href={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                      data-toggle="lightbox"
                                      data-title={mapProduct.product.productId}
                                      data-gallery="gallery"
                                    >
                                      <img
                                        className="card-img-top"
                                        src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                        alt="Card image cap"
                                      />
                                    </a>
                                    <div>
                                      <small className="text-muted m-2">
                                        {`  ${
                                          (mapProduct.product.type &&
                                            mapProduct.product.type.typeName) ||
                                          ""
                                        } ${
                                          mapProduct.product.description || ""
                                        } ${mapProduct.product.sizeColor || ""}
                                      ${
                                        (mapProduct.product.company &&
                                          mapProduct.product.company
                                            .companyName) ||
                                        ""
                                      }
                                      `}
                                      </small>
                                      {this.props.counter.filter(
                                        (u) =>
                                          u.productId ===
                                          mapProduct.product.productId
                                      ).length > 0 && (
                                        <React.Fragment>
                                          <div class="description-block">
                                            {this.props.counter
                                              .filter(
                                                (u) =>
                                                  u.productId ===
                                                  mapProduct.product.productId
                                              )
                                              .map((mapstr) => (
                                                <React.Fragment>
                                                  <small className="text-muted m-2">
                                                    {mapstr.qty} x{" "}
                                                    {mapProduct.product.price}
                                                    .00 Rs
                                                  </small>
                                                  <br />
                                                  {mapstr.qty > 1 && (
                                                    <button
                                                      onClick={() =>
                                                        this.props.onLess(
                                                          mapProduct.product
                                                            .productId
                                                        )
                                                      }
                                                      type="button"
                                                      className="btn btn-outline-warning btn-lg-sm btn-flat float-left"
                                                    >
                                                      <i class="fa fa-minus-circle"></i>
                                                    </button>
                                                  )}
                                                  {mapstr.qty < 2 && (
                                                    <button
                                                      type="button"
                                                      class="btn btn-outline-warning btn-flat float-left"
                                                      onClick={() =>
                                                        this.props.onDel(
                                                          mapProduct.product
                                                            .productId
                                                        )
                                                      }
                                                    >
                                                      <i class="fa fa-trash"></i>
                                                    </button>
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            <button
                                              onClick={() =>
                                                this.props.onAdd(
                                                  this.props.user.Id,
                                                  mapProduct.product.productId,
                                                  (mapProduct.product.type &&
                                                    mapProduct.product.type
                                                      .typeName) ||
                                                    "",
                                                  mapProduct.product.code,
                                                  mapProduct.product.price,
                                                  mapProduct.product.sizeColor,
                                                  mapProduct.product
                                                    .description,
                                                  mapProduct.product
                                                    .companyName,
                                                  this.props.match.params
                                                    .strname
                                                )
                                              }
                                              type="button"
                                              className="btn btn-outline-warning btn-flat float-right"
                                            >
                                              <i class="fa fa-plus-circle"></i>
                                            </button>
                                          </div>
                                        </React.Fragment>
                                      )}
                                      {this.props.counter.filter(
                                        (u) =>
                                          u.productId ===
                                          mapProduct.product.productId
                                      ).length < 1 && (
                                        <React.Fragment>
                                          <br />
                                          <small className="text-muted m-2">
                                            Rs{mapProduct.product.price}.00{" "}
                                          </small>
                                          <div class="description-block">
                                            <button
                                              onClick={() =>
                                                this.props.onAdd(
                                                  this.props.user.Id,
                                                  mapProduct.product.productId,
                                                  (mapProduct.product.type &&
                                                    mapProduct.product.type
                                                      .typeName) ||
                                                    "",
                                                  mapProduct.product.code,
                                                  mapProduct.product.price,
                                                  mapProduct.product.sizeColor,
                                                  mapProduct.product
                                                    .description,
                                                  mapProduct.product
                                                    .companyName,
                                                  this.props.match.params
                                                    .strname
                                                )
                                              }
                                              type="button"
                                              className="btn btn-block btn-outline-warning btn-flat"
                                            >
                                              <small>
                                                <i className="fas fa-cart-plus fa-lg"></i>
                                                Add to Cart
                                              </small>
                                            </button>
                                          </div>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <button
                            type="button"
                            className="btn btn-block btn-secondary btn-xs"
                          >
                            View all
                          </button>
                        </div>
                        <ol
                          className="breadcrumb"
                          style={{
                            background: `url("../dist/img/photo1.png")`,
                          }}
                        >
                          {shopSub
                            .filter(
                              (mapstr1) =>
                                mapstr1.parentCategory.catId == mapstr.catId
                            )
                            .map((mapstr1) => (
                              <React.Fragment>
                                <li className="breadcrumb-item active">
                                  <NavLink
                                    key={mapstr1.catId}
                                    tag={Link}
                                    to="/StoreUser"
                                  >
                                    {mapstr1.catName}
                                    {" ( "}
                                    {
                                      product.filter(
                                        (u) => u.product.catId == mapstr1.catId
                                      ).length
                                    }{" "}
                                    {")"}
                                  </NavLink>
                                </li>
                              </React.Fragment>
                            ))}
                        </ol>
                        {shopSub
                          .filter(
                            (mapstr1) =>
                              mapstr1.parentCategory.catId == mapstr.catId
                          )
                          .map((mapstr1) => (
                            <React.Fragment>
                              <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <NavLink
                                    key={mapstr1.catId}
                                    tag={Link}
                                    to="/StoreUser"
                                  >
                                    {mapstr1.catName}
                                    {" ( "}
                                    {
                                      product.filter(
                                        (u) => u.product.catId == mapstr1.catId
                                      ).length
                                    }{" "}
                                    {")"}
                                  </NavLink>
                                </li>
                              </ol>

                              <div className="container my-4">
                                <div className="row">
                                  {product
                                    .filter(
                                      (mapProduct) =>
                                        mapProduct.product.catId ==
                                        mapstr1.catId
                                    )
                                    .slice(0, 22)
                                    .map((mapProduct) => (
                                      <div className="col-md-3 col-4">
                                        {" "}
                                        {/* clearfix d-none d-md-block */}
                                        <div className="small-box">
                                          <a
                                            href={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                            data-toggle="lightbox"
                                            data-title={
                                              mapProduct.product.productId
                                            }
                                            data-gallery="gallery"
                                          >
                                            <img
                                              className="card-img-top"
                                              src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                              alt="Card image cap"
                                            />
                                          </a>

                                          <small className="text-muted m-2">
                                            {`  ${
                                              mapProduct.product.type
                                                .typeName || ""
                                            } ${
                                              mapProduct.product.description ||
                                              ""
                                            } ${
                                              mapProduct.product.sizeColor || ""
                                            } ${
                                              mapProduct.product.company
                                                .companyName || ""
                                            }
                                            `}
                                            {/* {mapProduct.product.type.typeName}{" "}
                                            {mapProduct.product.description}{" "}
                                            {mapProduct.product.sizeColor} -{" "}
                                            {
                                              mapProduct.product.company
                                                .companyName
                                            } */}
                                            {productPkg
                                              .filter(
                                                (mapProductPkg) =>
                                                  mapProductPkg.pkgId ==
                                                  mapProduct.product.productId
                                              )
                                              .map((mapProductPkg) => (
                                                <a>
                                                  -
                                                  {
                                                    mapProductPkg.product
                                                      .description
                                                  }
                                                </a>
                                              ))}
                                          </small>
                                          {this.props.counter.filter(
                                            (u) =>
                                              u.productId ===
                                              mapProduct.product.productId
                                          ).length > 0 && (
                                            <React.Fragment>
                                              <div class="description-block">
                                                {this.props.counter
                                                  .filter(
                                                    (u) =>
                                                      u.productId ===
                                                      mapProduct.product
                                                        .productId
                                                  )
                                                  .map((mapstr) => (
                                                    <React.Fragment>
                                                      <small className="text-muted m-2">
                                                        {mapstr.qty} x{" "}
                                                        {
                                                          mapProduct.product
                                                            .price
                                                        }
                                                        .00 Rs
                                                      </small>
                                                      <br />
                                                      {mapstr.qty > 1 && (
                                                        <button
                                                          onClick={() =>
                                                            this.props.onLess(
                                                              mapProduct.product
                                                                .productId
                                                            )
                                                          }
                                                          type="button"
                                                          className="btn btn-outline-warning btn-lg-sm btn-flat float-left"
                                                        >
                                                          <i class="fa fa-minus-circle"></i>
                                                        </button>
                                                      )}
                                                      {mapstr.qty < 2 && (
                                                        <button
                                                          type="button"
                                                          class="btn btn-outline-warning btn-flat float-left"
                                                          onClick={() =>
                                                            this.props.onDel(
                                                              mapProduct.product
                                                                .productId
                                                            )
                                                          }
                                                        >
                                                          <i class="fa fa-trash"></i>
                                                        </button>
                                                      )}
                                                    </React.Fragment>
                                                  ))}
                                                <button
                                                  onClick={() =>
                                                    this.props.onAdd(
                                                      this.props.user.Id,
                                                      mapProduct.product
                                                        .productId,
                                                      (mapProduct.product
                                                        .type &&
                                                        mapProduct.product.type
                                                          .typeName) ||
                                                        "",
                                                      mapProduct.product.code,
                                                      mapProduct.product.price,
                                                      mapProduct.product
                                                        .sizeColor,
                                                      mapProduct.product
                                                        .description,
                                                      mapProduct.product
                                                        .companyName,
                                                      this.props.match.params
                                                        .strname
                                                    )
                                                  }
                                                  type="button"
                                                  className="btn btn-outline-warning btn-flat float-right"
                                                >
                                                  <i class="fa fa-plus-circle"></i>
                                                </button>
                                              </div>
                                            </React.Fragment>
                                          )}
                                          {this.props.counter.filter(
                                            (u) =>
                                              u.productId ===
                                              mapProduct.product.productId
                                          ).length < 1 && (
                                            <React.Fragment>
                                              <br />
                                              <small className="text-muted m-2">
                                                Rs{mapProduct.product.price}.00{" "}
                                              </small>
                                              <div class="description-block">
                                                <button
                                                  onClick={() =>
                                                    this.props.onAdd(
                                                      this.props.user.Id,
                                                      mapProduct.product
                                                        .productId,
                                                      (mapProduct.product
                                                        .type &&
                                                        mapProduct.product.type
                                                          .typeName) ||
                                                        "",
                                                      mapProduct.product.code,
                                                      mapProduct.product.price,
                                                      mapProduct.product
                                                        .sizeColor,
                                                      mapProduct.product
                                                        .description,
                                                      mapProduct.product
                                                        .companyName,
                                                      this.props.match.params
                                                        .strname
                                                    )
                                                  }
                                                  type="button"
                                                  className="btn btn-block btn-outline-warning btn-flat"
                                                >
                                                  <small>
                                                    <i className="fas fa-cart-plus fa-lg"></i>
                                                    Add to Cart
                                                  </small>
                                                </button>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-block btn-secondary btn-xs"
                                >
                                  View all
                                </button>
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            )}
            {this.state.searchStore == "search" && (
              <div className="container-fluid">
                <div class="card">
                  <div class="card-header">
                    <div class="card-tools float-left">
                      <ul class="pagination pagination-sm">
                        <li class="page-item">
                          <a href="#" class="page-link">
                            &laquo;
                          </a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a href="#" class="page-link">
                            Home &raquo;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="m-1"> </div>
                      <div class="direct-chat-msg">
                        <small class="badge badge-warning">
                          <i class="far fa-clock"></i> 1 day
                        </small>
                        <div class="badge badge-warning">
                          <i class="far fa-clock"></i> 1 day
                        </div>
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-text m-1">Working</div>
                      </div>
                    </div>
                    <div className="navbar navbar-white navbar-light">
                      Total design(s) {filterTile.length} available.
                      <SearchBox
                        value={searchTile}
                        onChange={this.handleSearchTile}
                      />
                    </div>

                    <div className="row">
                      {filterTile.map((productTile) => (
                        <LazyLoad
                          key={productTile.productId}
                          placeholder={<this.Loading />}
                        >
                          <div className="col-lg-3 col-4">
                            <div className="card h-100">
                              <a
                                href={`../../store/${this.props.match.params.strname}/${productTile.productId}.jpg`}
                                data-toggle="lightbox"
                                data-title={productTile.product.code}
                                data-gallery="gallery"
                              >
                                <LazyLoad
                                  once={true}
                                  placeholder={
                                    <img
                                      src="https://www.eliananunes.com/images/lazy_loader.gif"
                                      alt="..."
                                    />
                                  }
                                >
                                  <img
                                    className="card-img-top"
                                    src={`../../store/${this.props.match.params.strname}/${productTile.productId}.jpg`}
                                    alt=""
                                  />
                                </LazyLoad>
                              </a>
                              <div
                                className="card-body"
                                key={productTile.productId}
                              >
                                <h4>
                                  <a href="#">
                                    {productTile.product.description}
                                  </a>
                                </h4>
                                <h5>Rs {productTile.product.price}</h5>
                                <p className="card-text">
                                  Packing Box {productTile.product.packing}
                                </p>
                              </div>
                              <div className="card-footer">
                                <small className="text-muted">
                                  {productTile.product.sizeColor}
                                </small>
                              </div>
                            </div>
                          </div>
                        </LazyLoad>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.match.params.catname && this.state.searchStore == "" && (
              <div class="row">
                <div class="col-3 col-sm-2">
                  <div
                    class="nav flex-column nav-tabs h-100"
                    id="vert-tabs-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {shopMenu
                      .filter((mapstr) => mapstr.parentCategory == null)
                      .map((mapstr, index) => (
                        <a
                          class={index === 0 ? "nav-link active" : "nav-link"}
                          id={`vert-tabs-${mapstr.catName}-tab`}
                          key={mapstr.catId}
                          data-toggle="pill"
                          href={`#vert-tabs-${mapstr.catName}`}
                          role="tab"
                          aria-controls={`vert-tabs-${mapstr.catName}`}
                          aria-selected={index === 0 ? "true" : "false"}
                        >
                          {mapstr.catName}
                        </a>
                      ))}
                  </div>
                </div>
                <div class="col-9 col-sm-10">
                  <div class="tab-content" id="vert-tabs-tabContent">
                    {shopMenu
                      .filter((mapstr) => mapstr.parentCategory == null)
                      .map((mapstr, index) => (
                        <div
                          class={
                            index === 0
                              ? "tab-pane text-left fade show active"
                              : "tab-pane fade"
                          }
                          id={`vert-tabs-${mapstr.catName}`}
                          role="tabpanel"
                          aria-labelledby={`vert-tabs-${mapstr.catName}-tab`}
                        >
                          <nav className="mt-0">
                            <ul
                              className="nav nav-sidebar flex-column"
                              data-widget="treeview"
                              role="menu"
                              data-accordion="false"
                            >
                              <div class="user-block">
                                <img
                                  class="img-circle img-bordered-sm"
                                  src="../../dist/img/user1-128x128.jpg"
                                  alt="user image"
                                />
                                <span class="username">
                                  <a href="#">{mapstr.catName}</a>
                                </span>
                                <span class="description">
                                  Shared publicly - 7:45 PM today
                                </span>
                              </div>
                              <div class="row">
                                {product
                                  .filter(
                                    (mapProduct) =>
                                      mapProduct.product.catId === mapstr.catId
                                  )
                                  .slice(0, 4)
                                  .map((mapProduct) => (
                                    <div class="col-lg-3 col-4">
                                      <div class="small-box">
                                        <img
                                          className="d-block w-100"
                                          src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                          alt="Second slide"
                                        />
                                        <small className="text-muted m-2">
                                          {`  ${
                                            (mapProduct.product.type &&
                                              mapProduct.product.type
                                                .typeName) ||
                                            ""
                                          } ${
                                            mapProduct.product.description || ""
                                          } ${
                                            mapProduct.product.sizeColor || ""
                                          }
                                      ${
                                        (mapProduct.product.company &&
                                          mapProduct.product.company
                                            .companyName) ||
                                        ""
                                      }
                                      `}
                                        </small>
                                        {this.props.counter.filter(
                                          (u) =>
                                            u.productId ===
                                            mapProduct.product.productId
                                        ).length > 0 && (
                                          <React.Fragment>
                                            <div class="description-block">
                                              {this.props.counter
                                                .filter(
                                                  (u) =>
                                                    u.productId ===
                                                    mapProduct.product.productId
                                                )
                                                .map((mapstr) => (
                                                  <React.Fragment>
                                                    <small className="text-muted m-2">
                                                      {mapstr.qty} x{" "}
                                                      {mapProduct.product.price}
                                                      .00 Rs
                                                    </small>
                                                    <br />
                                                    {mapstr.qty > 1 && (
                                                      <button
                                                        onClick={() =>
                                                          this.props.onLess(
                                                            mapProduct.product
                                                              .productId
                                                          )
                                                        }
                                                        type="button"
                                                        className="btn btn-outline-warning btn-lg-sm btn-flat float-left"
                                                      >
                                                        <i class="fa fa-minus-circle"></i>
                                                      </button>
                                                    )}
                                                    {mapstr.qty < 2 && (
                                                      <button
                                                        type="button"
                                                        class="btn btn-outline-warning btn-flat float-left"
                                                        onClick={() =>
                                                          this.props.onDel(
                                                            mapProduct.product
                                                              .productId
                                                          )
                                                        }
                                                      >
                                                        <i class="fa fa-trash"></i>
                                                      </button>
                                                    )}
                                                  </React.Fragment>
                                                ))}
                                              <button
                                                onClick={() =>
                                                  this.props.onAdd(
                                                    this.props.user.Id,
                                                    mapProduct.product
                                                      .productId,
                                                    (mapProduct.product.type &&
                                                      mapProduct.product.type
                                                        .typeName) ||
                                                      "",
                                                    mapProduct.product.code,
                                                    mapProduct.product.price,
                                                    mapProduct.product
                                                      .sizeColor,
                                                    mapProduct.product
                                                      .description,
                                                    mapProduct.product
                                                      .companyName,
                                                    this.props.match.params
                                                      .strname
                                                  )
                                                }
                                                type="button"
                                                className="btn btn-outline-warning btn-flat float-right"
                                              >
                                                <i class="fa fa-plus-circle"></i>
                                              </button>
                                            </div>
                                          </React.Fragment>
                                        )}
                                        {this.props.counter.filter(
                                          (u) =>
                                            u.productId ===
                                            mapProduct.product.productId
                                        ).length < 1 && (
                                          <React.Fragment>
                                            <br />
                                            <small className="text-muted m-2">
                                              Rs{mapProduct.product.price}.00{" "}
                                            </small>
                                            <div class="description-block">
                                              <button
                                                onClick={() =>
                                                  this.props.onAdd(
                                                    this.props.user.Id,
                                                    mapProduct.product
                                                      .productId,
                                                    (mapProduct.product.type &&
                                                      mapProduct.product.type
                                                        .typeName) ||
                                                      "",
                                                    mapProduct.product.code,
                                                    mapProduct.product.price,
                                                    mapProduct.product
                                                      .sizeColor,
                                                    mapProduct.product
                                                      .description,
                                                    mapProduct.product
                                                      .companyName,
                                                    this.props.match.params
                                                      .strname
                                                  )
                                                }
                                                type="button"
                                                className="btn btn-block btn-outline-warning btn-flat"
                                              >
                                                <small>
                                                  <i className="fas fa-cart-plus fa-lg"></i>
                                                  Add to Cart
                                                </small>
                                              </button>
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {shopSub
                                .filter(
                                  (mapstr1) =>
                                    mapstr1.parentCategory.catId == mapstr.catId
                                )
                                .map((mapstr1) => (
                                  <div className="callout callout-info">
                                    <li className="nav-item has-treeview">
                                      <a href="#" className="nav-link">
                                        <div>
                                          {mapstr1.catName}
                                          {" ( "}
                                          {
                                            product.filter(
                                              (u) =>
                                                u.product.catId == mapstr1.catId
                                            ).length
                                          }{" "}
                                          {")"}
                                          <i className="right fas fa-angle-left float-right"></i>
                                        </div>
                                      </a>
                                      <ul className="nav nav-treeview">
                                        <div class="card-body p-0">
                                          <ul class="users-list clearfix">
                                            {shopSub
                                              .filter(
                                                (mapstr11) =>
                                                  mapstr11.parentCategory
                                                    .catId == mapstr1.catId
                                              )
                                              .map((mapstr11) => (
                                                <li>
                                                  <img
                                                    src="../dist/img/avatar/abc.jpg"
                                                    alt="User Image"
                                                  />
                                                  <a
                                                    class="users-list-name"
                                                    href="#"
                                                  >
                                                    {mapstr11.catName}
                                                  </a>
                                                  <span class="users-list-date">
                                                    {mapstr11.catName}
                                                  </span>
                                                </li>
                                              ))}
                                          </ul>
                                          <div class="row">
                                            {product
                                              .filter(
                                                (mapProduct) =>
                                                  mapProduct.product.catId ===
                                                  mapstr1.catId
                                              )
                                              .map((mapProduct) => (
                                                <div class="col-lg-3 col-4">
                                                  <div class="small-box">
                                                    <img
                                                      className="d-block w-100"
                                                      src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                                      alt="Second slide"
                                                    />
                                                    <small className="text-muted m-2">
                                                      {`  ${
                                                        (mapProduct.product
                                                          .type &&
                                                          mapProduct.product
                                                            .type.typeName) ||
                                                        ""
                                                      } ${
                                                        mapProduct.product
                                                          .description || ""
                                                      } ${
                                                        mapProduct.product
                                                          .sizeColor || ""
                                                      }
                                      ${
                                        (mapProduct.product.company &&
                                          mapProduct.product.company
                                            .companyName) ||
                                        ""
                                      }
                                      `}
                                                    </small>
                                                    {this.props.counter.filter(
                                                      (u) =>
                                                        u.productId ===
                                                        mapProduct.product
                                                          .productId
                                                    ).length > 0 && (
                                                      <React.Fragment>
                                                        <div class="description-block">
                                                          <p>
                                                            {this.props.counter
                                                              .filter(
                                                                (u) =>
                                                                  u.productId ===
                                                                  mapProduct
                                                                    .product
                                                                    .productId
                                                              )
                                                              .map((mapstr) => (
                                                                <React.Fragment>
                                                                  <br />
                                                                  <small className="text-muted m-2">
                                                                    {mapstr.qty}{" "}
                                                                    x{" "}
                                                                    {
                                                                      mapProduct
                                                                        .product
                                                                        .price
                                                                    }
                                                                    .00 Rs
                                                                  </small>
                                                                  {mapstr.qty >
                                                                    1 && (
                                                                    <button
                                                                      onClick={() =>
                                                                        this.props.onLess(
                                                                          mapProduct
                                                                            .product
                                                                            .productId
                                                                        )
                                                                      }
                                                                      type="button"
                                                                      className="btn btn-outline-warning btn-lg-sm btn-flat float-left"
                                                                    >
                                                                      <i class="fa fa-minus-circle"></i>
                                                                    </button>
                                                                  )}
                                                                  {mapstr.qty <
                                                                    2 && (
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-warning btn-flat float-left"
                                                                      onClick={() =>
                                                                        this.props.onDel(
                                                                          mapProduct
                                                                            .product
                                                                            .productId
                                                                        )
                                                                      }
                                                                    >
                                                                      <i class="fa fa-trash"></i>
                                                                    </button>
                                                                  )}
                                                                </React.Fragment>
                                                              ))}
                                                            <button
                                                              onClick={() =>
                                                                this.props.onAdd(
                                                                  this.props
                                                                    .user.Id,
                                                                  mapProduct
                                                                    .product
                                                                    .productId,
                                                                  (mapProduct
                                                                    .product
                                                                    .type &&
                                                                    mapProduct
                                                                      .product
                                                                      .type
                                                                      .typeName) ||
                                                                    "",
                                                                  mapProduct
                                                                    .product
                                                                    .code,
                                                                  mapProduct
                                                                    .product
                                                                    .price,
                                                                  mapProduct
                                                                    .product
                                                                    .sizeColor,
                                                                  mapProduct
                                                                    .product
                                                                    .description,
                                                                  mapProduct
                                                                    .product
                                                                    .companyName,
                                                                  this.props
                                                                    .match
                                                                    .params
                                                                    .strname
                                                                )
                                                              }
                                                              type="button"
                                                              className="btn btn-outline-warning btn-flat float-right"
                                                            >
                                                              <i class="fa fa-plus-circle"></i>
                                                            </button>
                                                            <br />
                                                          </p>
                                                        </div>
                                                      </React.Fragment>
                                                    )}
                                                    {this.props.counter.filter(
                                                      (u) =>
                                                        u.productId ===
                                                        mapProduct.product
                                                          .productId
                                                    ).length < 1 && (
                                                      <React.Fragment>
                                                        <br />
                                                        <small className="text-muted m-2">
                                                          Rs
                                                          {
                                                            mapProduct.product
                                                              .price
                                                          }
                                                          .00{" "}
                                                        </small>
                                                        <div class="description-block">
                                                          <button
                                                            onClick={() =>
                                                              this.props.onAdd(
                                                                this.props.user
                                                                  .Id,
                                                                mapProduct
                                                                  .product
                                                                  .productId,
                                                                (mapProduct
                                                                  .product
                                                                  .type &&
                                                                  mapProduct
                                                                    .product
                                                                    .type
                                                                    .typeName) ||
                                                                  "",
                                                                mapProduct
                                                                  .product.code,
                                                                mapProduct
                                                                  .product
                                                                  .price,
                                                                mapProduct
                                                                  .product
                                                                  .sizeColor,
                                                                mapProduct
                                                                  .product
                                                                  .description,
                                                                mapProduct
                                                                  .product
                                                                  .companyName,
                                                                this.props.match
                                                                  .params
                                                                  .strname
                                                              )
                                                            }
                                                            type="button"
                                                            className="btn btn-block btn-outline-warning btn-flat"
                                                          >
                                                            <small>
                                                              <i className="fas fa-cart-plus fa-lg"></i>
                                                              Add to Cart
                                                            </small>
                                                          </button>
                                                        </div>
                                                      </React.Fragment>
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      </ul>
                                    </li>
                                  </div>
                                ))}
                            </ul>
                          </nav>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {/* <nav className="mt-2">
              <ul
                className="nav nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Home
                      <span className="right badge badge-danger">New</span>
                    </p>
                  </Link>
                </li>
                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <div>
                      Dashboard
                      <i className="right fas fa-angle-left float-right"></i>
                    </div>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Dashboard v1</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Dashboard v2</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Dashboard v3</p>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item has-treeview">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-book"></i>
                    <p>
                      Bill Book
                      <i className="fas fa-angle-left right"></i>
                      <span className="badge badge-info right">6</span>
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/receipt" className="nav-link">
                        <i className="far fa-plus-square nav-icon"></i>
                        <p>Receipt/Bill</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a href="#" className="nav-link">
                        <i className="far fa-credit-card nav-icon"></i>
                        <p>Bill Summary</p>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="col-md-6"></div>
            <div className="col-md-6"></div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Shop;
