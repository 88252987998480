import React, { Component } from "react";

class CounterKart extends Component {
  render() {
    return (
      <tr>
        <td>
          <div class="product-img">
            <img
              src={`../../store/${this.props.counter.strname}/${this.props.counter.productId}.jpg`}
              alt=""
              class="img-size-50"
            />
          </div>
        </td>
        <td>
          {this.formatCount()} x
        </td>
        <td>
          {this.props.counter.typeName}
          <br />
          {this.props.counter.description} {this.props.counter.sizeColor}{" "}
          {this.props.counter.code}{" "}
        </td>
        <td>
          Rs {this.props.counter.price}
        </td>
      </tr>
    );
  }

  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.props.counter.qty === 0 ? "warning" : "primary";
    return classes;
  }

  formatCount() {
    const { qty } = this.props.counter;
    return qty === 0 ? "Zero" : qty;
  }
}

export default CounterKart;
