import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import {
  getStoreGroup,
  getStoreGroupUser,
  getStoreDesignation,
} from "./services/serviceStore";
import ListStoreGroup from "./common/listStoreGroup";
import ListStoreDesignation from "./common/listStoreDesignation";

class ListStoreUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeGroup: [],
      storeGroupUser: [],
      storeDesignation: [],
    };
  }
  async componentDidMount() {
    const { data: storeDesg } = await getStoreDesignation();
    const storeDesignation = [
      { designationId: "", designationName: "All Designation" },
      ...storeDesg,
    ];



    //const { data: storeGroupUser } = await getStoreGroupUser();
    if (
      (!this.props.user.Id && this.props.user.Role === "Manager") ||
      (!this.props.user.Id && this.props.user.Role === "CEO")
    ) {
      const { data } = await axios.get(
        `https://api.hazaramart.com/api/storegroup/${this.props.user.IdMaster}`
      );
      const storeGroup = [{ storeId: "", storeTitle: "All STORES" }, ...data];
      const { data: storeGroupUser } = await axios.get(
        `https://api.hazaramart.com/api/storeusergroup/${this.props.user.IdStr}`
      );
      this.setState({ storeGroup, storeGroupUser });
    } else if (this.props.user.Id) {
      const { data } = await axios.get(
        `https://api.hazaramart.com/api/storegroup/${this.props.user.Id}`
      );
      const storeGroup = [{ storeId: "", storeTitle: "All STORES" }, ...data];
      const { data: storeGroupUser } = await axios.get(
        `https://api.hazaramart.com/api/storeusermaster/${this.props.user.Id}`
      );
      this.setState({ storeGroup, storeGroupUser });
    }

    this.setState({ storeDesignation });
  }
  handleStoreSelect = (storeGroup) => {
    this.setState({ selectedStore: storeGroup });
    console.log(storeGroup);
  };
  handleDsgnSelect = (storeDesignation) => {
    this.setState({ selectedDsgn: storeDesignation });
    console.log(storeDesignation);
  };
  render() {
    const { user } = this.props;
    const {
      selectedStore,
      storeGroupUser: AllStore,
      storeDesignation,
    } = this.state;
    if (AllStore.length === 0) return <p>Access Denied</p>;
    const filtered =
      selectedStore && selectedStore.storeId
        ? AllStore.filter((u) => u.storeId === selectedStore.storeId)
        : AllStore;
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Store User Panel</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink tag={Link} to="/StoreUser">
                      List
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    <NavLink tag={Link} to="/adduser">
                      Add
                    </NavLink>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                {/* {user && user.Role === "Demo" && ( */}
                <div className="card card-primary card-outline">
                  <ListStoreGroup
                    itemss={this.state.storeGroup}
                    selectedStore={this.state.selectedStore}
                    onStoreSelect={this.handleStoreSelect}
                  />
                </div>
                {/*})}*/}
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Store Users</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <ul className="nav nav-pills flex-column">
                      <ListStoreDesignation
                        itemsss={this.state.storeDesignation}
                        selectedDsgn={this.state.selectedDsgn}
                        onDsgnSelect={this.handleDsgnSelect}
                      />
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          <i className="far fa-circle text-danger"></i>
                          Important
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          <i className="far fa-circle text-warning"></i>{" "}
                          Promotions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          <i className="far fa-circle text-primary"></i>
                          Social
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          Showing {filtered.length} users in the database.
                        </h3>
                      </div>

                      <div className="card-body">
                        <table className="table table-bordered table-hover">
                          <thead className="btn-primary">
                            <tr>
                              <th>User Name</th>
                              <th>Password</th>
                              <th>Designation</th>
                              <th>Store ID</th>
                              <th>Status</th>
                              <th></th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filtered.map((storeuser) => (
                              <tr key={storeuser.storeUserId}>
                                <td>
                                  <Link
                                    to={`/adduser/${storeuser.storeUserId}`}
                                  >
                                    {storeuser.storeUserName}
                                  </Link>
                                </td>
                                <td>{storeuser.storeUserPassword}</td>
                                <td>{!user.Id && storeuser.designationId}</td>
                                <td>{storeuser.storeId}</td>
                                <td></td>
                                <td>{storeuser.storeUserStatus}</td>
                                <td>
                                  <Link
                                    className="btn btn-info btn-sm m-2"
                                    to={`/adduser/${storeuser.storeUserId}`}
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Link>
                                  <button
                                    onClick={() =>
                                      this.deleteUser(storeuser.userId)
                                    }
                                    className="btn btn-danger btn-sm"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ListStoreUser;
