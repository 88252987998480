import React, { Component } from "react";

class CounterBadge extends Component {
  state = {
    count: 10,
  };
  style = {};
  render() {
    return <span className={this.getBadgeClasses()}>{this.formatCount()}</span>;
  }

  getBadgeClasses() {
    let classes = "badge navbar-badge badge-";
    classes += this.state.count === 0 ? "primary" : "warning";
    return classes;
  }

  formatCount() {
    const { count } = this.state;
    return count === 0 ? "" : count;
  }
}

export default CounterBadge;
