import React from "react";
import { Link, NavLink } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import * as userService from "./services/userService";
import { toast } from "react-toastify";

class RegisterX extends Form {
  state = {
    data: { username: "", password: "", name: "" },
    dupUser: "",
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .min(5)
      .label("Password"),
    name: Joi.string()
      .required()
      .label("Name")
  };

  doSubmit = async () => {
    // Call the server
    //console.log("Submitted");
    //await userService.register(this.state.data);
    await axios
      .get(`https://api.hazaramart.com/api/userduplicate/${this.state.data.username}`)
      .then((response) => {
        if (response.status == 200)
        { 
          toast.warn(`Email is already registered. Try another.`);
          this.setState({dupUser: 200});
        }  
        else
        { 
          userService.register(this.state.data);
        }
          
        /* axios
          .post(`https://api.hazaramart.com/api/stocks`, {
            productId: response.data.productId,
            storeId: this.props.user.IdStr,
            qty: 0,
          })
          .then((response) => {
            toast.success(`Added in Store.`);
          }); */
      })
  };

  render() {
    const { dupUser } = this.state;
    return (

      <div className="hold-transition register-page">
        <div className="register-box">
          <div className="register-logo">
            <a href="../../index2.html">
              <b>AGILITYSOL</b>APP
              </a>
          </div>
          <div className="card">
            <div className="card-body register-card-body">
              <p className="login-box-msg">Register a new membership</p>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("username", "Email")}
                {dupUser==200 && (
                <h4 className="btn btn-block btn-outline-warning btn-flat">Email already registered. Try another.</h4>
                )}
                {this.renderInput("password", "Password", "password")}
                {this.renderInput("name", "Full Name")}

                <div className="row">
                  <div className="col-8">
                  </div>
                  <div className="col-4">
                    {this.renderButton("Register")}
                  </div>
                </div>
              </form>
              <NavLink tag={Link} to="/login">
                I already have a membership
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterX;
