import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import LazyLoad from "react-lazyload";
import SearchBox from "./searchBox";
//import "../../public/plugins/ekko-lightbox.css";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeByStrName: [],
      productTile: [],
      shopMenu: [],
      product: [],
      productPkg: [],
    };
  }
  async componentDidMount() {
    const strnam = this.props.match.params.strname;
    const { data: shopMenu, data: shopSub } = await axios.get(
      `https://api.hazaramart.com/api/CategoryByStrName/${strnam}`
    );
    const { data: productTile } = await axios.get(
      `https://api.hazaramart.com/api/StoreStockTileByStrName/${strnam}`
    );
    this.setState({
      shopMenu,
      shopSub: shopSub.filter((u) => u.parentCategory !== null),
      productTile,
    });
    await axios
      .get(`https://api.hazaramart.com/api/storebystrname/${strnam}`)
      .then((response) => {
        this.setState({ storeByStrName: response.data });
      });
    await axios
      .get(`https://api.hazaramart.com/api/StockByStrName/${strnam}`)
      .then((response) => {
        this.setState({ product: response.data });
      });
    await axios
      .get(`https://api.hazaramart.com/api/StockPkgByStrName/${strnam}`)
      .then((response) => {
        this.setState({ productPkg: response.data });
      });
    /* const { data: storeByStrName } = await axios.get(
      `https://api.hazaramart.com/api/storebystrname/${strnam}`
    ); */

    /* const { data: shopSub } = await axios.get(
      `https://linkwebapi.azurewebsites.net/api/CategoryByStrName/${strnam}`
    ); */
  }
  Loading = () => <div>...</div>;
  Spinner = () => (
    <div className="post loading">
      <h5>Loading...</h5>
    </div>
  );
  handleSearchTile = (query) => {
    this.setState({ searchTile: query });
  };
  render() {
    const {
      productTile: AllTile,
      searchTile,
      shopMenu,
      shopSub,
      product,
      productPkg,
      storeByStrName,
    } = this.state;
    let filterTile = AllTile;
    if (searchTile)
      filterTile = AllTile.filter(
        (t) =>
          t.code.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.size.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.meter.toString().startsWith(searchTile.toString())
      );
    return (
      <React.Fragment>
        <section className="content-header" />
        {/* <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  {(this.props.match.params.strname || "").toUpperCase()}
                  Store
                </h1>
                <h6> powered by: AgilitySol</h6>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add / Edit Product</li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
        <div className="content">
          <div className="card">
            <nav className="navbar navbar-expand-md navbar-light navbar-white">
              <a href="#">
                <img
                  src={`../../store/${this.props.match.params.strname}/logo.png`}
                  alt="Logo"
                  className="img-size-50 mr-3 img-circle elevation-3"
                  style={{ opacity: 0.8 }}
                />
              </a>

              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                Book Order Now
              </button>

              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">...</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler order-1"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse order-3"
                id="navbarCollapse"
              >
                <ul className="navbar-nav">
                  {shopMenu
                    .filter((mapstr) => mapstr.parentCategory == null)
                    .map((mapstr) => (
                      <li
                        key={mapstr.catId}
                        className={
                          shopSub.filter(
                            (u) => u.parentCategory.catId === mapstr.catId
                          ).length > 0
                            ? "nav-item dropdown"
                            : "nav-item"
                        }
                      >
                        <a
                          id="dropdownSubMenu1"
                          href="#"
                          data-toggle={
                            shopSub.filter(
                              (u) => u.parentCategory.catId === mapstr.catId
                            ).length > 0
                              ? "dropdown"
                              : ""
                          }
                          aria-haspopup="true"
                          aria-expanded="false"
                          className={
                            shopSub.filter(
                              (u) => u.parentCategory.catId === mapstr.catId
                            ).length > 0
                              ? "nav-link dropdown-toggle"
                              : "nav-link"
                          }
                        >
                          {mapstr.catName}
                        </a>
                        <ul
                          aria-labelledby="dropdownSubMenu1"
                          className="dropdown-menu border-0 shadow"
                        >
                          <li
                            href="#"
                            className="dropdown-submenu"
                            key={mapstr.catId}
                          >
                            <a href="#">- {mapstr.catName}</a>
                          </li>
                          {shopSub
                            .filter(
                              (mapstr1) =>
                                mapstr1.parentCategory.catId === mapstr.catId
                            )
                            .map((mapstr1) => (
                              <li
                                key={mapstr1.catId}
                                className="dropdown-submenu dropdown-hover"
                              >
                                <a
                                  id="dropdownSubMenu2"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className={
                                    shopSub.filter(
                                      (u) =>
                                        u.parentCategory.catId === mapstr1.catId
                                    ).length > 0
                                      ? "dropdown-item dropdown-toggle"
                                      : "dropdown-item"
                                  }
                                >
                                  {mapstr1.catName}
                                </a>
                                {shopSub
                                  .filter(
                                    (mapstr11) =>
                                      mapstr11.parentCategory.catId ===
                                      mapstr1.catId
                                  )
                                  .map((mapstr11) => (
                                    <ul
                                      aria-labelledby="dropdownSubMenu2"
                                      className="dropdown-menu border-0 shadow"
                                    >
                                      <li>
                                        <a
                                          href="#"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          className="dropdown-item"
                                        >
                                          {mapstr11.catName}
                                        </a>
                                      </li>
                                    </ul>
                                  ))}
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            </nav>
            <div className="card card-primary card-outline">
              <div className="card-body">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators"
                      data-slide-to="2"
                    ></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        className="d-block w-100"
                        src={`../../store/${this.props.match.params.strname}/slide1.gif`}
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={`../../store/${this.props.match.params.strname}/slide2.gif`}
                        alt="Second slide"
                      />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>nayaLink</h5>
                        <p>www.nayalink.com</p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        className="d-block w-100"
                        src={`../../store/${this.props.match.params.strname}/slide3.gif`}
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
            {shopMenu
              .filter((mapstr) => mapstr.parentCategory == null)
              .map((mapstr) => (
                <React.Fragment>
                  <div className="card-header">
                    <h2 key={mapstr.catId} className="m-0 text-primary">
                      {mapstr.catName}
                    </h2>
                    <div className="container my-4">
                      <div className="row">
                        {product
                          .filter(
                            (mapProduct) =>
                              mapProduct.product.catId === mapstr.catId
                          )
                          .slice(0, 4)
                          .map((mapProduct) => (
                            <div className="col-md-3 clearfix d-none d-md-block">
                              <div className="card mb-2">
                                <img
                                  className="card-img-top"
                                  src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                  alt="Card image cap"
                                />
                                <div>
                                  <small className="text-muted m-2">
                                    {`  ${
                                      (mapProduct.product.type &&
                                        mapProduct.product.type.typeName) ||
                                      ""
                                    } ${mapProduct.product.description || ""} ${
                                      mapProduct.product.sizeColor || ""
                                    }
                                    ${
                                      (mapProduct.product.company &&
                                        mapProduct.product.company.companyName) ||
                                      ""
                                    }
                                    `}
                                  </small>

                                  <button
                                    onClick={() =>
                                      this.props.onAdd(
                                        this.props.user.Id,
                                        mapProduct.product.productId,
                                        mapProduct.product.type.typeName  || "",
                                        mapProduct.product.code,
                                        mapProduct.product.price,
                                        mapProduct.product.sizeColor,
                                        mapProduct.product.description,
                                        mapProduct.product.companyName,
                                        this.props.match.params.strname
                                      )
                                    }
                                    type="button"
                                    className="btn btn-block btn-outline-warning btn-flat"
                                  >
                                    <h4 className="mt-0">
                                      <small>
                                        Rs{mapProduct.product.price}.00
                                      </small>
                                    </h4>
                                    <i className="fas fa-cart-plus fa-lg mr-2"></i>
                                    Add to Cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <button
                        type="button"
                        className="btn btn-block btn-secondary btn-xs"
                      >
                        View all
                      </button>
                    </div>
                    {shopSub
                      .filter(
                        (mapstr1) =>
                          mapstr1.parentCategory.catId === mapstr.catId
                      )
                      .map((mapstr1) => (
                        <React.Fragment>
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <NavLink
                                key={mapstr1.catId}
                                tag={Link}
                                to="/StoreUser"
                              >
                                {mapstr1.catName}
                                {" ( "}
                                {
                                  product.filter(
                                    (u) => u.product.catId === mapstr1.catId
                                  ).length
                                }{" "}
                                {")"}
                              </NavLink>
                            </li>
                          </ol>

                          <div className="container my-4">
                            <div className="row">
                              {product
                                .filter(
                                  (mapProduct) =>
                                    mapProduct.product.catId == mapstr1.catId
                                )
                                .slice(0, 22)
                                .map((mapProduct) => (
                                  <div className="col-md-3 clearfix d-none d-md-block">
                                    <div className="card mb-2">
                                      <img
                                        className="card-img-top"
                                        src={`../../store/${this.props.match.params.strname}/${mapProduct.product.productId}.jpg`}
                                        alt="Card image cap"
                                      />

                                      <small className="text-muted m-2">
                                        {`  ${
                                          mapProduct.product.type.typeName || ""
                                        } ${
                                          mapProduct.product.description || ""
                                        } ${mapProduct.product.sizeColor || ""}
                 ${mapProduct.product.company.companyName || ""}
                `}
                                        {/* {mapProduct.product.type.typeName}{" "}
                                          {mapProduct.product.description}{" "}
                                          {mapProduct.product.sizeColor} -{" "}
                                          {
                                            mapProduct.product.company
                                              .companyName
                                          } */}
                                        <br />
                                        {productPkg
                                          .filter(
                                            (mapProductPkg) =>
                                              mapProductPkg.pkgId ===
                                              mapProduct.product.productId
                                          )
                                          .map((mapProductPkg) => (
                                            <a>
                                              -
                                              {
                                                mapProductPkg.product
                                                  .description
                                              }
                                            </a>
                                          ))}
                                      </small>

                                      <button
                                        onClick={() =>
                                          this.props.onAdd(
                                            this.props.user.Id,
                                            mapProduct.product.productId,
                                            mapProduct.product.type.typeName  || "",
                                            mapProduct.product.code,
                                            mapProduct.product.price,
                                            mapProduct.product.sizeColor,
                                            mapProduct.product.description,
                                            mapProduct.product.companyName,
                                            this.props.match.params.strname
                                          )
                                        }
                                        type="button"
                                        className="btn btn-block btn-outline-warning btn-flat"
                                      >
                                        <h4 className="mt-0">
                                          <small>
                                            Rs{mapProduct.product.price}.00
                                          </small>
                                        </h4>
                                        <i className="fas fa-cart-plus fa-lg mr-2"></i>
                                        Add to Cart
                                      </button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <button
                              type="button"
                              className="btn btn-block btn-secondary btn-xs"
                            >
                              View all
                            </button>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </React.Fragment>
              ))}
            <div className="card-header">
              <h1 className="m-0 text-primary">Tile</h1>
              <br />
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink tag={Link} to="/StoreUser">
                    Bathroom Tile
                  </NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink tag={Link} to="/adduser">
                    Floor Tile
                  </NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink tag={Link} to="/adduser">
                    Kitchen Tile
                  </NavLink>
                </li>
              </ol>
            </div>
            <div className="container my-4">
              <div className="card-footer">
                <h3 className="m-0 text-primary">Bathroom</h3>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="card mb-2">
                    <img
                      className="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Card title</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 clearfix d-none d-md-block">
                  <div className="card mb-2">
                    <img
                      className="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(18).jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Card title</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 clearfix d-none d-md-block">
                  <div className="card mb-2">
                    <img
                      className="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(18).jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Card title</h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 clearfix d-none d-md-block">
                  <div className="card mb-2">
                    <img
                      className="card-img-top"
                      src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(35).jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Card title</h4>
                    </div>
                  </div>
                </div>
              </div>
              <a className="btn btn-primary float-right">Button</a>
            </div>
            <div className="container my-4">
              <div
                id="multi-item-example"
                className="carousel slide carousel-multi-item"
                data-ride="carousel"
              >
                <div className="controls-top">
                  <a
                    className="btn-floating"
                    href="#multi-item-example"
                    data-slide="prev"
                  >
                    <i className="fa fa-chevron-left"></i>
                  </a>
                  <a
                    className="btn-floating"
                    href="#multi-item-example"
                    data-slide="next"
                  >
                    <i className="fa fa-chevron-right"></i>
                  </a>
                </div>

                <ol className="carousel-indicators">
                  <li
                    data-target="#multi-item-example"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#multi-item-example" data-slide-to="1"></li>
                  <li data-target="#multi-item-example" data-slide-to="2"></li>
                </ol>

                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(18).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(35).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(60).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(47).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/City/4-col/img%20(48).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(53).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(45).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 clearfix d-none d-md-block">
                        <div className="card mb-2">
                          <img
                            className="card-img-top"
                            src="https://mdbootstrap.com/img/Photos/Horizontal/Food/4-col/img%20(51).jpg"
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Card title</h4>
                            <p className="card-text">
                              Some quick example text to build on the card title
                              and make up the bulk of the card's content.
                            </p>
                            <a className="btn btn-primary">Button</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="carousel-thumb"
              className="carousel slide carousel-fade carousel-thumbnails"
              data-ride="carousel"
            >
              <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100"
                    src={`../../store/${this.props.match.params.strname}/slide1.gif`}
                    alt="First slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src={`../../store/${this.props.match.params.strname}/slide2.gif`}
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src={`../../store/${this.props.match.params.strname}/slide3.gif`}
                    alt="Third slide"
                  />
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#carousel-thumb"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carousel-thumb"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>

              <ol className="carousel-indicators">
                <li
                  data-target="#carousel-thumb"
                  data-slide-to="0"
                  className="active"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(88).jpg"
                    width="100"
                  />
                </li>
                <li data-target="#carousel-thumb" data-slide-to="1">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(121).jpg"
                    width="100"
                  />
                </li>
                <li data-target="#carousel-thumb" data-slide-to="2">
                  <img
                    src="https://mdbootstrap.com/img/Photos/Others/Carousel-thumbs/img%20(31).jpg"
                    width="100"
                  />
                </li>
              </ol>
            </div>
            <div className="container my-4">
              <br />
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
                  <li data-target="#myCarousel" data-slide-to="3"></li>
                </ol>

                <div className="carousel-inner" role="listbox">
                  <div className="item active">
                    <div className="span4" style={{ paddingleft: 18 }}>
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="span4" style={{ paddingleft: 18 }}>
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                      <img
                        src="http://placehold.it/350x180"
                        className="img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <a
                  className="right carousel-control"
                  href="#myCarousel"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="glyphicon glyphicon-chevron-right"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="navbar navbar-white navbar-light"
                  style={{ marginLeft: 20 }}
                >
                  New Arrivals
                </div>
                <div className="card-body">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        className="active"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="1"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="2"
                      ></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          className="d-block w-100"
                          src="http://placehold.it/290x290"
                          alt="First slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src="../../dist/img/photo1.png"
                          alt="Second slide"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          className="d-block w-100"
                          src="../../dist/img/photo2.png"
                          alt="Third slide"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="navbar navbar-white navbar-light">
                  Total design(s) {filterTile.length} available.
                  <SearchBox
                    value={searchTile}
                    onChange={this.handleSearchTile}
                  />
                </div>
                <div className="row">
                  {filterTile.map((productTile) => (
                    <LazyLoad
                      key={productTile.productTileId}
                      placeholder={<this.Loading />}
                    >
                      <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                          <a
                            href={`../../store/${this.props.match.params.strname}/${productTile.code}.png`}
                            data-toggle="lightbox"
                            data-title={productTile.code}
                            data-gallery="gallery"
                          >
                            <LazyLoad
                              once={true}
                              placeholder={
                                <img
                                  src="https://www.eliananunes.com/images/lazy_loader.gif"
                                  alt="..."
                                />
                              }
                            >
                              <img
                                className="card-img-top"
                                src={`../../store/${this.props.match.params.strname}/${productTile.code}.png`}
                                alt=""
                              />
                            </LazyLoad>
                          </a>
                          <div
                            className="card-body"
                            key={productTile.productTileId}
                          >
                            <h4>
                              <a href="#">{productTile.code}</a>
                            </h4>
                            <h5>Rs {productTile.price}</h5>
                            <p className="card-text">
                              Packing Box {productTile.packing}
                              <br />
                              Meter {productTile.meter}
                            </p>
                          </div>
                          <div className="card-footer">
                            <small className="text-muted">
                              {productTile.size}
                            </small>
                          </div>
                        </div>
                      </div>
                    </LazyLoad>
                  ))}
                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                      <a href="#">
                        <img
                          className="card-img-top"
                          src="../../store/amc/A9010 A.png"
                          alt=""
                        />
                      </a>
                      <div className="card-body">
                        <h4 className="card-title">
                          <a href="#">Item Two</a>
                        </h4>
                        <h5>$24.99</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Amet numquam aspernatur! Lorem ipsum dolor sit
                          amet.
                        </p>
                      </div>
                      <div className="card-footer">
                        <small className="text-muted">
                          &#9733; &#9733; &#9733; &#9733; &#9734;
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                      <a href="#">
                        <img
                          className="card-img-top"
                          src="http://placehold.it/700x400"
                          alt=""
                        />
                      </a>
                      <div className="card-body">
                        <h4 className="card-title">
                          <a href="#">Item Three</a>
                        </h4>
                        <h5>$24.99</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Amet numquam aspernatur!
                        </p>
                      </div>
                      <div className="card-footer">
                        <small className="text-muted">
                          &#9733; &#9733; &#9733; &#9733; &#9734;
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                      <a href="#">
                        <img
                          className="card-img-top"
                          src="http://placehold.it/700x400"
                          alt=""
                        />
                      </a>
                      <div className="card-body">
                        <h4 className="card-title">
                          <a href="#">Item Four</a>
                        </h4>
                        <h5>$24.99</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Amet numquam aspernatur!
                        </p>
                      </div>
                      <div className="card-footer">
                        <small className="text-muted">
                          &#9733; &#9733; &#9733; &#9733; &#9734;
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                      <a href="#">
                        <img
                          className="card-img-top"
                          src="http://placehold.it/700x400"
                          alt=""
                        />
                      </a>
                      <div className="card-body">
                        <h4 className="card-title">
                          <a href="#">Item Five</a>
                        </h4>
                        <h5>$24.99</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Amet numquam aspernatur! Lorem ipsum dolor sit
                          amet.
                        </p>
                      </div>
                      <div className="card-footer">
                        <small className="text-muted">
                          &#9733; &#9733; &#9733; &#9733; &#9734;
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                      <a href="#">
                        <img
                          className="card-img-top"
                          src="http://placehold.it/700x400"
                          alt=""
                        />
                      </a>
                      <div className="card-body">
                        <h4 className="card-title">
                          <a href="#">Item Six</a>
                        </h4>
                        <h5>$24.99</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Amet numquam aspernatur!
                        </p>
                      </div>
                      <div className="card-footer">
                        <small className="text-muted">
                          &#9733; &#9733; &#9733; &#9733; &#9734;
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Shop;
