import React, { Component } from "react";
class ControlSidebar extends Component {
  state = {};
  render() {
    return (
      <aside className="control-sidebar control-sidebar-dark">
        <div className="p-3">
          <h5>Announcement</h5>
          <p>
            One month free trial!<br></br>Register Now!!
          </p>
        </div>
      </aside>
    );
  }
}

export default ControlSidebar;
