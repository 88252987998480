import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Joi from "joi-browser";
import FormAddUser from "./common/formAddUser";
import { getCompany } from "./services/serviceProduct";
import SearchBox from "./searchBox";
import { toast } from "react-toastify";

class Product extends FormAddUser {
  state = {
    data: {
      companyId: "",
      storeId: "",
      masterId: "",
      productType: "",
      productCode: "",
      productDescription: "",
      productSizeColor: "",
      priceLocal: "",
      pricePurchaseLocal: "",
      quantityLocal: "",
      quantityReturnLocal: "",
      storeIdTile: "",
      masterIdTile: "",
      company: "",
      type: "",
      code: "",
      packing: "",
      size: "",
      meter: "",
      price: "",
      pricePurchase: "",
      qtyBox: "",
      qtyPcs: "",
    },
    storeCompanies: [],
    productLocalGroup: [],
    productTile: [],
    productDetail: [],
    tileDetail: [],
    searchQuery: "",
    searchTile: "",
    errors: {},
  };
  schema = {
    productLocal1: Joi.string(),
    companyId: Joi.label("CompanyId"),

    productType: Joi.string().label("ProductType"),
    productCode: Joi.label("ProductCode"),
    productDescription: Joi.required().label("ProductDescription"),
    productSizeColor: Joi.required().label("productSizeColor"),
    priceLocal: Joi.required().label("priceLocal"),
    pricePurchaseLocal: Joi.required().label("pricePurchaseLocal"),
    productTileId: Joi.string(),
    company: Joi.string().label("company"),
    code: Joi.string().label("code"),
    packing: Joi.string().label("packing"),
    size: Joi.string().label("size"),
    meter: Joi.string().label("meter"),
    price: Joi.string().label("price"),
    pricePurchase: Joi.string().label("pricePurchase"),
    qtyBox: Joi.string().label("qtyBox"),
    qtyPcs: Joi.string().label("qtyPcs"),
  };
  async componentDidMount() {
    const { data: storeCompanies } = await getCompany();
    const { data: productLocalGroup } = await axios.get(
      `https://api.hazaramart.com/api/productlocalgroup/${this.props.user.IdMaster}`
    );
    const { data: productTile } = await axios.get(
      `https://api.hazaramart.com/api/StoreStockTiles/${this.props.user.IdMaster}`
    );
    this.setState({ storeCompanies, productLocalGroup, productTile });
  }
  mapToViewModel(productDetail) {
    return {
      productLocal1: productDetail.productLocal1,
      companyId: productDetail.companyId,
      storeId: productDetail.storeId,
      masterId: productDetail.masterId,
      productType:
        productDetail.productType === null ? "" : productDetail.productType,
      productCode:
        productDetail.productCode === null ? "" : productDetail.productCode,
      productDescription:
        productDetail.productDescription === null
          ? ""
          : productDetail.productDescription,
      productSizeColor:
        productDetail.productSizeColor === null
          ? ""
          : productDetail.productSizeColor,
      priceLocal:
        productDetail.priceLocal === null ? "" : productDetail.priceLocal,
      pricePurchaseLocal:
        productDetail.pricePurchaseLocal === null
          ? ""
          : productDetail.pricePurchaseLocal,
    };
  }
  mapToViewModelTile(tileDetail) {
    return {
      productTileId: tileDetail.productTileId,
      company: tileDetail.company,
      storeIdTile: tileDetail.storeId,
      masterIdTile: tileDetail.masterId,
      type: tileDetail.type,
      code: tileDetail.code === null ? "" : tileDetail.code,
      packing: tileDetail.packing === null ? "" : tileDetail.packing,
      size: tileDetail.size === null ? "" : tileDetail.size,
      meter: tileDetail.meter === null ? "" : tileDetail.meter,
      price: tileDetail.price === null ? "" : tileDetail.price,
      pricePurchase:
        tileDetail.pricePurchase === null ? "" : tileDetail.pricePurchase,
      qtyBox: tileDetail.qtyBox === null ? "" : tileDetail.qtyBox,
      qtyPcs: tileDetail.qtyPcs === null ? "" : tileDetail.qtyPcs,
    };
  }
  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };
  handleSearchTile = (query) => {
    this.setState({ searchTile: query });
  };
  async handleProductEdit(id) {
    const { data: productDetail } = await axios.get(
      `https://api.hazaramart.com/api/productlocals/${id}`
    );
    this.setState({ data: this.mapToViewModel(productDetail) });
  }
  async handleTileEdit(id) {
    const { data: tileDetail } = await axios.get(
      `https://api.hazaramart.com/api/producttiles/${id}`
    );
    this.setState({ data: this.mapToViewModelTile(tileDetail) });
  }
  async editTodo(id) {
    if (id) {
      const itemToEdit = { ...this.state.data };
      await axios
        .put(`https://api.hazaramart.com/api/productlocals/${id}`, itemToEdit)
        .then((response) => {
          toast.success("Successfully Updated.");
        });
      const { data: productLocalGroup } = await axios.get(
        `https://api.hazaramart.com/api/productlocalgroup/${this.props.user.IdMaster}`
      );
      this.setState({ productDetail: itemToEdit, productLocalGroup });
    } else {
      this.state.data.storeId = this.props.user.IdStr;
      this.state.data.masterId = this.props.user.IdMaster;
      const itemToAdd = { ...this.state.data };
      await axios
        .post(`https://api.hazaramart.com/api/productlocals`, itemToAdd)
        .then((response) => {
          toast.success("Successfully Added.");
        });
      const { data: productLocalGroup } = await axios.get(
        `https://api.hazaramart.com/api/productlocalgroup/${this.props.user.IdMaster}`
      );
      this.setState({ productLocalGroup });
      //this.state.data = "";
      //this.props.history.push("/product");
    }
  }
  async editTodoTile(id) {
    if (id) {
      this.state.data.storeId = this.state.data.storeIdTile;
      this.state.data.masterId = this.state.data.masterIdTile;
      const itemToEdit = { ...this.state.data };
      await axios
        .put(`https://api.hazaramart.com/api/ProductTiles/${id}`, itemToEdit)
        .then((response) => {
          toast.success("Successfully Updated.");
        });
      const { data: productTile } = await axios.get(
        `https://api.hazaramart.com/api/StoreStockTiles/${this.props.user.IdMaster}`
      );
      this.setState({ tileDetail: itemToEdit, productTile });
    } else {
      this.state.data.storeId = this.props.user.IdStr;
      this.state.data.masterId = this.props.user.IdMaster;
      const itemToAdd = { ...this.state.data };
      await axios
        .post(`https://api.hazaramart.com/api/ProductTiles`, itemToAdd)
        .then((response) => {
          toast.success("Successfully Added.");
        });
      const { data: productTile } = await axios.get(
        `https://api.hazaramart.com/api/StoreStockTiles/${this.props.user.IdMaster}`
      );
      this.setState({ productTile });
      //this.state.data = "";
      //this.props.history.push("/product");
    }
  }
  render() {
    const { user } = this.props;
    const {
      productLocalGroup: AllStock,
      productTile: AllTile,
      searchQuery,
      searchTile,
    } = this.state;
    let filtered = AllStock;
    let filterTile = AllTile;
    let pageTitle;
    let actionStatus;
    let pageTitleTile;
    let actionStatusTile;
    if (this.state.data.productLocal1) {
      pageTitle = "Edit Product";
      actionStatus = "Update";
    } else {
      pageTitle = "Add Product";
      actionStatus = "Save";
    }
    if (this.state.data.productTileId) {
      pageTitleTile = "Edit Tile";
      actionStatusTile = "Update";
    } else {
      pageTitleTile = "Add Tile";
      actionStatusTile = "Save";
    }
    if (searchQuery)
      filtered = AllStock.filter(
        (u) =>
          u.productType.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          u.productCode.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          u.productDescription
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase())
      );
    if (searchTile)
      filterTile = AllTile.filter(
        (t) =>
          t.code.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.size.toLowerCase().startsWith(searchTile.toLowerCase()) ||
          t.meter.toString().startsWith(searchTile.toString())
      );
    return (
      <React.Fragment>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Add / Edit Product
                  <small> powered by: AgilitySol</small>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add / Edit Product</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary card-outline-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-three-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-three-home-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-home"
                      role="tab"
                      aria-controls="custom-tabs-three-home"
                      aria-selected="true"
                    >
                      Add New Stock
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-profile-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-profile"
                      role="tab"
                      aria-controls="custom-tabs-three-profile"
                      aria-selected="false"
                    >
                      All New Tile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-messages-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-messages"
                      role="tab"
                      aria-controls="custom-tabs-three-messages"
                      aria-selected="false"
                    >
                      In Store Tile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-settings-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-settings"
                      role="tab"
                      aria-controls="custom-tabs-three-settings"
                      aria-selected="false"
                    >
                      All Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-three-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="custom-tabs-three-home"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-home-tab"
                  >
                    <div className="card">
                      <div className="card-header">
                        <div className="navbar navbar-white navbar-light">
                          Showing {filtered.length} product(s).
                          <SearchBox
                            value={searchQuery}
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="card-tools">
                          <div
                            className="input-group input-group-sm"
                            style={{ width: "150px" }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="card-body table-responsive p-0"
                        style={{ height: "160px" }}
                      >
                        <table className="table table-head-fixed text-nowrap table-hover">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Name</th>
                              <th>Code</th>
                              <th>Description</th>
                              <th>Size/Color</th>
                              <th>Price</th>
                              <th>Purchase</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filtered.map((storeproduct) => (
                              <tr key={storeproduct.productLocal1}>
                                <td>{storeproduct.company.companyName}</td>
                                <td>{storeproduct.productType}</td>
                                <td>{storeproduct.productCode}</td>
                                <td>{storeproduct.productDescription}</td>
                                <td>{storeproduct.productSizeColor}</td>
                                <td>{storeproduct.priceLocal}</td>
                                <td>{storeproduct.pricePurchaseLocal}</td>
                                <td>
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={() =>
                                      this.handleProductEdit(
                                        storeproduct.productLocal1
                                      )
                                    }
                                  >
                                    <i className="fas fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card card-danger">
                      <div className="card-header">
                        <h3 className="card-title">
                          {pageTitle} {this.state.data.productType}{" "}
                          {this.state.data.productDescription}
                        </h3>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                          {/*   <input
                                name="storeUserName"
                                id="storeUserName"
                                value={this.state.data.storeUserName}
                                className="form-control"
                              /> */}

                          <div className="row">
                            {this.renderSelect(
                              "companyId",
                              "Company",
                              this.state.storeCompanies
                            )}
                            {this.renderInput3("productType", "Name")}
                            {this.renderInput6(
                              "productDescription",
                              "Description"
                            )}
                          </div>
                          <div className="row">
                            {this.renderInput3("productCode", "Code")}
                            {this.renderInput3(
                              "productSizeColor",
                              "Size/Color	"
                            )}
                            {this.renderInput3("priceLocal", "Price")}

                            {this.renderInput3(
                              "pricePurchaseLocal",
                              "Purchase"
                            )}
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-danger float-right"
                            onClick={() =>
                              this.editTodo(this.state.data.productLocal1)
                            }
                          >
                            {actionStatus}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-profile"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-profile-tab"
                  >
                    <div className="card">
                      <div className="card-header">
                        <div className="navbar navbar-white navbar-light">
                          Showing {filterTile.length} product(s).
                          <SearchBox
                            value={searchTile}
                            onChange={this.handleSearchTile}
                          />
                        </div>

                        <div className="card-tools">
                          <div
                            className="input-group input-group-sm"
                            style={{ width: "150px" }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="card-body table-responsive p-0"
                        style={{ height: "160px" }}
                      >
                        <table className="table table-head-fixed text-nowrap table-hover">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Code</th>
                              <th>Packing</th>
                              <th>Size</th>
                              <th>Meter</th>
                              <th>Price</th>
                              <th>Box</th>
                              <th>Pcs</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterTile.map((productTile) => (
                              <tr key={productTile.productTileId}>
                                <td>
                                  <Link
                                    to={`/adduser/${productTile.productTileId}`}
                                  >
                                    {productTile.company}
                                  </Link>
                                </td>
                                <td>{productTile.code}</td>
                                <td>{productTile.packing}</td>
                                <td>{productTile.size}</td>
                                <td>{productTile.meter}</td>
                                <td>{productTile.price}</td>
                                <td>{productTile.qtyBox}</td>
                                <td>{productTile.qtyPcs}</td>
                                <td>
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={() =>
                                      this.handleTileEdit(
                                        productTile.productTileId
                                      )
                                    }
                                  >
                                    <i className="fas fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card card-danger">
                      <div className="card-header">
                        <h3 className="card-title">
                          {pageTitleTile} {this.state.data.code}
                        </h3>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                          <div className="row">
                            {this.renderInput2("code", "Code")}
                            {this.renderInput2("packing", "Packing")}
                            {this.renderInput2("size", "Size")}
                            {this.renderInput2("meter", "Meter")}
                            {this.renderInput2("price", "Price")}
                            {this.renderInput2("qtyBox", "Box")}
                          </div>
                          <div className="row">
                            {this.renderInput2("qtyPcs", "Pcs")}
                            {this.renderInput2("pricePurchase", "Purchase")}
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-danger float-right"
                            onClick={() =>
                              this.editTodoTile(this.state.data.productTileId)
                            }
                          >
                            {actionStatusTile}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-messages"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-messages-tab"
                  >
                    <div className="card">
                      <div className="navbar navbar-white navbar-light">
                        Showing {} users in the database..
                      </div>
                      <div className="card-body">
                        <table className="table table-bordered table-hover">
                          <thead className="btn-primary">
                            <tr>
                              <th>Company</th>
                              <th>Code</th>
                              <th>Packing</th>
                              <th>Size</th>
                              <th>Meter</th>
                              <th>Price</th>
                              <th>Box</th>
                              <th>Pcs</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-settings"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-settings-tab"
                  >
                    Pellentesque vestibulum commodo nibh nec blandit. Maecenas
                    neque magna, iaculis tempus turpis ac, ornare sodales
                    tellus. Mauris eget blandit dolor. Quisque tincidunt
                    venenatis vulputate. Morbi euismod molestie tristique.
                    Vestibulum consectetur dolor a vestibulum pharetra. Donec
                    interdum placerat urna nec pharetra. Etiam eget dapibus
                    orci, eget aliquet urna. Nunc at consequat diam. Nunc et
                    felis ut nisl commodo dignissim. In hac habitasse platea
                    dictumst. Praesent imperdiet accumsan ex sit amet facilisis.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Product;
