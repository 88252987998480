import React, { Component } from "react";
import Navbar from "./navbar";
import MainSidebar from "./mainSidebar";
import ContentSpace from "./contentSpace";
import Footer from "./footer";
import ControlSidebar from "./controlSidebar";
import { Container } from "reactstrap";
import Content from "./content";

class LayoutContent extends Component {
  state = {};
  render() {
    return <div className="content-wrapper">{this.props.children}</div>;
  }
}

export default LayoutContent;
