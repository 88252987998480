import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import axios from "axios";
import { login } from "./services/authService";

class LoginX extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .regex(/^[a-zA-Z0-9]{5,16}$/)
      .min(5)
      .required()
      .label("Password")
  };

  doSubmit = async () => {
    // Call the server
    /* const token = axios.post("https://linkwebapi.azurewebsites.net/api/token", {
      Email: "InventoryAdmin@abc.com",
      Password: "$admin@2017"
    });
    console.log(token); */
    try {
      const { data } = this.state;
      const { data: jwtt } = await login(data.username, data.password);
      localStorage.setItem("key", jwtt);
      //this.props.history.push("/");
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
    /* try {
      const { data } = this.state;
      const { data: jwt } = await login(data.username, data.password);
      localStorage.setItem("token", jwt);
      //this.props.history.push('/');
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    } */
  };

  render() {
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <b>Admin</b>AGILITYSOL
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              {/* <p className="login-box-msg">Sign in to start your session</p> */}
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("username", "Username")}
                {this.renderInput("password", "Password", "password")}
                {this.renderButton("Login")}
              </form>
              <div className="mb-2"></div>
              <p className="mb-0">
                <NavLink tag={Link} to="/loginMe">
                  Login Store User
                </NavLink>
              </p>
              <p className="mb-0">
                <NavLink tag={Link} to="/signup">
                  Register a new membership
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginX;
