import React, { Component } from "react";
import CounterNew from "./counterNew";

class CountersNew extends Component {
  render() {
    return (
      <tbody>
        {this.props.counters.map((counter) => (
          <CounterNew
            key={counter.id}
            onDelete={this.props.onDelete}
            onIncrement={this.props.onIncrement}
            onDecrement={this.props.onDecrement}
            counter={counter}
          />
        ))}
      </tbody>
    );
  }
}

export default CountersNew;
