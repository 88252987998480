import React, { Component } from "react";
const ListGroupX = props => {
  const { items } = props;
  return (
    <ul className="list-group">
      {items.map(item => (
        <li key={item.roleId} className="list-group-item">
          {item.roleName}
        </li>
      ))}
    </ul>
  );
};

export default ListGroupX;
