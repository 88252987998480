import React, { Component } from "react";
import axios from "axios";
import { getCatMain, getCatMainStr } from "./services/serviceCatMain";
class Welcome extends Component {
  state = {
    data: { saleDaily: "" },
    storeGroup: [],
    catMain: [],
    catMainStr: [],
    storeSaleDaily: [],
  };
  async componentDidMount() {
    const { data: catMain } = await getCatMain();
    this.setState({ catMain });
    const { data: catMainStr } = await axios.get(
      "https://api.hazaramart.com/api/categorymapstores"
    );
    this.setState({ catMainStr });
    if (this.props.user && this.props.user.Id) {
      const { data: storeGroup } = await axios.get(
        `https://api.hazaramart.com/api/storegroup/${this.props.user.Id}`
      );
      this.setState({ storeGroup });
    }
    if (this.props.user && this.props.user.IdStr) {
      const { data: storeSaleDaily } = await axios.get(
        `https://api.hazaramart.com/api/SaleDailys/${this.props.user.IdStr}`
      );
      this.setState({ storeSaleDaily });
    }
  }
  render() {
    const { user } = this.props;
    const { storeGroup, catMain, catMainStr, storeSaleDaily } = this.state;
    return (
      <React.Fragment>
        <section className="content-header" />
        <div className="content">
          <div className="card card-primary card-outline">
            <div className="card-body">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className="d-block w-100"
                      src={`../../store/main/slide1.gif`}
                      alt="First slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={`../../store/main/slide2.gif`}
                      alt="Second slide"
                    />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>nayaLink</h5>
                      <p>www.nayalink.com</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={`../../store/main/slide3.gif`}
                      alt="Third slide"
                    />
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            {catMain
              .filter((catM) => catM.pickUpMenu == true)
              //.sort((a, b) => a.showOrder - b.showOrder)
              .map((catM) => (
                <div class="col-12 col-sm-6 col-md-3">
                  <div class="info-box">
                    <span class="info-box-icon bg-info elevation-1">
                      <img src={`../../store/main/${catM.sCatId}.jpg`} alt="" />
                    </span>
                    <div class="info-box-content">
                      <span class="info-box-text">{catM.sCatName}</span>
                      <span class="users-list-date">
                        {catMain
                          .filter((sub) => sub.parentScatId == catM.sCatId)
                          .map((sub) => sub.sCatName + ", ")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span class="info-box-icon bg-danger elevation-1">
                  <i class="fas fa-thumbs-up"></i>
                </span>

                <div class="info-box-content">
                  <span class="info-box-text">Men's Fashion</span>
                  <span class="users-list-name">
                    Bread, Jam, Egg, Serials, Spread, Juice
                  </span>
                </div>
              </div>
            </div>
            <div class="clearfix hidden-md-up"></div>

            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span
                  type="button"
                  class="info-box-icon bg-success elevation-1"
                  data-toggle="dropdown"
                >
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                      Separated link
                    </a>
                  </div>
                </span>

                <div class="info-box-content">
                  <span class="info-box-text">Sales</span>
                  <span class="users-list-name">
                    <small class="badge badge-warning">
                      <i class="far fa-clock"></i> 1 day
                    </small>
                     <small class="badge badge-warning">Break</small> 
                    <a type="button" class="badge badge-warning">
                      Jam
                    </a>
                    <button
                      type="button"
                      class="btn btn-block btn-outline-primary btn-xs"
                    >
                      Primary
                    </button>
                    <button
                      type="button"
                      class="btn btn-block btn-outline-primary btn-xs"
                    >
                      Primary
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-3">
              <div class="info-box mb-3">
                <span class="info-box-icon bg-warning elevation-1">
                  <i class="fas fa-users"></i>
                </span>

                <div class="info-box-content">
                  <span class="info-box-text">New Members</span>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default">
                      Break
                    </button>
                    <button type="button" class="btn btn-default">
                      Jam
                    </button>

                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-default dropdown-toggle dropdown-icon"
                        data-toggle="dropdown"
                      ></button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Butter
                        </a>
                        <a class="dropdown-item" href="#">
                          Milk
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div class="row">
            {/* <div class="col-lg-3 col-6">
              <div class="small-box">
                <div class="inner">
                  <h3>Breakfast</h3>

                  <p>Bread, Jam, Egg, Serials, Spread, Juice </p>
                </div>
                <div class="icon">
                  <i type="button" class="fas fa-arrow-circle-right"></i>
                </div>
                <a
                  type="button"
                  class="small-box-footer"
                  data-toggle="dropdown"
                >
                  More info <i class="fas fa-arrow-circle-right"></i>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">
                      Separated link
                    </a>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <div class="small-box bg-light">
                <div class="inner">
                  <h3>
                    {catMain.length}
                    53<sup style={{ fontSize: "20px" }}>%</sup>
                  </h3>

                  <p>Month Recovery</p>
                </div>
                <div class="icon">
                  <i class="ion ion-stats-bars"></i>
                </div>
                <a href="#" class="small-box-footer">
                  Open Shops <i class="fas fa-arrow-circle-right"></i>
                </a>
                <a href="#" class="small-box-footer">
                  Open Shops <i class="fas fa-arrow-circle-right"></i>
                </a>
                <a href="#" class="small-box-footer">
                  Open Shops <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div> */}
            {catMain
              .filter((catM) => catM.parentScatId == null)
              .map((catM) => (
                <div class="col-lg-3 col-6">
                  <div class="bg-lightblue small-box">
                    <div class="inner">
                      <h2>{catM.sCatName}</h2>
                      <p>
                        {catMain
                          .filter((sub) => sub.parentScatId == catM.sCatId)
                          //.sort((a, b) => a.showOrder - b.showOrder)
                          .map((sub) => sub.sCatName + ", ")}
                      </p>
                    </div>
                    <div class="icon">
                      <i type="button" class="fas fa-arrow-circle-right"></i>
                    </div>
                    <a href="#" class="small-box-footer">
                      Open <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              ))}
            {/* <div class="col-lg-3 col-6">
              <div class="small-box bg-danger">
                <div class="inner">
                  <h3>65</h3>

                  <p>Receivable</p>
                </div>
                <div class="icon">
                  <i class="ion ion-pie-graph"></i>
                </div>
                <a href="#" class="small-box-footer">
                  More info <i class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div> */}
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-tools float-left">
                <ul class="pagination pagination-sm">
                  <li class="page-item">
                    <a href="#" class="page-link">
                      &laquo;
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">
                      Home &raquo;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            {catMain
              .filter((catM) => catM.parentScatId == null)
              .map((catM) => (
                <div class="col-md-4">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">{catM.sCatName}</h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="maximize"
                        >
                          <i class="fas fa-expand"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        {catMainStr.filter((u) => u.sCatId == catM.sCatId)
                          .length > 0 ? (
                          catMainStr
                            .filter(
                              (storeName) => catM.sCatId == storeName.sCatId
                            )
                            .map((storeName) => (
                              <li key={storeName.store.storeId}>
                                <a
                                  class="users-list-name"
                                  href={`/${storeName.store.storeLinkName}`}
                                >
                                  <img
                                    src={`store/${storeName.store.storeLinkName}/logo.png`}
                                    alt="User Image"
                                  />
                                  <br />
                                  {storeName.store.storeTitle}
                                </a>
                                <span class="users-list-date">
                                  {storeName.store.storeLoc}
                                </span>
                              </li>
                            ))
                        ) : (
                          <li>Coming Soon.</li>
                        )}
                      </ul>
                    </div>

                    <div class="card-footer text-center">
                      <a href="javascript::">View All Users</a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {user && user.Role !== "Buyer" && (
            <React.Fragment>
              <div class="card card-widget widget-user">
                <div class="widget-user-header bg-info">
                  <h3 class="widget-user-username">
                    Alexander Pierce-{storeGroup.length}
                  </h3>
                  <h5 class="widget-user-desc">Founder & CEO</h5>
                  <img
                    class="direct-chat-img float-right"
                    src="dist/img/user1-128x128.jpg"
                    alt="message user image"
                  />
                  <img
                    class="direct-chat-img float-right"
                    src="dist/img/user1-128x128.jpg"
                    alt="message user image"
                  />
                </div>
                <div class="widget-user-image">
                  <img
                    class="img-circle elevation-2"
                    src="../dist/img/avatar/abc.jpg"
                    alt="User Avatar"
                  />
                </div>
                <div className="row">
                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                      <span class="info-box-icon bg-success elevation-1">
                        <i class="fas fa-chart-pie mr-1"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">STORE SALES</span>
                        <span class="info-box-text">CASH/CREDIT</span>
                        <span class="info-box-text">
                          {storeSaleDaily.total}
                          {storeSaleDaily
                            .slice(0, 1)
                            .map((mapProduct) => mapProduct.total)}
                        </span>
                        <span class="info-box-number">
                          <small>TOTAL</small> 41,410
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card-body p-0">
                      <ul class="users-list clearfix">
                        {storeGroup.map((storeName) => (
                          <li key={storeName.storeId}>
                            <img
                              src={`dist/img/avatar/${storeName.storeLinkName}.jpg`}
                              alt="User Image"
                            />
                            <a class="users-list-name" href="#">
                              {storeName.storeLinkName}
                            </a>
                            <span class="users-list-date">
                              {storeName.storeLoc}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                      <span class="info-box-icon bg-danger elevation-1">
                        <i class="fas fa-chart-pie mr-1"></i>
                      </span>

                      <div class="info-box-content">
                        <span class="info-box-text">ONLINE SALES</span>
                        <span class="info-box-text">CASH/CREDIT</span>
                        <span class="info-box-text">41,410</span>
                        <span class="info-box-number">
                          <small>TOTAL</small> 41,410
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {user && user.Role === "Owner" && (
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{storeGroup.length}</h5>
                      <span class="description-text">Store/Shop</span>
                    </div>
                  </div>
                
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">13,000</h5>
                      <span class="description-text">Staff/User</span>
                    </div>
                  </div>
                
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">35</h5>
                      <span class="description-text">PRODUCTS</span>
                    </div>
                    
                  </div>
                  
                </div>
                
              </div>
              )}
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">3,200</h5>
                      <span class="description-text">VENDOR</span>
                    </div>
                
                  </div>
                
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">13,000</h5>
                      <span class="description-text">CUSTOMERS</span>
                    </div>
                
                  </div>
                
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">35</h5>
                      <span class="description-text">PRODUCTS</span>
                    </div>
                    
                  </div>
                  
                </div>
              </div> */}
              </div>
              <div className="container-fluid">
                <div class="row">
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-info">
                      <div class="inner">
                        <h3>150</h3>

                        <p>Month Sales</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-bag"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3>
                          53<sup style={{ fontSize: "20px" }}>%</sup>
                        </h3>

                        <p>Month Recovery</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>44</h3>

                        <p>Payable</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-person-add"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-danger">
                      <div class="inner">
                        <h3>65</h3>

                        <p>Receivable</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                      </div>
                      <a href="#" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card card-widget widget-user-2">
                  <div class="widget-user-header bg-success">
                    <h3 class="widget-user-username">Today Summary</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                      <span class="info-box-icon bg-success elevation-1">
                        <i class="fas fa-shopping-cart"></i>
                      </span>

                      <div class="info-box-content">
                        <span class="info-box-text">SALES</span>
                        <span class="info-box-number">
                          10
                          <small>%</small>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                      <span class="info-box-icon bg-danger elevation-1">
                        <i class="fas fa-thumbs-up"></i>
                      </span>

                      <div class="info-box-content">
                        <span class="info-box-text">Recovery</span>
                        <span class="info-box-number">41,410</span>
                      </div>
                    </div>
                  </div>

                  <div class="clearfix hidden-md-up"></div>

                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                      <span class="info-box-icon bg-info elevation-1">
                        <i class="fas fa-cog"></i>
                      </span>

                      <div class="info-box-content">
                        <span class="info-box-text">Payment</span>
                        <span class="info-box-number">760</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                      <span class="info-box-icon bg-warning elevation-1">
                        <i class="fas fa-users"></i>
                      </span>

                      <div class="info-box-content">
                        <span class="info-box-text">Credit</span>
                        <span class="info-box-number">2,000</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title">Monthly Recap Report</h5>
                    </div>

                    <div class="card-footer">
                      <div class="row">
                        <div class="col-sm-3 col-6">
                          <div class="description-block border-right">
                            <span class="description-percentage text-success">
                              <i class="fas fa-caret-up"></i> 17%
                            </span>
                            <h5 class="description-header">$35,210.43</h5>
                            <span class="description-text">TOTAL REVENUE</span>
                          </div>
                        </div>

                        <div class="col-sm-3 col-6">
                          <div class="description-block border-right">
                            <span class="description-percentage text-warning">
                              <i class="fas fa-caret-left"></i> 0%
                            </span>
                            <h5 class="description-header">$10,390.90</h5>
                            <span class="description-text">TOTAL COST</span>
                          </div>
                        </div>

                        <div class="col-sm-3 col-6">
                          <div class="description-block border-right">
                            <span class="description-percentage text-success">
                              <i class="fas fa-caret-up"></i> 20%
                            </span>
                            <h5 class="description-header">$24,813.53</h5>
                            <span class="description-text">TOTAL PROFIT</span>
                          </div>
                        </div>

                        <div class="col-sm-3 col-6">
                          <div class="description-block">
                            <span class="description-percentage text-danger">
                              <i class="fas fa-caret-down"></i> 18%
                            </span>
                            <h5 class="description-header">1200</h5>
                            <span class="description-text">
                              GOAL COMPLETIONS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="hold-transition login-page">
            <div className="hold-transition center">
              <div className="login-box">
                <div className="login-logo">
                  <a href="../../index2.html">
                    <b>AgilitySol</b>APP
                  </a>
                  <img
                    src="../../dist/img/Agility-Solutions.png"
                    alt="Agility-Solutions Logo"
                    className="brand-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Welcome;
