import React, { Component } from "react";
import axios from "axios";
//import { browserHistory, useRouterHistory } from "react-router";
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { getPayType } from "./components/services/serviceReceipt";
import logo from "./logo.svg";
//import "./App.css";
import Navbar from "./components/navbar";
import Counters from "./components/counters";
import CountersNew from "./components/countersNew";
import CountersKart from "./components/countersKart";
import MainSidebar from "./components/mainSidebar";
import ContentSpace from "./components/contentSpace";
import Footer from "./components/footer";
import ControlSidebar from "./components/controlSidebar";
import LayoutContent from "./components/layoutContent";
import Welcome from "./components/welcome";
import Content from "./components/content";
import Register from "./components/register";
import SignUp from "./components/registerx";
import Form from "./components/registerForm";
import LoginForm from "./components/loginForm";
import LoginX from "./components/loginx";
import LoginUsers from "./components/loginUsers";
import Logout from "./components/logout";
import ListUser from "./components/listUser";
import Users from "./components/users";
import Prdts from "./components/prdts";
import ProductDetails from "./components/productDetails";
import "react-toastify/dist/ReactToastify.css";
import ListStoreUser from "./components/listStoreUser";
import AddUser from "./components/addUser";
import Product from "./components/product";
import Products from "./components/products";
import AddItem from "./components/addItem";
import AddProduct from "./components/addProduct";
import AddTile from "./components/addTile";
import Shop from "./components/shop";
import Outlet from "./components/outlet";
import Store from "./components/store";
import CheckOut from "./components/checkOut";
import TestImgUpload from "./components/testImgUpload";
import FileUploadComponent from "./components/fileUploadComponent";
import Receipt from "./components/receipt";
import Order from "./components/order";

export default class App extends Component {
  state = {
    dataTest1: {
      receiptId: 1,
    },
    dataTest: [
      {
        detail: 1,
        qty: 11,
      },
      {
        detail: 2,
        qty: 12,
      },
      {
        detail: 3,
        qty: 13,
      },
      {
        detail: 4,
        qty: 14,
      },
      {
        userId: 1,
        productId: 1,
        qty: 4,
        typeName: "a",
        code: "code",
        price: 100,
        sizeColor: "x",
        description: "",
        unitPrice: "",
        strname: "",
      },
      {
        userId: 2,
        productId: 2,
        qty: 4,
        typeName: "b",
        code: "code",
        price: 0,
        sizeColor: "x",
        description: "",
        unitPrice: "",
        strname: "",
      },
      {
        userId: 3,
        productId: 3,
        qty: 0,
        typeName: "c",
        code: "code",
        price: 0,
        sizeColor: "x",
        description: "",
        unitPrice: "",
        strname: "",
      },
      {
        userId: 4,
        productId: 4,
        qty: 0,
        typeName: "d",
        code: "code",
        price: 0,
        sizeColor: "x",
        description: "",
        unitPrice: "",
        strname: "",
      },
    ],
    counters: [],
  };
  async componentDidMount() {
    try {
      const jwtt = localStorage.getItem("key");
      const user = jwtDecode(jwtt);
      this.setState({ user });
    } catch (ex) {}
    const { data: storePayType } = await getPayType();
    if (this.state.user && this.state.user.Id) {
      const { data } = axios
        .get(
          `https://api.hazaramart.com/api/AddReceiptKart/${this.state.user.Id}`
        )
        .then((response) => response.data)
        .then(
          (result) => {
            this.setState({
              counters: result,
            });
          },
          (error) => {
            this.setState({ error });
          }
        );
    }
  }
  getClasses() {
    let classes =
      "hold-transition sidebar-collapse layout-navbar-fixed layout-footer-fixed ";
    classes +=
      this.state.user && this.state.user.Role !== "Buyer"
        ? "sidebar-mini layout-fixed"
        : "";
    return classes;
  }
  handleIncrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] = { ...counter };
    counters[index].qty++;
    counters[index].price = counters[index].qty * counters[index].unitPrice;
    this.setState({ counters });
    axios
      .post(
        `https://api.hazaramart.com/api/AddKart/${counters[index].receiptKartId}`
      )
      .then((response) => {
        toast.success("Stock Added.");
      });
  };
  handleDecrement = (counter) => {
    const counters = [...this.state.counters];
    const index = counters.indexOf(counter);
    counters[index] = { ...counter };
    console.log(counters[index].qty);
    counters[index].qty--;
    console.log(counters[index].qty);
    counters[index].price = counters[index].qty * counters[index].unitPrice;
    this.setState({ counters });
    axios
      .post(
        `https://api.hazaramart.com/api/LessKart/${counters[index].receiptKartId}`
      )
      .then((response) => {
        toast.info("Stock Decresed.");
      });
  };
  handleDelete = (counterId) => {
    const counters = this.state.counters.filter(
      (c) => c.receiptKartId !== counterId
    );
    this.setState({ counters });
    axios
      .post(`https://api.hazaramart.com/api/AddReceiptKart/${counterId}`)
      .then((response) => {
        toast.error("Item Removed.");
      });
  };
  handleDel = (productId) => {
    const index = this.state.counters.findIndex(
      (u) => productId === u.productId
    );
    const kartId = this.state.counters[index].receiptKartId;
    const counters = this.state.counters.filter(
      (c) => c.productId !== productId
    );
    this.setState({ counters });
    axios
      .post(`https://api.hazaramart.com/api/AddReceiptKart/${kartId}`)
      .then((response) => {
        toast.success("Removed.");
      });
  };
  async handleReload() {
    //const counters = this.state.counters.filter((c) => c.receiptKartId === 0);
    if (this.state.user && this.state.user.Id) {
      const { data: counters } = await axios.get(
        `https://api.hazaramart.com/api/AddReceiptKart/${this.state.user.Id}`
      );
      this.setState({ counters });
    }
  }
  KartEmpty = () => {
    this.setState({ counters: [] });
  };
  handleAdd = (
    userId,
    productId,
    typeName,
    code,
    price,
    sizeColor,
    description,
    companyName,
    strname
  ) => {
    if (
      this.state.counters.filter((u) => u.productId === productId).length > 0
    ) {
      const index = this.state.counters.findIndex(
        (u) => productId === u.productId
      );
      const counters = [...this.state.counters];
      counters[index].qty++;
      counters[index].price = counters[index].qty * counters[index].unitPrice;
      this.setState({ counters });
      axios
        .post(
          `https://api.hazaramart.com/api/AddKart/${counters[index].receiptKartId}`
        )
        .then((response) => {
          toast.success("Stock Added.");
        });
    } else {
      this.setState({
        counters: this.state.counters.concat({
          userId: userId,
          productId: productId,
          qty: 1,
          typeName: typeName,
          code: code,
          price: price,
          sizeColor: sizeColor,
          description: description,
          strname: strname,
          unitPrice: price,
        }),
      });
      var dd = companyName || "";
      dd += " ";
      dd += typeName || "";
      dd += " ";
      dd += code || "";
      dd += " ";
      dd += description || "";
      const data = {
        userId: userId,
        productId: productId,
        detail: dd,
        qty: 1,
        unitPriceReceipt: price,
        total: (price * 1).toFixed(2),
      };
      axios
        .post(`https://api.hazaramart.com/api/AddReceiptKart`, data)
        .then((response) => {
          toast.info(`Item Added. ${response.data.receiptKartID}`);
          this.handleReload();
        });
    }
  };
  handleLess = (productId) => {
    const index = this.state.counters.findIndex(
      (u) => productId === u.productId
    );
    const counters = [...this.state.counters];
    if (counters[index].qty > 0) {
      counters[index].qty--;
      counters[index].price = counters[index].qty * counters[index].unitPrice;
      this.setState({ counters });
      axios
        .post(
          `https://api.hazaramart.com/api/LessKart/${counters[index].receiptKartId}`
        )
        .then((response) => {
          toast.success("Stock Decresed.");
        });
    }
  };
  async addTodo() {
    console.log(this.state.dataTest);
    await axios
      .post(
        `https://api.hazaramart.com/api/AddReceiptDetailOnline`,
        JSON.stringify(this.state.dataTest),
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((responseJson) => {
        toast.success(`${responseJson.data.length} records added. `);
      });
  }
  render() {
    const { user } = this.state;
    return (
      <BrowserRouter>
        <body className={this.getClasses()}>
          <div className="wrapper">
            <ToastContainer />
            <Navbar
              user={user}
              counter={
                <Counters
                  counters={this.state.counters}
                  onIncrement={this.handleIncrement}
                  onDecrement={this.handleDecrement}
                  onDelete={this.handleDelete}
                />
              }
              totalCounters={
                //this.state.counters.filter((c) => c.qty > 0).length
                this.state.counters.reduce(
                  (totalQty, meal) => totalQty + meal.qty,
                  0
                )
              }
            />
            {/* {user && user.Role === "Manager" && <MainSidebar user={user} />} */}
            <MainSidebar user={user} />
            <LayoutContent>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Welcome {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/login"
                  render={(props) => {
                    if (!user) return <LoginX />;
                    return <Welcome />;
                  }}
                />
                <Route
                  path="/checkout"
                  render={(props) => (
                    <CheckOut
                      {...props}
                      user={this.state.user}
                      onReload={this.KartEmpty}
                      counterCopy={
                        <CountersNew
                          counters={this.state.counters}
                          onIncrement={this.handleIncrement}
                          onDecrement={this.handleDecrement}
                          onDelete={this.handleDelete}
                        />
                      }
                      counterCopyKart={
                        <CountersKart counters={this.state.counters} />
                      }
                      totalCounters={
                        //this.state.counters.filter((c) => c.qty > 0).length
                        this.state.counters.reduce(
                          (totalQty, meal) => totalQty + meal.qty,
                          0
                        )
                      }
                      totalPrice={this.state.counters.reduce(
                        (totalPrice, meal) => totalPrice + meal.price,
                        0
                      )}
                    />
                  )}
                />
                <Route path="/logout" component={Logout} />
                <Route path="/register" component={Register} />
                <Route
                  path="/signup"
                  render={(props) => {
                    if (!user) return <SignUp />;
                    return <Welcome />;
                  }}
                />
                <Route path="/loginMe" component={LoginUsers} />
                <Route
                  path="/fileUploadComponent"
                  component={FileUploadComponent}
                />
                <Route exact path="/listUser" component={ListUser} />
                <Route
                  path="/StoreUser"
                  render={(props) => (
                    <ListStoreUser {...props} user={this.state.user} />
                  )}
                />
                <Route path="/ProductDetails/:id?" component={ProductDetails} />
                <Route path="/Prdts" component={Prdts} />
                <Route path="/Store" component={Store} />
                <Route
                  path="/shop/:strname?"
                  render={(props) => (
                    <Shop
                      {...props}
                      user={this.state.user}
                      onAdd={this.handleAdd}
                    />
                  )}
                />
                <Route
                  path="/additem"
                  render={(props) => (
                    <AddItem {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/receipt"
                  render={(props) => (
                    <Receipt {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/AddProduct"
                  render={(props) => (
                    <AddProduct {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/AddTile"
                  render={(props) => (
                    <AddTile {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/Product"
                  render={(props) => (
                    <Product {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/Products"
                  render={(props) => (
                    <Products {...props} user={this.state.user} />
                  )}
                />
                <Route path="/users" component={Users} />
                <Route
                  path="/adduser/:id?"
                  render={(props) => (
                    <AddUser {...props} user={this.state.user} />
                  )}
                />
                <Route path="/form" component={Form} />
                <Route path="/log" component={LoginForm} />
                <Route
                  path="/order"
                  render={(props) => (
                    <Order {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/:strname?/:catname?"
                  render={(props) => (
                    <Outlet
                      {...props}
                      user={this.state.user}
                      onAdd={this.handleAdd}
                      onLess={this.handleLess}
                      onDel={this.handleDel}
                      counter={this.state.counters}
                    />
                  )}
                />
                <Redirect from="/*" exact to="/" />
              </Switch>
              {/*<Route exact path="/" component={Welcome} />
             <Redirect from="/" exact to="/" /> */}
            </LayoutContent>
            <aside className="control-sidebar control-sidebar-light">
              <div className="p-1">
                <h5>
                  <button type="button" className="btn btn-block btn-warning">
                    CHECKOUT{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-right"
                    data-dismiss="modal"
                    onClick={() => this.addTodo()}
                  >
                    Add to Receipt
                  </button>
                </h5>
                <ul className="nav nav-pills flex-column">
                  <li className="nav-item">
                    <div href="#" className="nav-link">
                      <span className="float-right text-success">
                        {"Subtotal ("}
                        {this.state.counters.reduce(
                          (totalQty, meal) => totalQty + meal.qty,
                          0
                        )}
                        {" item): Rs "}
                        {this.state.counters.reduce(
                          (totalPrice, meal) => totalPrice + meal.price,
                          0
                        )}
                      </span>
                    </div>
                  </li>
                </ul>

                <p>
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">List</h3>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <Counters
                        counters={this.state.counters}
                        onReset={this.handleReset}
                        onIncrement={this.handleIncrement}
                        onDecrement={this.handleDecrement}
                        onDelete={this.handleDelete}
                      />
                    </div>
                    <div className="card-footer text-center">
                      <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                          <div href="#" className="nav-link">
                            <span className="float-right text-success">
                              {"Subtotal ("}
                              {this.state.counters.reduce(
                                (totalQty, meal) => totalQty + meal.qty,
                                0
                              )}
                              {" item): Rs "}
                              {this.state.counters.reduce(
                                (totalPrice, meal) => totalPrice + meal.price,
                                0
                              )}
                            </span>
                          </div>
                        </li>
                      </ul>
                      <a href="javascript:void(0)" className="uppercase">
                        <button
                          type="button"
                          className="btn btn-block btn-warning"
                        >
                          CHECKOUT{" "}
                        </button>
                      </a>
                    </div>
                  </div>
                </p>
              </div>
            </aside>
            <Footer
              totalCounters={
                //this.state.counters.filter((c) => c.qty > 0).length
                this.state.counters.reduce(
                  (totalQty, meal) => totalQty + meal.qty,
                  0
                )
              }
              totalPrice={this.state.counters.reduce(
                (totalPrice, meal) => totalPrice + meal.price,
                0
              )}
            />
          </div>
        </body>
      </BrowserRouter>
      /* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */
    );
  }
}
