import React from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import { toast } from "react-toastify";

class AddUser extends Form {
  state = {
    data: {
      storeUserName: "",
      storeId: "",
      designationId: "",
      storeUserPassword: "",
      storeUserStatus: "",
    },
    designations: [],
    storeDetail: [],
    errors: {},
  };
  schema = {
    storeUserId: Joi.string(),
    storeUserName: Joi.string().required().label("StoreUserName"),
    storeId: Joi.required().label("StoreID"),
    designationId: Joi.required().label("DesignationId"),
  };
  async componentDidMount() {
    const urlId = this.props.match.params.id;
    const { data: designations } = await axios.get(
      `https://api.hazaramart.com/api/storedesignations`
    );
    const { data: storeDetail } = await axios.get(
      `https://api.hazaramart.com/api/storeusers/${this.props.user.IdU}`
    );
    this.setState({ storeDetail, designations });
    console.log(storeDetail);
    if (urlId) {
      const { data: storeUser } = await axios.get(
        `https://api.hazaramart.com/api/storeusers/${urlId}`
      );
      if (storeUser && storeUser.storeId === parseInt(this.props.user.IdStr))
        this.setState({ data: this.mapToViewModel(storeUser) });
    }
  }
  mapToViewModel(storeUser) {
    return {
      storeUserId: storeUser.storeUserId,
      storeUserName: storeUser.storeUserName,
      storeId: storeUser.storeId,
      designationId: storeUser.designationId,
      storeUserPassword: storeUser.storeUserPassword,
      storeUserStatus: storeUser.storeUserStatus,
    };
  }

  async editTodo(id) {
    if (id) {
      const itemToEdit = { ...this.state.data };

      await axios
        .put(`https://api.hazaramart.com/api/storeusers/${id}`, itemToEdit)
        .then((response) => {
          toast.success("Successfully Updated.");
        });

      this.setState({ storeUser: itemToEdit });
    } else {
      const itemToAdd = { ...this.state.data };
      await axios
        .post(`https://api.hazaramart.com/api/storeusers`, itemToAdd)
        .then((response) => {
          toast.success("Successfully Added.");
        });
      this.state.data = "";
      this.props.history.push("/ShopUser");
    }
  }
  render() {
    const { storeDetail } = this.state;
    let pageTitle;
    let actionStatus;
    if (this.state.data.storeUserId) {
      pageTitle = <h2>Edit User</h2>;
      actionStatus = <b>Update</b>;
    } else {
      pageTitle = <h2>Add User</h2>;
      actionStatus = <b>Save</b>;
    }
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add / Edit Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink tag={Link} to="/StoreUser">
                      List
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">
                    <NavLink tag={Link} to="/adduser">
                      Add
                    </NavLink>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      <img
                        className="profile-user-img img-fluid img-circle"
                        src="../../dist/img/user4-128x128.jpg"
                        alt="User profile picture"
                      />
                    </div>

                    <h3 className="profile-username text-center">
                      {storeDetail.storeUserId && storeDetail.store.storeTitle}
                    </h3>

                    <p className="text-muted text-center">
                      {storeDetail.storeUserId &&
                        storeDetail.store.storeAddress}
                    </p>

                    <ul className="list-group list-group-unbordered mb-3">
                      <li className="list-group-item">
                        <b>
                          <i className="fas fa-map-marker-alt mr-1"></i>
                          Location
                        </b>
                        <a className="float-right">
                          {storeDetail.storeUserId &&
                            storeDetail.store.storeLoc}
                        </a>
                      </li>
                      <li className="list-group-item">
                        <b>Mobile</b>
                        <a className="float-right">
                          {storeDetail.storeUserId &&
                            storeDetail.store.storeMobile}
                        </a>
                      </li>
                      <li className="list-group-item">
                        <b>Phone</b>
                        <a className="float-right">
                          {storeDetail.storeUserId &&
                            storeDetail.store.storePhone}
                        </a>
                      </li>
                    </ul>

                    <a href="#" className="btn btn-primary btn-block">
                      <b>Follow</b>
                    </a>
                  </div>
                </div>
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Total User(s) = </h3>
                  </div>

                  <div className="card-body">
                    <strong>
                      <i className="fas fa-book mr-1"></i> Education
                    </strong>

                    <p className="text-muted">
                      B.S. in Computer Science from the University of Tennessee
                      at Knoxville
                    </p>

                    <hr />

                    <strong>
                      <i className="fas fa-map-marker-alt mr-1"></i> Location
                    </strong>

                    <p className="text-muted">Malibu, California</p>

                    <hr />

                    <strong>
                      <i className="fas fa-pencil-alt mr-1"></i> Skills
                    </strong>

                    <p className="text-muted">
                      <span className="tag tag-danger">UI Design</span>
                      <span className="tag tag-success">Coding</span>
                      <span className="tag tag-info">Javascript</span>
                      <span className="tag tag-warning">PHP</span>
                      <span className="tag tag-primary">Node.js</span>
                    </p>

                    <hr />

                    <strong>
                      <i className="far fa-file-alt mr-1"></i> Notes
                    </strong>

                    <p className="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam fermentum enim neque.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          Showing {this.state.data.storeUserName} users in the
                          database.
                        </h3>
                      </div>

                      <div className="card-body">
                        {pageTitle}
                        <form onSubmit={this.handleSubmit}>
                          {/* <input
                            name="storeUserName"
                            id="storeUserName"
                            value={this.state.data.storeUserName}
                            className="form-control"
                          /> */}
                          {this.renderInput("storeUserName", "User Name")}
                          {this.renderInput("storeId", "StoreID")}
                          {this.renderSelect(
                            "designationId",
                            "Designation",
                            this.state.designations
                          )}
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              this.editTodo(this.state.data.storeUserId)
                            }
                          >
                            {actionStatus}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AddUser;
