import React, { Component } from "react";
import axios from "axios";
import Joi from "joi-browser";
import FormReceipt from "./common/formReceipt";
import { getPayType } from "./services/serviceReceipt";
import SearchBox from "./searchBox";
import { toast } from "react-toastify";
import dateFormat from 'dateformat';

class Receipt extends FormReceipt {
  state = {
    dataNew: {
      storeId: "",
      statusReceipt: "Pending",
      createdDate: "",
      userby: "",
    },
    data1: {
      storeId: "",
      receiptId: "",
      customerId: "",
      paymentId: "",
      recveAmount: "",
      total: "",
      userby: "",
    },
    data: {
      storeId: "",
      receiptId: "",
      customerId: "",
      productID: "",
      detail: "",
      unitPrice: "",
      discount: "",
      qty: "",
      unitPriceReceipt: "",
      calcPercent: "",
      calcDiscount: "",
      calcFromPercent: "",
      userby: "",
    },
    receiptId: "",
    statusReceipt: "",
    userby: "",
    returnBill: "",
    customerId: "",
    custName: "",
    custMobile: "",
    custBalance: "",
    stockId: "",
    productID: "",
    detail: "",

    inStock: "",
    price: "",

    paymentId: "",
    recveAmount: "",

    storePayType: [],
    storeCustomer: [],
    storeRcptPng: [],
    storeRcptToday: [],
    categoriesGroup: [],
    storeCompanies: [],
    productTypeGroup: [],
    productLocalGroup: [],
    storeRcptDetail: [],
    errors: {},
  };
  schema = {
    qty: Joi.label("qty"),
    customerId: Joi.label("customerId"),
    paymentId: Joi.label("paymentId"),
    discount: Joi.label("discount"),
    unitPriceReceipt: Joi.label("unitPriceReceipt"),
    recveAmount: Joi.label("recveAmount"),
  };
  async componentDidMount() {
    const { data: storePayType } = await getPayType();
    const { data: storeCustomer } = await axios.get(
      `https://api.hazaramart.com/api/CustomerGroup/${this.props.user.IdStr}`
    );
    const { data: storeRcptPng } = await axios.get(
      `https://api.hazaramart.com/api/ReceiptPending/${this.props.user.IdStr}`
    );
    const { data: storeRcptToday } = await axios.get(
      `https://api.hazaramart.com/api/ReceiptToday/${this.props.user.IdStr}`
    );
    this.setState({
      storePayType,
      storeCustomer,
      storeRcptPng,
      storeRcptToday,
    });
    const { data: categoriesGroup } = await axios.get(
      `https://api.hazaramart.com/api/CategoriesGroup/${this.props.user.IdMaster}`
    );
    const { data: storeCompanies } = await axios.get(
      `https://api.hazaramart.com/api/CompaniesGroup/${this.props.user.IdMaster}`
    );
    const { data: productTypeGroup } = await axios.get(
      `https://api.hazaramart.com/api/ProductTypeGroup/${this.props.user.IdMaster}`
    );
    const { data: productLocalGroup } = await axios.get(
      `https://api.hazaramart.com/api/StoreStock/${this.props.user.IdStr}`
    );
    this.setState({
      storeCompanies,
      categoriesGroup,
      productTypeGroup,
      productLocalGroup,
    });
  }
  handleSearchCust = (queryy) => {
    this.setState({ searchCustomer: queryy });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };
  async handleCustReceipt(id, cnam, mob, preBal) {
    this.setState({
      customerId: id,
      custName: cnam,
      custMobile: mob,
      custBalance: preBal,
    });
    //this.state.data.calcPercent = "";
    this.state.data.customerId = id;
    this.state.data.receiptId = this.state.receiptId;
    this.state.data.storeId = this.props.user.IdStr;
    const itemToEdit = { ...this.state.data };
    await axios
      .put(
        `https://api.hazaramart.com/api/Receipts/${this.state.receiptId}`,
        itemToEdit
      )
      .then((response) => {
        toast.success("Customer Added In Receipt.");
      });
    const { data: storeRcptPng } = await axios.get(
      `https://api.hazaramart.com/api/ReceiptPending/${this.props.user.IdStr}`
    );
    this.setState({ storeRcptPng });
  }
  async handleLoadPending(id, srcpt, custid, userSale, rBill) {
    this.setState({
      receiptId: id,
      statusReceipt: srcpt,
      userby: userSale,
      returnBill: rBill,

      customerId: "",
      custName: "",
      custMobile: "",
      custBalance: "",
    });
    if (custid) {
      const { data: rcptCust } = await axios.get(
        `https://api.hazaramart.com/api/Customers/${custid}`
      );
      this.setState({ rcptCust });
      this.setState({
        customerId: rcptCust.customerId,
        custName: rcptCust.custName,
        custMobile: rcptCust.custMobile,
        custBalance: rcptCust.custBalance,
      });
    }
    const { data: storeRcptDetail } = await axios.get(
      `https://api.hazaramart.com/api/ReceiptDetailGroup/${id}`
    );
    this.setState({ storeRcptDetail });
  }
  handleDiscount = () => {
    this.state.data.calcPercent = (
      100 -
      (this.state.data.unitPriceReceipt * 100) / this.state.price
    ).toFixed(2);
    this.state.data.calcDiscount =
      (this.state.price - this.state.data.unitPriceReceipt) *
      this.state.data.qty;
  };
  handlePercentage = () => {
    this.state.data.calcFromPercent =
      this.state.price - (this.state.data.discount * this.state.price) / 100;
  };
  handleRecptItem = (
    stId,
    id,
    companyName,
    typeName,
    code,
    description,
    inStock,
    price
  ) => {
    var dd = companyName || "";
    dd += " ";
    dd += typeName || "";
    dd += " ";
    dd += code || "";
    dd += " ";
    dd += description || "";
    this.setState({
      stockId: stId,
      productID: id,
      detail: dd,
      inStock: inStock,
      price: price,
    });
    this.state.data.unitPriceReceipt = price;
    this.state.data.discount = price - this.state.data.unitPriceReceipt;
  };
  async handleAddNewRecpt() {
    this.state.dataNew.storeId = this.props.user.IdStr;
    this.state.dataNew.userby = this.props.user.Name;
    this.state.dataNew.createdDate = dateFormat(Date(), "dd mmmm yy h:MM TT");
    const itemToAdd = { ...this.state.dataNew };
    console.log(itemToAdd)
    await axios
      .post(`https://api.hazaramart.com/api/Receipts`, itemToAdd)
      .then((response) => {
        console.log(response);
        toast.success(`Successfully Added. ${response.data.receiptId}`);
        this.setState({
          receiptId: response.data.receiptId,
          statusReceipt: response.data.statusReceipt,
        });
      });
  }
  async addTodo() {
    /* this.state.data.receiptId = this.state.receiptId;
    this.state.data.productID = this.state.productID;
    this.state.data.detail = this.state.detail;
    this.state.data.unitPrice = this.state.price;
    const itemToAdd = { ...this.state.data }; */
    const data = {
      receiptId: this.state.receiptId,
      productID: this.state.productID,
      detail: this.state.detail,
      qty: this.state.data.qty,
      unitPriceReceipt: this.state.data.unitPriceReceipt,
      discount: this.state.data.calcDiscount,
      total: (this.state.data.unitPriceReceipt * this.state.data.qty).toFixed(
        2
      ),
      receiptDetailID: this.state.stockId,
    };
    await axios
      .post(`https://api.hazaramart.com/api/AddReceiptDetail`, data)
      .then((response) => {
        toast.success(`Item Added. ${response.data.receiptDetailID}`);

        this.setState({
          storeRcptDetail: [
            {
              receiptDetailID: response.data.receiptDetailID,
              productID: this.state.productID,
              detail: this.state.detail,
              qty: this.state.data.qty,
              unitPriceReceipt: this.state.data.unitPriceReceipt,
              total: (
                this.state.data.unitPriceReceipt * this.state.data.qty
              ).toFixed(2),
            },
            ...this.state.storeRcptDetail,
          ],
        });
      });
  }
  async addRcptClose() {
    const data1 = {
      receiptId: this.state.receiptId,
      recveAmount: this.state.data.recveAmount,
      total: this.state.storeRcptDetail.reduce(
        (totalRcpt, meal) =>
          totalRcpt + parseInt(meal.total, 10),
        0
      ),
      paymentId: this.state.data.paymentId,
      customerId: this.state.customerId,
      userby: this.props.user.Name,
      storeId: this.props.user.IdStr,
    };
    await axios
      .post(`https://api.hazaramart.com/api/AddReceiptClose`, data1)
      .then((response) => {
        toast.success(`Receipt ${response.data.receiptId} saved. `);
        this.setState({
          statusReceipt: 'Complete',
        });
      });
      const { data: storeRcptPng } = await axios.get(
        `https://api.hazaramart.com/api/ReceiptPending/${this.props.user.IdStr}`
      );
      const { data: storeRcptToday } = await axios.get(
        `https://api.hazaramart.com/api/ReceiptToday/${this.props.user.IdStr}`
      );
      this.setState({ storeRcptPng, storeRcptToday });
  }
  render() {
    const {
      storeCustomer: AllCustomer,
      searchCustomer,
      storeRcptPng,
      storeRcptToday,
      productLocalGroup: AllStock,
      categoriesGroup,
      storeCompanies,
      productTypeGroup,
      searchQuery,
      storeRcptDetail,
    } = this.state;
    //this.state.data.discount =
    // (this.state.price - this.state.data.unitPriceReceipt) *
    //this.state.data.qty;
    let filteredCust = AllCustomer;
    let filtered = AllStock;
    if (searchCustomer)
      filteredCust = AllCustomer.filter(
        (u) =>
          //(u.sizeColor &&
          //  u.sizeColor.toLowerCase().startsWith(searchCustomer.toLowerCase())) ||
          (u.custMobile &&
            u.custMobile
              .toLowerCase()
              .startsWith(searchCustomer.toLowerCase())) ||
          (u.custName &&
            u.custName.toLowerCase().startsWith(searchCustomer.toLowerCase()))
      );
    if (searchQuery)
      filtered = AllStock.filter(
        (u) =>
          (u.product.company &&
            u.product.company.companyName
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())) ||
          (u.product.type &&
            u.product.type.typeName
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())) ||
          (u.product.sizeColor &&
            u.product.sizeColor
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())) ||
          (u.product.code &&
            u.product.code
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())) ||
          (u.product.description &&
            u.product.description
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase()))
      );
    return (
      <section className="content">
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {this.state.detail}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Price: {this.state.price} Qty: {this.state.data.qty} Discount:{" "}
                {this.state.data.discount} Rs.{" "}
                {(
                  this.state.data.calcFromPercent * this.state.data.qty
                ).toFixed(2)}
                <div className="card card-primary card-tabs">
                  <div className="card-header p-0 pt-1">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-one-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-home"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >
                          Unit Price
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-one-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-one-profile"
                          role="tab"
                          aria-controls="custom-tabs-one-profile"
                          aria-selected="false"
                        >
                          % Discount
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-one-tabContent"
                    >
                      <div>{this.renderInput("qty", "Quantity")}</div>
                      <div
                        className="tab-pane fade show active"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <div className="row" onChange={this.handleDiscount()}>
                          {this.renderInput(
                            "unitPriceReceipt",
                            "Price Receipt"
                          )}
                          <div className="col-8">
                            <b>Discount</b>
                            <div className="m-3">
                              {this.state.data.calcPercent}% Rs.{" "}
                              {this.state.data.calcDiscount}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="btn btn-default">
                            Total:{" "}
                            {(
                              this.state.data.unitPriceReceipt *
                              this.state.data.qty
                            ).toFixed(2)}
                          </div>

                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            data-dismiss="modal"
                            onClick={() => this.addTodo()}
                          >
                            Add to Receipt
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary float-right"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="custom-tabs-one-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-profile-tab"
                      >
                        <div className="row" onChange={this.handlePercentage()}>
                          <div className="col-4">
                            <b>Price Receipt</b>
                            <br />
                            <input
                              type="text"
                              className="form-control m-2"
                              placeholder={this.state.data.calcFromPercent}
                              disabled
                            ></input>
                          </div>
                          {this.renderInput("discount", "Discount")}
                        </div>
                        <div className="col-12">
                          <div className="btn btn-default">
                            Total:{" "}
                            {(
                              this.state.data.calcFromPercent *
                              this.state.data.qty
                            ).toFixed(2)}
                          </div>

                          <button
                            type="button"
                            className="btn btn-primary float-right"
                          >
                            Save changes
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary float-right"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                In Stock: {this.state.inStock}
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal-success">
        <div className="modal-dialog modal-lg">
          <div className="modal-content bg-success">
            <div className="modal-header">
              <h4 className="modal-title">Today Receipt / Bill</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
            <div className="table-responsive">
                  <table className="table m-0">
                    <thead>
                    <tr>
                      <th>Bill No</th>
                      <th>Customer Name</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                          {storeRcptToday.map((rcptToday) => (
                            <tr key={rcptToday.receiptId}>
                              <td>{rcptToday.receiptId}</td>
                              <td>{rcptToday.custName}</td>
                              <td>{rcptToday.total}</td>
                              <td><span className="badge badge-warning">{rcptToday.statusReceipt}</span></td>
                              <td>
                                <button
                                  className="btn btn-warning btn-sm"
                                  data-dismiss="modal"
                                  onClick={() =>
                                    this.handleLoadPending(
                                      rcptToday.receiptId,
                                      rcptToday.statusReceipt,
                                      rcptToday.customerId,
                                      rcptToday.userby,
                                      rcptToday.returnBill
                                    )
                                  }
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-outline-light" data-dismiss="modal">Close</button>
            </div>
          </div>
        
        </div>
        
      </div>
      <div className="modal fade" id="modal-secondary">
        <div className="modal-dialog modal-lg">
          <div className="modal-content bg-secondary">
            <div className="modal-header">
              <h4 className="modal-title">Secondary Modal</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
            <div className="table-responsive">
                  <table className="table m-0">
                    <thead>
                    <tr>
                      <th>Bill No</th>
                      <th>Customer Name</th>
                      <th>Open Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                          {storeRcptPng.map((rcptPng) => (
                            <tr key={rcptPng.receiptId}>
                              <td>{rcptPng.receiptId}</td>
                              <td>{rcptPng.customerId &&
                                    AllCustomer.find(
                                      (p) => p.customerId === rcptPng.customerId
                                    ).custName}</td>
                              <td>{dateFormat(rcptPng.createdDate, "dd mmmm yy h:MM TT")}</td>
                              <td>{rcptPng.returnBill && "Return"}</td>
                              <td><span className="badge badge-warning">{rcptPng.statusReceipt}</span></td>
                              <td>
                                <button
                                  className="btn btn-warning btn-sm"
                                  data-dismiss="modal"
                                  onClick={() =>
                                    this.handleLoadPending(
                                      rcptPng.receiptId,
                                      rcptPng.statusReceipt,
                                      rcptPng.customerId,
                                      rcptPng.userby,
                                      rcptPng.returnBill
                                    )
                                  }
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-outline-light" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-header">
                {!this.state.receiptId && (
                  <React.Fragment>
                  <button
                    type="button"
                    onClick={() => this.handleAddNewRecpt()}
                    className="btn btn-sm btn-info float-left"
                  >
                    New Receipt
                  </button>
                  <button type="button" className="btn btn-sm btn-success  float-right" data-toggle="modal" data-target="#modal-success">
                  Receipt Today
                  </button>
                  <button type="button" className="btn btn-sm btn-secondary float-right" data-toggle="modal" data-target="#modal-secondary">
                Pending Receipt
                </button>
                  </React.Fragment>
                )}
                {this.state.receiptId && (
                  <React.Fragment>
                  <button
                  type="button"
                  data-toggle="modal" data-target="#modal-success"
                  className="btn btn-sm btn-success float-right"
                >
                  Receipt Today
                </button>
                <button
                  type="button"
                  data-toggle="modal" data-target="#modal-secondary"
                  className="btn btn-sm btn-secondary float-right"
                >
                  Pending Receipt
                </button>
                <button
                    type="button"
                    onClick={() => this.handleAddNewRecpt()}
                    className="btn btn-sm btn-warning float-right"
                  >
                    Open New Receipt
                  </button>
                </React.Fragment>
                )}
                {storeRcptPng.map((rcptPng) => (
                  <button
                    key={rcptPng.receiptId}
                    type="button"
                    onClick={() =>
                      this.handleLoadPending(
                        rcptPng.receiptId,
                        rcptPng.statusReceipt,
                        rcptPng.customerId,
                        rcptPng.userby,
                        rcptPng.returnBill
                      )
                    }
                    className="btn btn-default btn-sm"
                  >
                    Pending : {rcptPng.receiptId}{" "}
                  </button>
                ))}
              </div>
            </div>
            {this.state.receiptId && this.state.statusReceipt === "Pending" && (
              <React.Fragment>
                <div className="col-md-3">
                  <div className="card">
                    {!this.state.customerId && (
                      <React.Fragment>
                        <div className="navbar navbar-warning">
                          Total ({filteredCust.length})
                          <SearchBox
                            className="float-right"
                            value={searchCustomer}
                            onChange={this.handleSearchCust}
                          />
                        </div>
                        <div
                          className="table-responsive p-0"
                          style={{ height: "160px" }}
                        >
                          {filteredCust.map((cust) => (
                            <React.Fragment>
                              <div
                                key={cust.customerId}
                                className="dropdown-item text-muted text-sm"
                              >
                                <button
                                  className="btn btn-warning btn-sm"
                                  onClick={() =>
                                    this.handleCustReceipt(
                                      cust.customerId,
                                      cust.custName,
                                      cust.custMobile,
                                      cust.custBalance
                                    )
                                  }
                                >
                                  <i className="fas fa-user"></i>
                                </button>{" "}
                                 {cust.custName}
                                <span className="badge float-right">
                                  Balance : {cust.custBalance}
                                  <br />
                                  <span className="product-description">
                                    {cust.custMobile}
                                  </span>
                                </span>
                              </div>
                              <div className="dropdown-divider"></div>
                            </React.Fragment>
                          ))}
                        </div>
                      </React.Fragment>
                    )}
                    {this.state.customerId && (
                      <div className="card-footer text-center">
                        {/* <a href="#" className="uppercase">
                          <button
                            type="button"
                            className="btn btn-block btn-warning"
                          >
                            CHANGE {this.state.custName}
                          </button>
                        </a> */}
                        <div
                          className="card-body table-responsive p-0"
                          style={{ height: "180px" }} //145
                        >
                          <table className="table table-head-fixed">
                            <thead>
                              <tr>
                                <th>Receipt No: {this.state.receiptId}</th>
                              </tr>
                              <tr>
                                <th>Status: {this.state.statusReceipt}</th>
                              </tr>
                              <tr>
                                <td>
                                  <div className="row no-print">
                                    <a
                                      href="invoice-print.html"
                                      target="_blank"
                                      className="btn btn-default"
                                    >
                                      <i className="fas fa-edit"></i>
                                      {this.state.custName} :{" "}
                                      {this.state.custMobile}
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </thead>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card">
                    <div className="navbar navbar-warning navbar-light">
                      Showing {filtered.length} product(s).
                      <SearchBox
                        value={searchQuery}
                        onChange={this.handleSearch}
                      />
                    </div>

                    <div
                      className="card-body table-responsive p-0"
                      style={{ height: "160px" }}
                    >
                      <table className="table table-head-fixed text-nowrap table-hover text-sm">
                        <thead>
                          <tr>
                            <th></th>
                            {/* <th>Category</th> */}
                            <th>Company</th>
                            <th>Type</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Size/Color</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtered.map((storeproduct) => (
                            <tr key={storeproduct.productId}>
                              <td>
                                <button
                                  className="btn btn-info btn-sm"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={() =>
                                    this.handleRecptItem(
                                      storeproduct.stockId,
                                      storeproduct.productId,
                                      storeproduct.product.company &&
                                        storeproduct.product.company
                                          .companyName,
                                      storeproduct.product.type &&
                                        storeproduct.product.type.typeName,
                                      storeproduct.product.code,
                                      storeproduct.product.description,
                                      storeproduct.qty,
                                      storeproduct.product.price
                                    )
                                  }
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              </td>
                              {/* <td>
                              {storeproduct.catId &&
                                categoriesGroup.find(
                                  (p) => p.catId === storeproduct.catId
                                ).catName}
                            </td> */}
                              <td>
                                {storeproduct.product.companyId &&
                                  storeCompanies.find(
                                    (p) =>
                                      p.companyId ===
                                      storeproduct.product.companyId
                                  ).companyName}
                              </td>
                              <td>
                                {storeproduct.product.typeId &&
                                  productTypeGroup.find(
                                    (p) =>
                                      p.typeId === storeproduct.product.typeId
                                  ).typeName}
                              </td>
                              <td>{storeproduct.product.code}</td>
                              <td>
                                {storeproduct.product.description || ""}{" "}
                                {storeproduct.product.priceUnit}
                              </td>
                              <td>
                                {storeproduct.product.sizeColor}{" "}
                                {storeproduct.product.packing}
                              </td>
                              <td>{storeproduct.product.price}</td>
                              {/* <td>{storeproduct.pricePurchase}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="invoice p-3 mb-3">
                <div className="row">
                  <div className="col-12">
                    <h4>
                      <i className="fas fa-globe"></i> Al Mansoor Trading
                      Company
                      <small className="float-right">{dateFormat(Date(), "fullDate")}</small>
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 border-right">
                    <div className="description-block">
                      <h5 className="description-header">Receipt No.</h5>
                      <span className="description-text">
                        {this.state.receiptId}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-2 border-right">
                    <div className="description-block">
                      <h5 className="description-header">Name</h5>
                      <span className="description-text">
                        {this.state.custName}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-2 border-right">
                    <div className="description-block">
                      <h5 className="description-header">Contact</h5>
                      <span className="description-text">
                        {this.state.custMobile}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-2 border-right">
                    <div className="description-block">
                    {this.state.returnBill==true && (
                      <span className="description-text">
                      <div className="bg-gray py-2 px-3">
                      Return Bill
                      </div>  
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-sm-2 border-right">
                    <div className="description-block">
                      <h5 className="description-header">Saleman</h5>
                      <span className="description-text">
                        {this.state.userby}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="description-block">
                      <h5 className="description-header">Previous Balance/Total</h5>
                      <span className="description-text">
                        {this.state.custBalance}/
                        {storeRcptDetail.reduce(
                          (totalRcpt, meal) =>
                            totalRcpt + parseInt(meal.total, 10),
                          0
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Description</th>
                          <th>Qty</th>
                          <th>Unit Price</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {storeRcptDetail.map((rcptdetal) => (
                          <tr key={rcptdetal.receiptDetailId}>
                            <td>{rcptdetal.receiptDetailId}</td>
                            <td>{rcptdetal.detail}</td>
                            <td>{rcptdetal.qty}</td>
                            <td>{rcptdetal.unitPriceReceipt}</td>
                            <td>{rcptdetal.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.state.statusReceipt === "Pending" && (
                  <React.Fragment>
                <div className="row">
                  <div className="col-6">
                    <p className="lead">Payment Methods:</p>
                    <img src="../../dist/img/credit/visa.png" alt="Visa" />
                    <img
                      src="../../dist/img/credit/mastercard.png"
                      alt="Mastercard"
                    />
                    <img
                      src="../../dist/img/credit/american-express.png"
                      alt="American Express"
                    />
                    <img src="../../dist/img/credit/paypal2.png" alt="Paypal" />

                    <p
                      className="text-muted well well-sm shadow-none"
                      style={{ marginTop: 10 }}
                    >
                      Etsy doostang zoodles disqus groupon greplin oooj voxy
                      zoodles, weebly ning heekya handango imeem plugg dopplr
                      jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                    </p>
                  </div>

                  <div className="col-6">
                    <p className="lead">Amount Due 2/22/2014</p>

                    <div className="table-responsive">
                      <table className="table">
                        <tr>
                          <th style={{ width: 50 }}>Total:</th>
                          <td>
                            {storeRcptDetail.reduce(
                              (totalRcpt, meal) =>
                                totalRcpt + parseInt(meal.total, 10),
                              0
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Payment Type</th>
                          <td>
                            {this.renderSelectPayType(
                              "paymentId",
                              "",
                              this.state.storePayType
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Recieved Amount</th>
                          <td>{this.renderInput1("recveAmount", "")}</td>
                        </tr>
                        <tr>
                          <th>Balance</th>
                          <td>
                            {storeRcptDetail.reduce(
                              (totalRcpt, meal) =>
                                totalRcpt + parseInt(meal.total, 10),
                              0
                            ) - this.state.data.recveAmount}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row no-print">
                  <div className="col-12">
                    <a
                      href="invoice-print.html"
                      target="_blank"
                      className="btn btn-default"
                    >
                      <i className="fas fa-print"></i> Print
                    </a>
                    <button
                      type="button"
                      className="btn btn-success float-right"
                      onClick={() => this.addRcptClose()}
                    >
                      <i className="far fa-credit-card"></i> Submit Payment
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                      style={{ marginRight: 5 }}
                    >
                      <i className="fas fa-download"></i> Generate PDF
                    </button>
                  </div>
                </div>
                </React.Fragment>
                )}
              </div>
              
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className="btn btn-default text-center">
                  Total
                  <br />
                  {this.state.custBalance}
                </label>
                <label className="btn btn-default text-center">
                  Previous Balance
                  <br />
                  {this.state.custBalance}
                </label>
                <label className="btn btn-default text-center">
                  Recieved Amount
                  <br />
                  {this.state.recveAmount}
                </label>
                <label className="btn btn-default text-center">
                  Less Cash
                  <br />
                  
                </label>
                <label className="btn btn-default text-center">
                  New Balance
                  <br />
                  
                </label>
              </div>
              <div className="card card-info card-outline"></div>
              <div className="callout callout-info">
                <div className="card-header border-transparent">
                <div className="direct-chat-text float-left">
                    Receipt: {this.state.receiptId} Type:{" "}
                    {this.state.statusReceipt}
                  </div>
                  <div className="direct-chat-text float-left">
                    Customer: {this.state.custName} Contact:{" "}
                    {this.state.custMobile}
                  </div>
                  <div className="direct-chat-msg right float-right">
                    <div className="direct-chat-text float-left">
                      Previous Balance: {this.state.custBalance}
                    </div>
                    <div className="direct-chat-text float-left">
                      Total Bill: 1001
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="callout callout-info">  
                <h5>
                  <i className="fas fa-info"></i> Note:
                  {this.renderSelect(
                    "customerId",
                    "Customer",
                    this.state.storeCustomer
                  )}
                </h5>
                This page has been enhanced for printing. Click the print button
                at the bottom of the invoice to test.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Receipt;
