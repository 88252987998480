import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const apiUrl = "https://api.hazaramart.com/api";

class Prdts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      response: {},
    };
  }
  componentDidMount() {
    const jwtt = localStorage.getItem("key");
    axios
      .get(apiUrl + "/products", {
        headers: { Authorization: `Bearer ${jwtt}` },
      })
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({
            products: result,
          });
          //console.log(result);
        },
        (error) => {
          this.setState({ error });
        }
      );
  }
  deleteUser(id) {
    const { products } = this.state;
    const jwtt = localStorage.getItem("key");
    axios
      .delete(apiUrl + "/products/" + id, {
        headers: { Authorization: `Bearer ${jwtt}` },
      })
      .then((result) => {
        alert(result.data);
        this.setState({
          response: result,
          products: products.filter((product) => product.productId !== id),
        });
      });
  }
  render() {
    const { error, products } = this.state;
    return (
      <table className="table table-bordered table-hover">
        <thead className="btn-primary">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Password</th>
            <th>Mobile</th>
            <th>ID Role</th>
            <th></th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.productId}>
              <td>
                <Link to={`/ProductDetails/${product.productId}`}>
                  {product.name}
                </Link>
              </td>
              <td>{product.category}</td>
              <td>{product.color}</td>
              <td>{product.unitPrice}</td>
              <td>{product.availableQuantity}</td>
              <td></td>
              <td>
                <button
                  onClick={() => this.props.editUser(product.productId)}
                  className="btn btn-info btn-sm m-2"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => this.deleteUser(product.productId)}
                  className="btn btn-danger btn-sm"
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default Prdts;
