import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import Joi from "joi-browser";
import FormAddUser from "./common/formAddUser";
import { getCompany } from "./services/serviceProduct";
import SearchBox from "./searchBox";
import { toast } from "react-toastify";

class AddItem extends FormAddUser {
  state = {
    data: {
      companyId: "",
      storeId: "",
      masterId: "",
      typeId: "",
      code: "",
      description: "",
      sizeColor: "",
      price: "",
      pricePurchase: "",
      priceUnit: "",
      packing: "",
      productGroup: "",
    },
    categoriesGroup: [],
    storeCompanies: [],
    productTypeGroup: [],
    productLocalGroup: [],
    productDetail: [],
    searchQuery: "",
    errors: {},
  };
  schema = {
    productId: Joi.string(),
    catId: Joi.label("catId"),
    companyId: Joi.label("CompanyId"),
    typeId: Joi.label("typeId"),
    //productType: Joi.string().label("ProductType"),
    code: Joi.label("code"),
    description: Joi.required().label("description"),
    sizeColor: Joi.required().label("sizeColor"),
    price: Joi.required().label("price"),
    pricePurchase: Joi.required().label("pricePurchase"),
    priceUnit: Joi.label("priceUnit"),
    packing: Joi.label("packing"),
  };
  async componentDidMount() {
    const { data: storeCompanies1 } = await getCompany();
    const { data: categoriesGroup } = await axios.get(
      `https://api.hazaramart.com/api/CategoriesGroup/${this.props.user.IdMaster}`
    );
    const { data: storeCompanies } = await axios.get(
      `https://api.hazaramart.com/api/CompaniesGroup/${this.props.user.IdMaster}`
    );
    const { data: productTypeGroup } = await axios.get(
      `https://api.hazaramart.com/api/ProductTypeGroup/${this.props.user.IdMaster}`
    );
    const { data: productLocalGroup } = await axios.get(
      `https://api.hazaramart.com/api/ProductGroupMaster/${this.props.user.IdMaster}`
    );
    this.setState({
      storeCompanies,
      categoriesGroup,
      productTypeGroup,
      productLocalGroup,
    });
  }
  mapToViewModel(productDetail) {
    return {
      productId: productDetail.productId,
      catId: productDetail.catId,
      companyId: productDetail.companyId,
      storeId: productDetail.storeId,
      productGroup: productDetail.productGroup,
      masterId: productDetail.masterId,
      typeId: productDetail.typeId,
      code: productDetail.code === null ? "" : productDetail.code,
      description:
        productDetail.description === null ? "" : productDetail.description,
      sizeColor:
        productDetail.sizeColor === null ? "" : productDetail.sizeColor,
      price: productDetail.price === null ? "" : productDetail.price,
      pricePurchase:
        productDetail.pricePurchase === null ? "" : productDetail.pricePurchase,
      priceUnit:
        productDetail.priceUnit === null ? "" : productDetail.priceUnit,
      packing: productDetail.packing === null ? "" : productDetail.packing,
    };
  }
  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };
  async handleProductEdit(id) {
    const { data: productDetail } = await axios.get(
      `https://api.hazaramart.com/api/Productss/${id}`
    );
    this.setState({ data: this.mapToViewModel(productDetail) });
  }
  async editTodo(id) {
    if (id) {
      const itemToEdit = { ...this.state.data };
      await axios
        .put(`https://localhost:44340/api/Productss/${id}`, itemToEdit)
        .then((response) => {
          toast.success("Successfully Updated.");
        });
      const { data: productLocalGroup } = await axios.get(
        `https://api.hazaramart.com/api/ProductGroupMaster/${this.props.user.IdMaster}`
      );
      this.setState({ productDetail: itemToEdit, productLocalGroup });
    } else {
      this.state.data.storeId = this.props.user.IdStr;
      //this.state.data.productGroup = this.props.user.productGroup;
      //this.state.data.masterId = this.props.user.IdMaster;
      const itemToAdd = { ...this.state.data };
      await axios
        .post(`https://api.hazaramart.com/api/Productss`, itemToAdd)
        .then((response) => {
          toast.success(`Successfully Added. ${response.data.productId}`);
          axios
            .post(`https://api.hazaramart.com/api/stocks`, {
              productId: response.data.productId,
              storeId: this.props.user.IdStr,
              qty: 0,
            })
            .then((response) => {
              toast.success(`Added in Store.`);
            });
        });
      const { data: productLocalGroup } = await axios.get(
        `https://api.hazaramart.com/api/ProductGroupMaster/${this.props.user.IdMaster}`
      );
      this.setState({ productLocalGroup });
      //this.state.data = "";
      //this.props.history.push("/product");
    }
  }
  render() {
    const {
      productLocalGroup: AllStock,
      categoriesGroup,
      storeCompanies,
      productTypeGroup,
      searchQuery,
    } = this.state;
    let filtered = AllStock;
    let pageTitle;
    let actionStatus;
    if (this.state.data.productId) {
      pageTitle = "Edit Product";
      actionStatus = "Update";
    } else {
      pageTitle = "Add Product";
      actionStatus = "Save";
    }
    if (searchQuery)
      filtered = AllStock.filter(
        (u) =>
          (u.sizeColor &&
            u.sizeColor.toLowerCase().startsWith(searchQuery.toLowerCase())) ||
          (u.code &&
            u.code.toLowerCase().startsWith(searchQuery.toLowerCase())) ||
          (u.description &&
            u.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    return (
      <React.Fragment>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Add / Edit Item
                  <small> powered by: NayaLinkApp</small>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add / Edit Item</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary card-outline-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-three-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-three-home-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-home"
                      role="tab"
                      aria-controls="custom-tabs-three-home"
                      aria-selected="true"
                    >
                      Add New/ Edit Item
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" tag={Link} to="/addTile">
                      Add Package Item
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-messages-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-messages"
                      role="tab"
                      aria-controls="custom-tabs-three-messages"
                      aria-selected="false"
                    >
                      In Store Tile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-three-settings-tab"
                      data-toggle="pill"
                      href="#custom-tabs-three-settings"
                      role="tab"
                      aria-controls="custom-tabs-three-settings"
                      aria-selected="false"
                    >
                      All Tiles
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-three-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="custom-tabs-three-home"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-home-tab"
                  >
                    <div className="card">
                      <div className="card-header">
                        <div className="navbar navbar-white navbar-light">
                          Showing {filtered.length} product(s).
                          <SearchBox
                            value={searchQuery}
                            onChange={this.handleSearch}
                          />
                        </div>

                        <div className="card-tools">
                          <div
                            className="input-group input-group-sm"
                            style={{ width: "150px" }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="card-body table-responsive p-0"
                        style={{ height: "160px" }}
                      >
                        <table className="table table-head-fixed text-nowrap table-hover">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Company</th>
                              <th>Type</th>
                              <th>Code</th>
                              <th>Description</th>
                              <th>Size/Color</th>
                              <th>Price</th>
                              <th>Purchase</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filtered.map((storeproduct) => (
                              <tr key={storeproduct.productId}>
                                <td>
                                  {storeproduct.catId &&
                                    categoriesGroup.find(
                                      (p) => p.catId === storeproduct.catId
                                    ).catName}
                                </td>
                                <td>
                                  {storeproduct.companyId &&
                                    storeCompanies.find(
                                      (p) =>
                                        p.companyId === storeproduct.companyId
                                    ).companyName}
                                </td>
                                <td>
                                  {storeproduct.typeId &&
                                    productTypeGroup.find(
                                      (p) => p.typeId === storeproduct.typeId
                                    ).typeName}
                                </td>
                                <td>{storeproduct.code}</td>
                                <td>
                                  {storeproduct.description || ""}{" "}
                                  {storeproduct.priceUnit}
                                </td>
                                <td>
                                  {storeproduct.sizeColor}{" "}
                                  {storeproduct.packing}
                                </td>
                                <td>{storeproduct.price}</td>
                                <td>{storeproduct.pricePurchase}</td>
                                <td>
                                  <button
                                    className="btn btn-info btn-sm"
                                    onClick={() =>
                                      this.handleProductEdit(
                                        storeproduct.productId
                                      )
                                    }
                                  >
                                    <i className="fas fa-eye"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card card-danger">
                      <div className="card-header">
                        <h3 className="card-title">
                          {pageTitle} {this.state.data.typeId}{" "}
                          {this.state.data.description}
                        </h3>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                          {/*   <input
                                name="storeUserName"
                                id="storeUserName"
                                value={this.state.data.storeUserName}
                                className="form-control"
                              /> */}

                          <div className="row">
                            {this.renderSelectCat(
                              "catId",
                              "Category",
                              this.state.categoriesGroup
                            )}
                            {this.renderSelectType(
                              "typeId",
                              "Type",
                              this.state.productTypeGroup
                            )}
                            {this.renderSelect(
                              "companyId",
                              "Company",
                              this.state.storeCompanies
                            )}

                            {this.renderInput6("description", "Description")}
                          </div>
                          <div className="row">
                            {this.renderInput3("code", "Code")}
                            {this.renderInput3("sizeColor", "Size/Color	")}
                            {this.renderInput3("price", "Price")}
                            {this.renderInput3("priceUnit", "Meter")}
                            {this.renderInput3("pricePurchase", "Purchase")}
                            {this.renderInput3("packing", "Packing")}
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            className="btn btn-danger float-right"
                            onClick={() =>
                              this.editTodo(this.state.data.productId)
                            }
                          >
                            {actionStatus}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-profile"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-profile-tab"
                  ></div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-messages"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-messages-tab"
                  ></div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-three-settings"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-three-settings-tab"
                  >
                    Pellentesque vestibulum commodo nibh nec blandit. Maecenas
                    neque magna, iaculis tempus turpis ac, ornare sodales
                    tellus. Mauris eget blandit dolor. Quisque tincidunt
                    venenatis vulputate. Morbi euismod molestie tristique.
                    Vestibulum consectetur dolor a vestibulum pharetra. Donec
                    interdum placerat urna nec pharetra. Etiam eget dapibus
                    orci, eget aliquet urna. Nunc at consequat diam. Nunc et
                    felis ut nisl commodo dignissim. In hac habitasse platea
                    dictumst. Praesent imperdiet accumsan ex sit amet facilisis.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AddItem;
