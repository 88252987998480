import React, { Component } from "react";

class Counter extends Component {
  render() {
    return (
      <li class="item">
        <div class="product-img">
          <img
            src={`../../store/${this.props.counter.strname}/${this.props.counter.productId}.jpg`}
            alt=""
            class="img-size-50"
          />
        </div>
        <div class="product-info">
          <div href="javascript:void(0)" class="product-title">
            {this.props.counter.typeName}
            <span class="badge float-right">
              <button
                type="button"
                class="btn btn-tool"
                onClick={() => this.props.onDelete(this.props.counter.receiptKartId)}
              >
                <i class="fas fa-times"></i>
              </button>
            </span>
            <span class="badge float-right">Rs {this.props.counter.price}</span>
          </div>
          <span class="badge float-right">
            <button
              type="button"
              class="btn btn-tool"
              onClick={() => this.props.onIncrement(this.props.counter)}
            >
              +
            </button>
          </span>
          <span class="badge float-right">{this.formatCount()}</span>
          <span class="badge float-right">
            <button
              type="button"
              class="btn btn-tool"
              onClick={() => this.props.onDecrement(this.props.counter)}
              disabled={this.props.counter.qty === 0 ? "disabled" : ""}
            >
              -
            </button>
          </span>
          <span class="product-description">
            {this.props.counter.description}
            {this.props.counter.sizeColor}
            {this.props.counter.code}
          </span>
        </div>
      </li>
    );
  }

  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.props.counter.qty === 0 ? "" : "";
    return classes;
  }

  formatCount() {
    const { qty } = this.props.counter;
    return qty === 0 ? "Zero" : qty;
  }
}

export default Counter;
