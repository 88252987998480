import React, { Component } from "react";

class ProductDetails extends Component {
  state = {
    account: { username: "", password: "" }
  };
  handleSave = () => {
    //this.props.history.push("/products");
    this.props.history.replace("/products");
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log("Submitted");
  };
  render() {
    return (
      <div>
        {/* <h1>Product Details - {this.props.match.params.id}</h1>
        <button onClick={this.handleSave}>Save</button> */}
        {/* <button onClick={() => history.push("/products")}>Save</button> */}
        <form onSubmit={this.handleSubmit}>sdfsd</form>
      </div>
    );
  }
}

export default ProductDetails;
